import React, { useEffect, useRef, useState } from "react";
import Styles from "../report.module.css";

import { Link } from "react-router-dom";
import Select from "react-select";

import PortfolioLayout from "../../../../components/Layout/Portfolio";
import SimpleReactValidator from "simple-react-validator";
import AllCategoriesView from "./AssetCategoriesDetailsViews/AllCategoriesView";
import MutualFundReportView from "./AssetCategoriesDetailsViews/MutualFundReportView";
import StocksHoldingsReportView from "./AssetCategoriesDetailsViews/StocksHoldingsReportView";
import FixedDepositBondsReportView from "./AssetCategoriesDetailsViews/FixedDepositBondsReportView";
import AlternateReportView from "./AssetCategoriesDetailsViews/AlternateReportView";
import {
  fetchData,
  getParentUserId,
  getPublicMediaURL,
  indianRupeeFormat,
} from "../../../../common_utilities";
import style from "./style.module.css";
import FintooButton from "../../../../components/HTML/FintooButton";
import { GET_MEMBER_LIST } from "../../../../constants";
import { useDispatch } from "react-redux";
import PortfolioGraph from "./PortfolioGraph";

const reportTypeOptions = [
  { value: "", label: "Select" },
  { value: "Summary", label: "Summary Report" },
  { value: "Detailed", label: "Detail Report" },
];

const AssetCategoryOptions = [
  { value: "All", label: "All" },
  { value: "MutualFund", label: "Mutual Fund" },
  { value: "StocksHoldings", label: "Stocks Holdings" },
  { value: "FixedDepositBonds", label: "Fixed Deposit | Bonds" },
  { value: "Alternate", label: "Alternate" },
];

const initialValues = {
  reportType: "",
  assetCategory: "All",
};

const AssetBox = ({ title, amount, percentage, color }) => {
  return (
    <div className={style.assetBoxDv}>
      <p className={style.assetBoxTitle}>{title}</p>
      <p className={style.assetBoxAmount}>
        {indianRupeeFormat(amount)}
        <span>({percentage}%)</span>
      </p>
    </div>
  );
};

function PortfolioHoldingsReportDetails() {
  const dispatch = useDispatch();
  const [, forceUpdate] = useState();
  const [formData, setFormData] = useState(initialValues);
  const [currentReportView, setCurrentReportView] = useState(null);
  const [memberDropdownData, setMemberDropdownData] = useState([]);

  const simpleValidator = useRef(new SimpleReactValidator());

  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
    if (simpleValidator.current.allValid() == true) {
      setCurrentReportView(formData.assetCategory);
      return true;
    }
    return false;
  };

  const getMemberData = async () => {
    try {
      let x = await fetchData({
        url: GET_MEMBER_LIST,
        method: "post",
        data: { user_id: getParentUserId(), is_direct: "1" },
      });
      setMemberDropdownData([
        { label: "Family", value: 0 },
        ...x.data.map((v) => ({ label: v.NAME, value: v.id })),
      ]);
    } catch (e) {
      // console.error("err #334 getMemberData", e);
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Something went wrong, please try again later.",
          type: "error",
        },
      });
    }
  };

  useEffect(() => {
    getMemberData();
  }, []);

  return (
    <PortfolioLayout>
      <div className={`${Styles.ReportDetailSection}`}>
        <div>
          <Link
            className="text-decoration-none"
            to={`${process.env.PUBLIC_URL}/commondashboard/Report`}
          >
            <img
              className="pointer"
              src={
                process.env.REACT_APP_STATIC_URL + "media/DMF/left-arrow.png"
              }
              width={25}
            />
          </Link>
        </div>
        <div className={`row ${Styles.PortfolioReportSection}`}>
          <div className="col-12  mt-md-5 mt-4">
            <div className={`pb-2 ${Styles.insideTabBoxd}`}>
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src={
                      process.env.REACT_APP_STATIC_URL +
                      "media/DMF/Report/01_capital_gains_Loss_report.svg"
                    }
                    width={50}
                  />
                </div>
                <div className={`pt-3  ${Styles.ReportName}`}>
                  Portfolio Holdings Report
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row mt-4">
            <div className="col">
              <div className={`${Styles.ReportLabel}`}>Type of Report</div>
              <div className="mt-2">
                <Select
                  className="box-shadow-none border-0"
                  classNamePrefix="ReportSelect"
                  isSearchable={false}
                  placeholder="Select.."
                  options={reportTypeOptions}
                  value={
                    reportTypeOptions.filter(
                      (v) => v.value === formData.reportType
                    )[0] ?? ""
                  }
                  onChange={(e) => {
                    onDateAndSelectInputChange("reportType", e.value);
                  }}
                />
              </div>
              {simpleValidator.current.message(
                "reportType",
                formData.reportType,
                "required",
                { messages: { required: "This field is required" } }
              )}
            </div>
            <div className="col">
              <div className={`${Styles.ReportLabel}`}>Asset Category</div>
              <div className="mt-2">
                <Select
                  className="box-shadow-none border-0"
                  classNamePrefix="ReportSelect"
                  isSearchable={false}
                  options={AssetCategoryOptions}
                  placeholder="Select.."
                  value={
                    AssetCategoryOptions.filter(
                      (v) => v.value === formData.assetCategory
                    )[0] ?? "All"
                  }
                  onChange={(e) => {
                    onDateAndSelectInputChange("assetCategory", e.value);
                  }}
                />
              </div>
              {simpleValidator.current.message(
                "assetCategory",
                formData.assetCategory,
                "required"
              )}
            </div>
            <div className="col">
              <div className={`${Styles.ReportLabel}`}>Member</div>
              {console.log("memberDropdownData====>", memberDropdownData)}
              <div className="mt-2">
                <Select
                  className="box-shadow-none border-0"
                  classNamePrefix="ReportSelect"
                  isSearchable={false}
                  options={memberDropdownData}
                  placeholder="Select.."
                />
              </div>
              {simpleValidator.current.message(
                "assetCategory",
                formData.assetCategory,
                "required"
              )}
            </div>
            <div className="col">
              <div className={`${Styles.ReportGenOption}`}>
                <div
                  className="d-md-block d-none"
                  style={{
                    height: "2.3rem",
                  }}
                ></div>
                <button
                  onClick={() => validateForm()}
                  className={`${Styles.ReportButton}`}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-5 fw-bold mb-0">Mutual fund</p>
        <div className="mt-4">
          <div className="mt-4">
            <p className="text-center fw-bold mb-0">
              Currently it seems we don't have your Mutual Fund data to display.
            </p>
            <p className="text-center fw-bold mb-0">
              You can fetch your existing Mutual Fund Holdings
            </p>
            <FintooButton
              className={"mt-4 d-block px-4 py-2 m-auto"}
              title={"Fetch Holdings New"}
            />
            <img
              className={`${style["no-results"]} mt-4`}
              src={getPublicMediaURL("static/media/icons/Investment-data.png")}
              width={500}
            />
          </div>
          <div className="row mt-4">
            <div className="col-4">
              <div className={style.assetBoxTableWrapper}>
                <table className={style.assetBoxTable}>
                  <tr>
                    <td>
                      <AssetBox
                        title={"Mutual Fund"}
                        amount={12000}
                        percentage={18}
                        color={"blue"}
                      />
                    </td>
                    <td>
                      <AssetBox
                        title={"Mutual Fund"}
                        amount={12000}
                        percentage={18}
                        color={"blue"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AssetBox
                        title={"Mutual Fund"}
                        amount={12000}
                        percentage={18}
                        color={"blue"}
                      />
                    </td>
                    <td>
                      <AssetBox
                        title={"Mutual Fund"}
                        amount={12000}
                        percentage={18}
                        color={"blue"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AssetBox
                        title={"Mutual Fund"}
                        amount={12000}
                        percentage={18}
                        color={"blue"}
                      />
                    </td>
                    <td>
                      <AssetBox
                        title={"Mutual Fund"}
                        amount={12000}
                        percentage={18}
                        color={"blue"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AssetBox
                        title={"Mutual Fund"}
                        amount={12000}
                        percentage={18}
                        color={"blue"}
                      />
                    </td>
                    <td>
                      <AssetBox
                        title={"Mutual Fund"}
                        amount={12000}
                        percentage={18}
                        color={"blue"}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-6">
              <PortfolioGraph />
            </div>
          </div>

          <div className="row">
            <div className="col-7">
              <div className={style.assetBoxWrapper}>
                <div className="d-flex">
                  <div className={`col ${style.assetBoxColLine}`}>
                    <p className={style.assetInvText}>Invested Value</p>
                    <p className={style.assetInvValue}>
                      {indianRupeeFormat(10000)}
                    </p>
                  </div>
                  <div className="col">
                    <p className={style.assetInvText}>Invested Value</p>
                    <p className={style.assetInvValue}>
                      {indianRupeeFormat(10000)}
                    </p>
                  </div>
                  <div className={`col ${style.assetBoxColLine}`}>
                    <p className={style.assetInvText}>Invested Value</p>
                    <p className={style.assetInvValue}>
                      {indianRupeeFormat(10000)}
                    </p>
                  </div>
                  <div className="col">
                    <p className={style.assetInvText}>Invested Value</p>
                    <p className={style.assetInvValue}>
                      {indianRupeeFormat(10000)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <p className={style.reportTextDate}>
                Report Generated on <strong>31/11/2023</strong>
              </p>
            </div>
            <div className={`${style.bottomBtnDv} col-6`}>
              <div className="d-flex align-items-center">
                <div
                  className={`${style.shareNowDv} d-flex align-items-center position-relative`}
                >
                  <p className="m-0">Share Now</p>
                  <span className="ms-1">
                    <img
                      src={getPublicMediaURL(
                        "/static/media/icons/share-ico.png"
                      )}
                    />
                  </span>
                  <div className={style.showMoreDv}>
                    <div className={style.showMoreDvInner}>
                      <div className="row">
                        <div
                          className={`col ${style.borderDvRight} ${style.insideWrapper}`}
                        >
                          <div>
                            <p className="m-0 text-center">
                              <img
                                className={style.btnIcons}
                                src={getPublicMediaURL(
                                  "/static/media/icons/email-ico.png"
                                )}
                              />
                            </p>
                            <p className="m-0 mt-2 text-center">
                              Share via Email
                            </p>
                          </div>
                        </div>
                        <div className={`col ${style.insideWrapper}`}>
                          <div>
                            <p className="m-0 text-center">
                              <img
                                className={style.btnIcons}
                                src={getPublicMediaURL(
                                  "/static/media/icons/whatsapp-ico.png"
                                )}
                              />
                            </p>
                            <p className="m-0 mt-2 text-center">
                              Share on WhatsApp
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.emptyLine}></div>
                <div className="d-flex align-items-center">
                  <p className="m-0">Download Now</p>
                  <span className="ms-1">
                    <img
                      src={getPublicMediaURL(
                        "/static/media/icons/download-ico.png"
                      )}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${Styles.categoriesDataContainer}`}>
          {currentReportView === "All" && (
            <AllCategoriesView
              selectedCategoryName={
                AssetCategoryOptions.filter(
                  (v) => v.value === currentReportView
                )[0]?.label ?? ""
              }
            />
          )}
          {currentReportView === "MutualFund" && (
            <MutualFundReportView
              selectedCategoryName={
                AssetCategoryOptions.filter(
                  (v) => v.value === currentReportView
                )[0]?.label ?? ""
              }
            />
          )}
          {currentReportView === "StocksHoldings" && (
            <StocksHoldingsReportView
              selectedCategoryName={
                AssetCategoryOptions.filter(
                  (v) => v.value === currentReportView
                )[0]?.label ?? ""
              }
            />
          )}
          {currentReportView === "FixedDepositBonds" && (
            <FixedDepositBondsReportView
              selectedCategoryName={
                AssetCategoryOptions.filter(
                  (v) => v.value === currentReportView
                )[0]?.label ?? ""
              }
            />
          )}
          {currentReportView === "Alternate" && (
            <AlternateReportView
              selectedCategoryName={
                AssetCategoryOptions.filter(
                  (v) => v.value === currentReportView
                )[0]?.label ?? ""
              }
            />
          )}
        </div>
      </div>
    </PortfolioLayout>
  );
}

export default PortfolioHoldingsReportDetails;
