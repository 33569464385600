// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.style_mybtn__cryoX {\n    border: 0px;\n    background-color: #52afde;\n    border-radius: 5px;\n    padding: 5px 10px;\n}\n.style_mybtn__cryoX i {\n    color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/ReactCrop/style.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,WAAW;AACf","sourcesContent":["\n.mybtn {\n    border: 0px;\n    background-color: #52afde;\n    border-radius: 5px;\n    padding: 5px 10px;\n}\n.mybtn i {\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mybtn": "style_mybtn__cryoX"
};
export default ___CSS_LOADER_EXPORT___;
