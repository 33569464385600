// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_button__M96uu {\n    background: #005263;\n    border: none;\n    padding: 8px 30px;\n    border-radius: 30px;\n    margin: 20px 0;\n    color: #fff;\n    font-weight: 600;\n    transition: 0.3s ease all;\n    text-align: center;\n    outline: none;\n    font-weight: 600;\n    margin-right: 25px;\n    display: inline-block;\n    vertical-align: middle;\n}\n.style_active__DDqws {\n    background: #006E84 !important;\n    /* background: #827f7f !important; */\n    transform: translateY(4px);\n    box-shadow: 7px 6px 28px 1px ;\n}\n@media only screen and (max-width: 768px){\n    .style_button__M96uu {\n        margin-right: 9px;\n        padding: 8px 13px;\n        font-size: .8rem;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/DgRoundedButton/style.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;AAC1B;AACA;IACI,8BAA8B;IAC9B,oCAAoC;IACpC,0BAA0B;IAC1B,6BAA6B;AACjC;AACA;IACI;QACI,iBAAiB;QACjB,iBAAiB;QACjB,gBAAgB;IACpB;AACJ","sourcesContent":[".button {\n    background: #005263;\n    border: none;\n    padding: 8px 30px;\n    border-radius: 30px;\n    margin: 20px 0;\n    color: #fff;\n    font-weight: 600;\n    transition: 0.3s ease all;\n    text-align: center;\n    outline: none;\n    font-weight: 600;\n    margin-right: 25px;\n    display: inline-block;\n    vertical-align: middle;\n}\n.active {\n    background: #006E84 !important;\n    /* background: #827f7f !important; */\n    transform: translateY(4px);\n    box-shadow: 7px 6px 28px 1px ;\n}\n@media only screen and (max-width: 768px){\n    .button {\n        margin-right: 9px;\n        padding: 8px 13px;\n        font-size: .8rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "style_button__M96uu",
	"active": "style_active__DDqws"
};
export default ___CSS_LOADER_EXPORT___;
