import { useState } from "react";
import { useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import DatagatherReportLayout from "../../components/Layout/Datagather/Reports";
import FintooLoader from "../../components/FintooLoader";
import {
  ADVISORY_GET_CASHINFLOW_DATA,
  ADVISORY_GET_CASHOUTFLOW_DATA,
  ADVISORY_NET_SURPLUS_SHORTFALL_DATA,
  ADVISORY_GET_CASHFLOW_RECOMMENDATION_DATA,
  CHECK_SESSION,
} from "../../constants";
import {
  getParentUserId,
  getItemLocal,
  apiCall,
  loginRedirectGuest,
  fetchEncryptData, setBackgroundDivImage
} from "../../common_utilities";
import { Link } from "react-router-dom";
import NetSurplusShortfall from "./CashflowAnalysisReport/NetSurplusShortfall";
import Inflow from "./CashflowAnalysisReport/Inflow";
import Outflow from "./CashflowAnalysisReport/Outflow";
import CashflowRecommendation from "./CashflowAnalysisReport/CashflowRecommendation";
import { ScrollToTop } from "./ScrollToTop"
const CashinFlow = () => {
  const [tab, setTab] = useState("tab1");
  const [surplusShortfallData, setSurplusShortfallData] = useState([]);
  const [cashinflowData, setCashInflowData] = useState([]);
  const [cashoutflowData, setCashOutflowData] = useState([]);
  const [recommendationData, setRecommendationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.body.classList.add("dg-layout");
    document.body.classList.add("rp-layout");
    document.getElementById('report-bg-cash').style.background = 'url(' + imagePath +
                          '/static/assets/img/reports/ill-cashflow.svg)' + " no-repeat right top";
    getMemberList();
    setBackgroundDivImage();

    return () => {
      document.body.classList.remove("dg-layout");
      document.body.classList.remove("rp-layout");
    };
  }, []);

  const netSurplusShortfallAPI = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
    };
    let config = {
      method: "POST",
      url: ADVISORY_NET_SURPLUS_SHORTFALL_DATA,
      data: api_data,
    };

    try {
      const [response, inflow_response, outflow_response] = await Promise.all([
        fetchEncryptData(config),
        fetchEncryptData({
          method: "POST",
          url: ADVISORY_GET_CASHINFLOW_DATA,
          data: api_data,
        }),
        fetchEncryptData({
          method: "POST",
          url: ADVISORY_GET_CASHOUTFLOW_DATA,
          data: api_data,
        }),
      ]);

      if (response) {
        if (
          response["error_code"] == "100" ||
          response["error_code"] == "103"
        ) {
          let data = response["data"];
          let tempSurplusShortfallData = { ...data };

          tempSurplusShortfallData = {
            ...tempSurplusShortfallData,
            surplusShortfallColspan:
              parseInt(Object.keys(data["cashflowinvestments"]).length) + 1,
          };

          if (inflow_response && inflow_response["error_code"] == "100") {
            var total_gross_income =
              inflow_response["data"][0]["total_gross_income"];
            tempSurplusShortfallData = {
              ...tempSurplusShortfallData,
              income: total_gross_income,
            };
          }

          if (outflow_response && outflow_response["error_code"] == "100") {
            var total_gross_expense =
              outflow_response["data"][0]["total_gross_expense"];
            var cashflowYear = outflow_response["data"][0]["cashflowyear"];
            tempSurplusShortfallData = {
              ...tempSurplusShortfallData,
              expense: total_gross_expense,
              cashflowyear: cashflowYear,
            };
          }

          setSurplusShortfallData({ ...tempSurplusShortfallData });
        }
      }
    } catch (error) {
      // Handle errors if any of the API calls fail.
      console.error(error);
    }
  };

  const cashInflowAPI = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
    };
    let config = {
      method: "POST",
      url: ADVISORY_GET_CASHINFLOW_DATA,
      data: api_data,
    };
    var response = await fetchEncryptData(config);
    if (response["error_code"] == "100") {
      let data = response["data"];
      let tempCashinflowData = { ...data[0] };
      setCashInflowData(response["data"][0]);

      let total_category_income = response["data"][0]["total_category_income"];
      let cashinflowyear_set = response["data"][0]["cashflowyear"];
      let cashinflow_year_list = [];
      Object.keys(cashinflowyear_set).map((k, v) => {
        cashinflow_year_list.push({ year: k, age: cashinflowyear_set[k] });
      });
      tempCashinflowData = {
        ...tempCashinflowData,
        cashflowyear: cashinflow_year_list,
      };
      let inflow_final_array = [];
      Object.keys(total_category_income).map((key, index) => {
        var newkey = key;
        if (total_category_income[key].length > 0) {
          var newkey;
          switch (key) {
            case "post_office_mis":
              newkey = "Interest Income";
              break;
            case "salary_and_bonus":
              newkey = "Salary & Bonus";
              break;
            case "business":
              newkey = "Business";
              break;
            case "pension":
              newkey = "Pension";
              break;
            case "rental":
              newkey = "Rental";
              break;
            case "gifts":
              newkey = "Gifts";
              break;
            case "others":
              newkey = "Others";
              break;
            case "ULIP":
              newkey = "ULIP";
              break;
            case "endowment":
              newkey = "Endowment";
              break;
            case "assured_income_plan":
              newkey = "Assured Income Plan";
              break;
            case "pension_plan":
              newkey = "Pension Plan";
              break;
            default:
              newkey = key;
          }
          var newdict = { name: newkey, data: total_category_income[key] };
          inflow_final_array.push(newdict);
        }
      });
      tempCashinflowData = {
        ...tempCashinflowData,
        colSpan: 1 + inflow_final_array.length,
        inflowFinalArray: inflow_final_array,
      };
      setCashInflowData({ ...tempCashinflowData });
    }
  };

  const cashOutflowAPI = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
    };
    let config = {
      method: "POST",
      url: ADVISORY_GET_CASHOUTFLOW_DATA,
      data: api_data,
    };
    var response = await fetchEncryptData(config);
    if (response["error_code"] == "100") {
      let data = response["data"][0];
      let tempCashoutflowData = { ...data };
      let outflow_expense_data = response["data"][0]["Expenses"];

      let y_axis_final_array = [];
      Object.keys(outflow_expense_data).map((key, index) => {
        var newkey = key;
        if (outflow_expense_data[key] > 0) {
          switch (key) {
            case "mandatory_fixed":
              newkey = "Mandatory Fixed";
              break;
            case "mandatory_variable":
              newkey = "Mandatory Variable";
              break;
            case "wishful_variable":
              newkey = "Wishful Variable";
              break;
            case "wishful_fixed":
              newkey = "Wishful Fixed";
              break;
            default:
              break;
          }
        }
        var newdict = {
          name: newkey,
          data: outflow_expense_data[key]["total"],
        };
        y_axis_final_array.push(newdict);
      });
      tempCashoutflowData = {
        ...tempCashoutflowData,
        colSpan: 1 + y_axis_final_array.length,
      };
      setCashOutflowData({ ...tempCashoutflowData });
    }
  };

  const recommendationAPI = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
      fp_user_id: session_data["data"]["fp_user_id"],
    };
    let config = {
      method: "POST",
      url: ADVISORY_GET_CASHFLOW_RECOMMENDATION_DATA,
      data: api_data,
    };
    var response = await fetchEncryptData(config);
    if (response["error_code"] == "100") {
      setRecommendationData(response["data"]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getMemberList = async () => {
    try {

      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };

      let session_data = await apiCall(url, data, true, false);
      if (session_data.error_code == "102") {
        loginRedirectGuest();
      } else {
        netSurplusShortfallAPI(session_data);
        cashInflowAPI(session_data);
        cashOutflowAPI(session_data);
        recommendationAPI(session_data);

      }
    } catch (e) {
      console.log("err", e);
    }
  };

  return (
    <DatagatherReportLayout>
      <FintooLoader isLoading={isLoading} />
      <div className="reports ">
        <div className="">
          <div className="background-div">
            <div className="bg active" id="report-bg-cash"></div>
          </div>
          <div className="white-box">
            <div className="d-flex justify-content-center tab-box">
              <div className="d-flex top-tab-menu noselect">
                <div
                  className={`tab-menu-item ${tab == "tab1" ? "active" : ""}`}
                  onClick={() => setTab("tab1")}
                >
                  <div className="tab-menu-title">NET SURPLUS / SHORTFALL</div>
                </div>
                <div
                  className={`tab-menu-item ${tab == "tab2" ? "active" : ""}`}
                  onClick={() => setTab("tab2")}
                >
                  <div className="tab-menu-title">CASHINFLOW</div>
                </div>
                <div
                  className={`tab-menu-item ${tab == "tab3" ? "active" : ""}`}
                  onClick={() => setTab("tab3")}
                >
                  <div className="tab-menu-title">CASHOUTFLOW</div>
                </div>
                <div
                  className={`tab-menu-item ${tab == "tab4" ? "active" : ""}`}
                  onClick={() => setTab("tab4")}
                >
                  <div className="tab-menu-title">FINTOO RECOMMENDS</div>
                </div>
              </div>
            </div>

            <div>
              <div className={tab == "tab1" ? "d-block" : "d-none"}>
                <NetSurplusShortfall data={surplusShortfallData} />
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <Link
                            to={
                              process.env.PUBLIC_URL + "/report/risk-management"
                            }
                          >
                            <div className="previous-btn form-arrow d-flex align-items-center">
                              <FaArrowLeft />
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Previous&nbsp;
                              </span>
                            </div>
                          </Link>
                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => 
                              {
                                ScrollToTop();
                                setTab("tab2")}
                              }
                          >
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Next&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={tab == "tab2" ? "d-block" : "d-none"}>
                <Inflow data={cashinflowData} />
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <div
                            className="previous-btn form-arrow d-flex align-items-center"
                            onClick={() => 
                              {
                                ScrollToTop();
                                setTab("tab1")}
                              }
                          >
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => 
                              {
                                ScrollToTop();
                                setTab("tab3")}
                              }
                          >
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Next&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={tab == "tab3" ? "d-block" : "d-none"}>
                <Outflow data={cashoutflowData} />
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <div
                            className="previous-btn form-arrow d-flex align-items-center"
                            onClick={() => setTab("tab2")}
                          >
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                          {/* <button className="default-btn gradient-btn save-btn">
                                  Save & Add More
                                </button> */}
                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => 
                              {
                                ScrollToTop();
                                setTab("tab4")}
                              }
                          >
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Next&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={tab == "tab4" ? "d-block" : "d-none"}>
                <CashflowRecommendation
                  data={recommendationData}
                  income_data={cashinflowData}
                  expense_data={cashoutflowData}
                />
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <div
                            className="previous-btn form-arrow d-flex align-items-center"
                            onClick={() => 
                              {
                                ScrollToTop();
                                setTab("tab3")}
                              }
                          >
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              "/report/retirement-corpus"
                            }
                          >
                            <div className="next-btn form-arrow d-flex align-items-center">
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Next&nbsp;
                              </span>
                              <FaArrowRight />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DatagatherReportLayout>
  );
};
export default CashinFlow;
