import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import FormRadioComponent from "../CommonDashboardComponents/FormRadioComponent";
import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import {
  apiCall,
  fetchEncryptData,
  getFpUserDetailsId,
  getItemLocal,
  getParentUserId,
  getUserId,
} from "../../../../common_utilities";
import {
  ADVISORY_ADD_ASSETS_API,
  ADVISORY_UPDATE_ASSETS_API,
  DMF_ADD_EDIT_REALESTATE,
  GET_OTHER_INVESTMENTS,
} from "../../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

const numericRegex = new RegExp(/^\d*\.?\d*$/);

const realEastateInputs = {
  default: [
    "nameOfProperty",
    "purchaseDate",
    "purchaseValue",
    "currentValue",
    "pincode",
    "residentialType",
    "mortgageOrFreehold",
  ],
  53: [
    "nameOfProperty",
    "purchaseDate",
    "purchaseValue",
    "currentValue",
    "pincode",
    "residentialType",
    "mortgageOrFreehold",
    "cityType",
  ],
  52: [
    "nameOfProperty",
    "purchaseDate",
    "purchaseValue",
    "currentValue",
    "pincode",
    "residentialType",
    "mortgageOrFreehold",
    "cityType",
  ],
  56: [
    "nameOfProperty",
    "purchaseDate",
    "purchaseValue",
    "currentValue",
    "pincode",
    "residentialType",
    "mortgageOrFreehold",
    "cityType",
  ],
  51: [
    "nameOfProperty",
    "purchaseDate",
    "purchaseValue",
    "currentValue",
    "pincode",
    "residentialType",
    "mortgageOrFreehold",
    "cityType",
  ],
  55: [
    "nameOfProperty",
    "purchaseDate",
    "purchaseValue",
    "currentValue",
    "pincode",
    "mortgageOrFreehold",
  ],
  59: [
    "nameOfProperty",
    "purchaseDate",
    "purchaseValue",
    "currentValue",
    "pincode",
    "residentialType",
    "mortgageOrFreehold",
    "cityType",
  ],
};

const options_type_of_real_estate = [
  { value: 53, label: "Agriculture Land" },
  { value: 56, label: "Land" },
  { value: 52, label: "Commercial Land" },
  { value: 51, label: "Residential Premises" },
  { value: 55,label: "Under Construction Property",},
  { value: 59, label: "Others" },
];


const options_residental_type_radio = [
  { text: "Self Occupied", name: "residentialType", id: "self occupied" },
  { text: "Rented", name: "residentialType", id: "rented" },
];

const options_mortgage_or_freehold = [
  { text: "Freehold", name: "mortgageOrFreehold", id: "freehold" },
  { text: "Mortgage", name: "mortgageOrFreehold", id: "mortgage" },
];

const options_city_type = [
  { text: "Tier 1", name: "cityType", id: "0" },
  { text: "Tier 2", name: "cityType", id: "1" },
  { text: "Tier 3", name: "cityType", id: "2" },
];

const initialValues = {
  typeOfProperty: "",
  nameOfProperty: "",
  purchaseDate: "",
  purchaseValue: "",
  currentValue: "",
  pincode: "",
  residentialType: "self occupied",
  mortgageOrFreehold: "freehold",
  cityType: "0",
  asset_source: "2"
};

function isInputInPolicy(inputName, policyType) {
  if (typeof policyType == "string") {
    let lowercasePolicyType = policyType.toLowerCase();

    if (!policyType) {
        lowercasePolicyType = "default";
    }
    return realEastateInputs[lowercasePolicyType].includes(inputName);
  } else if (typeof policyType == "number") {
      return realEastateInputs[policyType].includes(inputName);
  }

}

const NewRealEstateFormView = () => {
  const [, forceUpdate] = useState();
  const [formData, setFormData] = useState(initialValues);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);

  function resetState () {
    setFormData((prev) => ({ ...prev, purchaseDate: "", purchaseValue: "", currentValue: "", pincode: "", residentialType: "self occupied", mortgageOrFreehold: "freehold", cityType: "0", asset_source: "2"}))
  }

  let { id } = useParams();

  useEffect(() => {
    if (getItemLocal("family")) {
      navigate(
        process.env.PUBLIC_URL +
        "/direct-mutual-fund/portfolio/dashboard?realestate=1"
      );
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Not allowed!", type: "error", autoClose: 3000},
      });
    }
    if (id) {
      fetchInsuranceData();
    }
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator());

  const onInputChange = (e, isNumeric) => {
    const name = e.target.name;
    let value = e.target.value;

    if (isNumeric && !numericRegex.test(value) && value !== '') {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const onDateAndSelectInputChange = (name, value) => {
    if (name == "typeOfProperty") {
      setFormData({...formData, typeOfProperty: value.value, nameOfProperty: value.label});
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
    if (simpleValidator.current.allValid() == true) {
      saveRealEstate();
    }
  };

  const getRealEstateTypeValue = (insuranceTypeData, value) => {
    return insuranceTypeData.find((data) => data.value === value);
  }

  const assignEstateData = (data) => {
    console.log("roh assignData ---> ", data);
    const dataCopy = {
      typeOfProperty: getRealEstateTypeValue(
        options_type_of_real_estate,
        data.asset_sub_category_id
      ).value,
      nameOfProperty: data.property_name,
      purchaseDate: moment(data.purchase_date).toDate(),
      purchaseValue: data.purchase_rate.toString(),
      currentValue: data.current_rate.toString(),
      pincode: data.pincode.toString(),
      residentialType: data.asset_isRented == "0" ? "rented" : "self occupied",
      mortgageOrFreehold: data.asset_isMortgage == "1" ? "mortgage" : "freehold",
      cityType: data.asset_isRented == "0" ? data.asset_citytype : "",
      asset_source: "2"
    };
    console.log("roh dataCopy ---> ", dataCopy);
    setFormData(dataCopy)
  };

  simpleValidator.current.purgeFields();

  const saveRealEstate = async () => {
    try {
      if (id != undefined || id != null) {
        let payload = {
          url: ADVISORY_UPDATE_ASSETS_API,
          data: {
            user_id: getParentUserId(),
            asset_member_id: await getFpUserDetailsId(),
            asset_ror: 0,
            fp_log_id : "",
            categorydetail: "Real Estate",
            asset_category_id: 39,
            asset_isActive: "1",
            asset_citytype: formData.cityType,
            asset_name: formData.nameOfProperty,
            asset_sub_category_id: formData.typeOfProperty,
            asset_purchase_amount: formData.purchaseValue,
            asset_amount: formData.currentValue,
            asset_purchase_date: moment(formData.purchaseDate).format("DD/MM/YYYY"),
            asset_pin_code: formData.pincode,
            asset_isrented: formData.residentialType == "rented" ? "0" : "1",
            asset_isMortgage: formData.mortgageOrFreehold == "mortgage" ? "1" : "0",
            id: atob(id),
            asset_source: formData.asset_source,
            asset_frequency: 0
          },
          method: "post",
        };

        let resp = await fetchEncryptData(payload);
        console.log("resp ---> ", resp);
        if (resp.error_code == 100) {
          navigate(
            process.env.PUBLIC_URL +
            "/direct-mutual-fund/portfolio/dashboard?realestate=1"
          );
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Data updated successfully!", type: "success", autoClose: 3000 },
          });
        } else {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Something went wrong, try again later.", type: "error", autoClose: 3000 },
          });
        }
      } else {
        let payload = {
          url: ADVISORY_ADD_ASSETS_API,
          data: {
            user_id: getParentUserId(),
            asset_member_id: await getFpUserDetailsId(),
            asset_ror: 0,
            categorydetail: "Real Estate",
            asset_category_id: 39,
            asset_isActive: "1",
            fp_log_id: "",
            asset_citytype: formData.cityType,
            asset_name: formData.nameOfProperty,
            asset_sub_category_id: formData.typeOfProperty,
            asset_purchase_amount: formData.purchaseValue,
            asset_amount: formData.currentValue,
            asset_purchase_date: moment(formData.purchaseDate).format("DD/MM/YYYY"),
            asset_pin_code: formData.pincode,
            asset_isrented: formData.residentialType == "rented" ? "0" : "1",
            asset_isMortgage: formData.mortgageOrFreehold == "mortgage" ? "1" : "0",
            asset_source: formData.asset_source
          },
          method: "post"
        }
        const resp = await fetchEncryptData(payload);
        if (resp.error_code == 100) {
          navigate(
            process.env.PUBLIC_URL +
            "/direct-mutual-fund/portfolio/dashboard?realestate=1"
          );
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Data added successfully.", type: "success", autoClose: 3000 }
          });
        } else {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Something went wrong, try again later.", type: "error", autoClose: 3000 }
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchInsuranceData = async () => {
    try {
      var payload = {
        url: GET_OTHER_INVESTMENTS,
        data: {
          user_id: getItemLocal("family") ? new_array : getUserId(),
          inv_type: "real_estate",
        },
        method: "post",
      };
      var res = await fetchEncryptData(payload);
      if (res.error_code == "100") {
        var realestateId = atob(id);
        const data = res.data.real_estate_data.real_estate_details.filter(
          (v) => v.realestate_id === Number(realestateId)
        );
        assignEstateData(data[0])
      } else {
        setFormData(initialValues);
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Error fetching your investment data.", type: "error", autoClose: 3000 },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
// Select Options Styles
const customStyles = {
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: "#ffff",
      color: isFocused ? "#000" : isSelected ? "#000" : "gray",
      cursor: "pointer",
    };
  },
  menuList: (base) => ({
    ...base,
    overflowY: "scroll",
    scrollBehavior: "smooth",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#005263",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
  return (
    <>
      <div className="px-0 px-md-4 assetForm">
        <div
          className="p-3"
          style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
        >
          {id != undefined ? (
            <div className="d-flex">
              <a href={`${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard?assetTabNumber=5`}>
                <img
                  style={{
                    transform: "rotate(180deg)",
                  }}
                  width={20}
                  height={20}
                  src={process.env.PUBLIC_URL + "/static/media/icons/chevron.png"}
                />
              </a>
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Edit Your Real Estate
              </h3>
            </div>
          ) : (

            <div className="d-flex">
              <a href={`${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard?assetTabNumber=5`}>
                <img
                  style={{
                    transform: "rotate(180deg)",
                  }}
                  width={20}
                  height={20}
                  src={process.env.PUBLIC_URL + "/static/media/icons/chevron.png"}
                />
              </a>
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Add Your Real Estate
              </h3>
            </div>
          )}

          <hr style={{ color: "#afafaf" }} />
          <div className="row">
            <div className="col-12 col-md-11 col-lg-8 m-auto">
              <p className="text-center">
                Enter Your Details To {id != undefined ? "Edit " : "Add "} Existing Assets
              </p>
              <br />
              <br />
              <div>
                <div className="my-md-4">
                  <div>
                    <span className="lbl-newbond">Type Of Property *</span>
                    <br />

                    <Select
                      className="fnto-dropdown-react"
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={customStyles}
                      options={options_type_of_real_estate}
                      value={getRealEstateTypeValue(
                        options_type_of_real_estate,
                        formData?.typeOfProperty
                      )}
                      name="typeOfProperty"
                      onChange={(e) => {
                        onDateAndSelectInputChange("typeOfProperty", e);
                        resetState();
                      }
                      }
                    />
                    {simpleValidator.current.message(
                      "typeOfProperty",
                      formData.typeOfProperty,
                      "required"
                    )}
                  </div>
                </div>

                <div>
                  <div
                    className="row"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <div className="col-md-12 col-12">
                      <div className="my-md-4">
                        <div>
                          <span className="lbl-newbond">Name Of Property *</span>
                          <br />
                          <input
                            placeholder="Enter Name Of Property"
                            className={` w-100 fntoo-textbox-react inputPlaceholder`}
                            type="text"
                            name="nameOfProperty"
                            value={formData.nameOfProperty}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        {simpleValidator.current.message(
                          "nameOfProperty",
                          formData.nameOfProperty,
                          "required|alpha_num_dash_space"
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="my-md-4">
                        <div>
                          <span className="lbl-newbond">Purchase Date *</span>
                          <br />
                          <div className="bonds-datepicker">
                            <FintooDatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={
                                formData.purchaseDate === ""
                                  ? ""
                                  : new Date(formData.purchaseDate)
                              }
                              showMonthDropdown
                              showYearDropdown
                              autoComplete="off"
                              dropdownMode="select"
                              name="purchaseDate"
                              customClass="datePickerDMF"
                              maxDate={new Date()}
                              onChange={(e) =>
                                onDateAndSelectInputChange(
                                  "purchaseDate",
                                  formatDatefun(e)
                                )
                              }
                              onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            />
                          </div>
                          {simpleValidator.current.message(
                            "purchaseDate",
                            formData.purchaseDate,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="my-md-4">
                        <div>
                          <span className="lbl-newbond">Purchase Value*</span>
                          <br />
                          <input
                            placeholder="Enter Purchase Value"
                            className={` w-100 fntoo-textbox-react Rupee-icon`}
                            type="text"
                            name="purchaseValue"
                            value={formData.purchaseValue}
                            onChange={(e) => onInputChange(e, true)}
                          />
                        </div>
                        {simpleValidator.current.message(
                          "purchaseValue",
                          formData.purchaseValue,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="my-md-4">
                        <div>
                          <span className="lbl-newbond">Current Value *</span>
                          <br />
                          <input
                            placeholder="Enter Current Value"
                            className={` w-100 fntoo-textbox-react Rupee-icon`}
                            type="text"
                            name="currentValue"
                            value={formData.currentValue}
                            onChange={(e) => onInputChange(e, true)}
                          />
                        </div>
                        {simpleValidator.current.message(
                          "currentValue",
                          formData.currentValue,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="my-md-4">
                        <div>
                          <span className="lbl-newbond">Pincode *</span>
                          <br />
                          <input
                            placeholder="Enter Pincode"
                            className={` w-100 fntoo-textbox-react inputPlaceholder`}
                            type="text"
                            name="pincode"
                            value={formData.pincode}
                            maxLength={6}
                            onChange={(e) => onInputChange(e, true)}
                          />
                        </div>
                        {simpleValidator.current.message(
                          "pincode",
                          formData.pincode,
                          "required|integer|max:6|min:6",
                          { messages: { required: "Please enter Pin Code." } }
                        )}
                      </div>
                    </div>
                    {isInputInPolicy(
                      "residentialType",
                      formData.typeOfProperty
                    ) && (
                        <div className="col-md-6 col-12">
                          <div className="my-md-4">
                            <div>
                              <span className="lbl-newbond">
                                Residential Type *
                              </span>
                              <br />
                            </div>
                            <FormRadioComponent
                              radioData={options_residental_type_radio}
                              onChange={onInputChange}
                              checkedOpton={formData.residentialType}
                            />
                          </div>
                        </div>
                      )}

                    <div className="col-md-6 col-12">
                      <div className="my-md-4">
                        <div>
                          <span className="lbl-newbond">
                            Mortgage Or Freehold *
                          </span>
                          <br />
                        </div>
                        <FormRadioComponent
                          radioData={options_mortgage_or_freehold}
                          onChange={onInputChange}
                          checkedOpton={
                            formData.mortgageOrFreehold

                          }
                        />
                      </div>
                    </div>

                    {isInputInPolicy("cityType", formData.typeOfProperty) && formData.residentialType === 'rented' && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">City Type *</span>
                            <br />
                          </div>
                          <FormRadioComponent
                            radioData={options_city_type}
                            onChange={onInputChange}
                            checkedOpton={formData.cityType}
                            isHorizontal={true}
                          />
                        </div>
                      </div>
                    )}

                  </div>
                </div>

                <br />
                <div className="my-md-4">
                  <button
                    type="submit"
                    className="d-block m-auto btn btn-primary"
                    onClick={() => validateForm()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewRealEstateFormView;
