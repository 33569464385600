import React, { useEffect, useState, useRef } from "react";
import "react-responsive-modal/styles.css";
import Profile_1 from "../../../Assets/01_pan_verfication.svg";
import Form from "react-bootstrap/Form";
import Verify from "../../../Assets/verify.png";
import { Row, Modal } from "react-bootstrap";
import axios from "axios";
import * as constClass from "../../../../constants";
import { useDispatch } from "react-redux";
import moment from "moment";
import commonEncode from "../../../../commonEncode";
import {
  apiCall,
  errorAlert,
  fetchEncryptData,
  getUserId,
  loginRedirectGuest,
  memberId,
} from "../../../../common_utilities";
import FintooButton from "../../../HTML/FintooButton";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import OTPInput from "otp-input-react";
import Success from "../../../Assets/Success.png";

import { CheckSession, successAlert } from "../../../../common_utilities";
import { AutoTabProvider } from "react-auto-tab";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import WhiteOverlay from "../../../../components/HTML/WhiteOverlay";

function Pan(props) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (val = true) => {
    setShow1(val);
    setTempKey(tempKey + 1);
  };
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => {
    setShow2(true);
    setTimeout(() => {
      setShow2(false);
      window.location.href =
        process.env.PUBLIC_URL + "/direct-mutual-fund/Profile?s=Birth";
    }, 5000);
  };

  const handleClose2 = () => setShow2(false);
  const [OTP, setOTP] = useState("");
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [, setKycPan] = useState("");
  const [kycName, setKycName] = useState(null);
  const userid = memberId();

  const [adharOtpError, setAdharOtpError] = useState("");
  const [otpgenerateerror, setotpgenerateerror] = useState("");
  const [kycVerified, setKycVerified] = useState(0);
  const [tempKey, setTempKey] = useState(0);
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (getUserId() == null) {
      loginRedirectGuest();
    }
  }, []);

  const { inputPanNumber, inputPanName } = form;

  useEffect(() => {
    props.dispatch({ type: "SET_PROGRESS_NAME", payload: kycName });
  }, [props, kycName]);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleChange = (e) => {
    e.target.value = e.target.value.toUpperCase();
    setField(e.target.id, e.target.value);
    setKycPan(e.target.value);
  };

  const findPanErrors = () => {
    const newErrors = {};

    let regex = /^([a-zA-Z]){3}(P|H){1}([a-zA-Z]){1}([0-9]){4}([a-zA-Z]){1}?$/;
    if (!inputPanNumber || inputPanNumber === "")
      newErrors.inputPanNumber = "Please enter your PAN!";
    else if (inputPanNumber.length !== 10)
      newErrors.inputPanNumber = "Please enter a valid PAN!";
    else if (!regex.test(inputPanNumber))
      newErrors.inputPanNumber = "Please enter a valid PAN!";
    return newErrors;
  };

  const findNameErrors = () => {
    const newErrors = {};
    let regex = /([a-zA-Z\s])/;
    if (!inputPanName || inputPanName === "")
      newErrors.inputPanName = "Please enter your name as per PAN!";
    else if (inputPanName.length > 50)
      newErrors.inputPanName = "Cannot be more than 50 charachters!";
    else if (!regex.test(inputPanName))
      newErrors.inputPanName = "Please enter a valid name!";
    return newErrors;
  };

  const getPanStatus = async (inputPanNumber) => {
    try {
      if (getUserId() == null) {
        props.dispatch({
          type: "RENDER_TOAST",
          payload: { message: constClass.GUEST_MESSAGE, type: "error" },
        });
        return;
      }
      let url = constClass.DMF_GETPANSTATUS_API_URL;
      let data = {
        pan: inputPanNumber,
        user_id: userid,
        is_direct: constClass.IS_DIRECT,
      };

      let respData = await fetchEncryptData({ url, data, method: "post" });
      if (respData["error_code"] === "100") {
        let name =
          respData["data"]["kyc_name"] !== ""
            ? respData["data"]["kyc_name"]
            : "";
        setField("inputPanNumber", inputPanNumber);
        setField("inputPanName", name);
        if (name) setKycVerified(1);
        setKycName(name);
        setIsDisabled(true);

        props.dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "You will not be able to edit PAN after clicking Next.",
            autoClose: 10000,
            type: "success",
          },
        });
      } else if (respData["error_code"] === "102") {
        if (respData["message"] !== "") {
          errorAlert(respData["message"]);
        } else {
          errorAlert();
        }
      }
    } catch (err) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went Wrong...", type: "error" },
      });
    }
  };
  if (kycName != null && kycName !== "") {
    var name_user = kycName;
    var aadhar_name = name_user;
  } else {
    var aadhar_name = inputPanName;
  }

  const updatePanData = (event) => {
    const newErrors = findPanErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    getPanStatus(inputPanNumber);
  };

  const handleSubmit = async () => {
    const newNameErrors = findNameErrors();
    const newPanErrors = findPanErrors();
    var ret = false;
    if (Object.keys(newNameErrors).length > 0) {
      setErrors(newNameErrors);
      ret = true;
    }
    if (Object.keys(newPanErrors).length > 0) {
      setErrors(newPanErrors);
      ret = true;
    }
    if (ret) return;
    if (getUserId() == null) {
      props.dispatch({
        type: "RENDER_TOAST",
        payload: { message: constClass.GUEST_MESSAGE, type: "error" },
      });
      return;
    }
    let url = constClass.DMF_UPDATEBASICDETAILS_API_URL;
    let data = {
      user_id: userid,
      pan: inputPanNumber,
      first_name: inputPanName,
      kyc_user_name: inputPanName,
      kyc_verified: kycVerified,
      is_direct: constClass.IS_DIRECT,
    };
    let respData = await fetchEncryptData({ url, data, method: "post" });
    if (respData["error_code"] === "100") {
    } else if (respData["error_code"] === "102") {
      if (respData["message"] !== "") {
        errorAlert(respData["message"]);
      } else {
        errorAlert();
      }
      return;
    }

    handleShow();
    props.dispatch({
      type: "SET_PROGRESS",
      payload: Math.round((1 / 18) * 100),
    });
  };

  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(10);
  useEffect(() => {
    timer.current.counter = timer.current.default;
  }, []);

  const startTimer = () => {
    setOTP("");
    clearInterval(timer.current.obj);
    timer.current.counter = timer.current.default;
    timer.current.obj = setInterval(() => {
      if (timer.current.counter > 0) {
        timer.current.counter = timer.current.counter - 1;
        setCount(timer.current.counter);
      } else {
        clearInterval(timer.current.obj);
        timer.current.counter = timer.current.default;
      }
    }, 1000);
  };

  const [num, setNum] = useState("");
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [otpvalue, setOtpValue] = useState();
  const [otpvalue2, setOtpValue2] = useState();
  const [otpvalue3, setOtpValue3] = useState();
  const [client_id, setclientids] = useState("");
  const [useraddress, setuseraddress] = useState("");
  const navigate = useNavigate();
  const limit = 4;
  const handleNumChange = (event) => {
    setNum(event.target.value.replace(/[^0-9\s]/g, "").slice(0, limit));

    setOtpValue(event.target.value);
    checkerror();
  };
  const handleNumChange1 = (event) => {
    setNum1(event.target.value.replace(/[^0-9\s]/g, "").slice(0, limit));
    setOtpValue2(event.target.value);
    checkerror();
  };
  const handleNumChange2 = (event) => {
    setNum2(event.target.value.replace(/[^0-9\s]/g, "").slice(0, limit));
    setOtpValue3(event.target.value);
    checkerror();
  };

  var aadhr_num = num + num1 + num2;
  function checkerror() {
    if (aadhr_num.length < 12) setotpgenerateerror("");
  }

  function inputaadhar() {
    if (aadhr_num == "" || aadhr_num == null) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Please enter aadhar number", type: "error" },
      });
    }
  }

  const generateotp = async () => {
    try {
      setShowLoader(true);
      var req_data = {
        id_number: aadhr_num,
      };
      var config = {
        method: "post",
        url: constClass.AADHAR_GENERATE_OTP,
        headers: constClass.AADHAR_HEADERS,
        data: req_data,
      };
      var res = await axios(config);
      setclientids(res["data"]["data"]["client_id"]);
      var status = res["data"]["status_code"];

      if (status == "200") {
        setShowLoader(false);
        props.dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "OTP has been sent successfully",
            type: "success",
          },
        });
        handleShow1();
        setotpgenerateerror("");
        startTimer(true);
      }
    } catch (e) {
      setShowLoader(false);
      e.toString().indexOf("failed") > -1;
      {
        setotpgenerateerror(
          "Oops! Something went wrong. please try after sometime."
        );
        handleShow1(false);
      }
    }
  };

  const verifyadharotp = async () => {
    setAdharOtpError("");
    setShowLoader(true);
    try {
      var sub_req_data = {
        client_id: client_id,
        otp: OTP,
      };

      var config = {
        method: "post",
        url: constClass.AADHAR_SUBMIT_OTP,
        headers: constClass.AADHAR_HEADERS,
        data: sub_req_data,
      };
      var res = await axios(config);

      var code = res.status;
      setuseraddress(res.data.data.address);

      const req = commonEncode.encrypt(JSON.stringify(res.data));
      localStorage.setItem("req", req);

      handleClose1();
      if (code == "200") {
        setShowLoader(false);
        aadharstatus();
        handleShow2(true);
        handleClose(true);
      } else {
        startTimer(false);
      }
    } catch (e) {
      setShowLoader(false);
      if (
        e.toString().indexOf("failed") > -1 &&
        e.toString().indexOf("400") > -1
      ) {
        setAdharOtpError("Enter OTP");
      } else if (
        e.toString().indexOf("failed") > -1 &&
        e.toString().indexOf("422") > -1
      ) {
        setAdharOtpError("Invalid OTP");
      } else {
        e.toString().indexOf("failed") > -1 && e.toString().indexOf("500") > -1;
        {
          setotpgenerateerror(
            "Oops! Something went wrong. Please try after sometime."
          );
          handleShow1(false);
          handleShow();
        }
      }
    }
  };

  const aadharstatus = async () => {
    try {
      var req_data = { user_id: userid, aadhar_verified: "yes" };
      var data = commonEncode.encrypt(JSON.stringify(req_data));

      var config = {
        method: "post",
        url: constClass.DMF_UPDATEBASICDETAILS_API_URL,
        data: data,
      };
      var res = await axios(config);
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went wrong...", type: "error" },
      });
    }
  };

  return (
    <Row className="reverse">
      <ToastContainer limit={1} />
      <WhiteOverlay show={showLoader} />
      <div className="ProfileImg col-12 col-md-6">
        <div>
          <img src={process.env.REACT_APP_STATIC_URL + "media/DMF/01_pan_verfication.svg"}  alt="" />
        </div>
      </div>
      <div className="col-12 col-md-6 RightPanel">
        <div className="rhl-inner">
          <h4>PAN Verification</h4>
          <p>Please enter your PAN for verification</p>

          <div className="pn-rht-bx">
            <div className="pn-rht-bx-txt">
              <Form.Control
                type="text"
                className="InputText shadow-none w-100"
                id="inputPanNumber"
                name="inputPanNumber"
                placeholder="Enter PAN"
                aria-describedby="inputPanNumBlock"
                maxLength={10}
                onChange={handleChange}
                isInvalid={!!errors.inputPanNumber}
                disabled={isDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {errors.inputPanNumber}
              </Form.Control.Feedback>
            </div>
            <div className="pt-4 pt-md-0">
              <FintooButton
                className="d-block me-0 ms-auto"
                type="button"
                onClick={() => {
                  updatePanData();
                }}
                title="Verify "
                disabled={isDisabled}
              />
            </div>
            {/* Modal Popup 1*/}
            <Modal centered show={show}>
              <div className="m-3 p-5 d-grid place-items-center align-item-center">
                <div className="text-center">
                  <h4>{aadhar_name}</h4>
                  <div>
                    Please Enter your Aadhar number to verify your address.
                  </div>
                </div>
                <div className="mt-5 text-center">
                  <h4>Aadhar Number</h4>
                </div>
                <AutoTabProvider>
                  <div className="d-flex align-item-center justify-content-between mt-5">
                    <input
                      className="form-control shadow-none"
                      type="text"
                      maxLength={4}
                      onPaste={handleChange}
                      value={num}
                      onChange={handleNumChange}
                      tabbable
                    />
                    {/* <span>-</span> */}
                    <input
                      className="form-control shadow-none ms-3"
                      type="text"
                      value={num1}
                      onChange={handleNumChange1}
                      onPaste={handleChange}
                      maxLength={4}
                      tabbable
                    />
                    <input
                      className="form-control shadow-none ms-3"
                      type="text"
                      value={num2}
                      onPaste={handleChange}
                      onChange={handleNumChange2}
                      maxLength={4}
                      tabbable
                    />
                  </div>
                </AutoTabProvider>
                <div className="d-flex align-item-center justify-content-center mt-5">
                  <center>
                    <FintooButton
                      type="button"
                      className="w-25 m-auto"
                      onClick={() => {
                        inputaadhar();
                        generateotp();

                        startTimer();
                      }}
                      disabled={aadhr_num.length < 12}
                      title="Verify "
                    />
                  </center>
                </div>
                <br></br>
                {otpgenerateerror && (
                  <p className="error mb-0">{otpgenerateerror}</p>
                )}
                <div className="d-flex align-item-center justify-content-center mt-5">
                  <center>
                    <div
                      style={{
                        color: "gray",
                        fontWeight: "600",
                        cursor: " pointer",
                      }}
                      onClick={() => {
                        handleClose();
                        window.location.href =
                          process.env.PUBLIC_URL +
                          "/direct-mutual-fund/Profile?s=Birth";
                      }}
                    >
                      Skip
                    </div>
                  </center>
                </div>
              </div>
            </Modal>

            <Modal
              centered
              show={show1}
              key={tempKey}
              className="profile-popups"
              onHide={() => {
                setShow1(false);
                setShow(true);
              }}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="m-3 p-5 pt-0 d-grid place-items-center align-item-center">
                  <div className="text-center">
                    <h4>{aadhar_name}</h4>
                    <div>
                      Please verify your aadhar details by entering the OTP.
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <h5>Aadhar Number</h5>
                  </div>
                  <div className="d-flex align-item-center justify-content-center ">
                    <div>
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1.3em",
                        }}
                      >
                        {num}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1.3em",
                          marginLeft: "1rem",
                        }}
                      >
                        {num1}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "1.3em",
                          marginLeft: "1rem",
                        }}
                      >
                        {num2}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-item-center justify-content-center mt-5">
                    <div>
                      <p
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        Enter OTP
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-item-center justify-content-center">
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      className="roundedBorder"
                      style={{
                        border: "none",
                      }}
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                    />
                  </div>
                  <div className="text-center mt-4">
                    {count === 0 && (
                      <label
                        className="Otp-resend-text mt-0 ml-2"
                        style={{
                          color: "#52afde",
                          textAlign: "center",
                          cursor: "Pointer",
                        }}
                        onClick={() => {
                          generateotp();
                        }}
                      >
                        Resend OTP
                      </label>
                    )}
                    {count > 0 && (
                      <label
                        className="Otp-resend-text mt-0 ml-2"
                        onClick={() => startTimer()}
                        style={{
                          color: "red",
                        }}
                      >
                        <strong>
                          {moment()
                            .startOf("day")
                            .seconds(count)
                            .format("mm:ss")}
                        </strong>
                      </label>
                    )}
                  </div>
                  <div className="d-flex align-item-center justify-content-center mt-5">
                    <center>
                      <FintooButton
                        type="button"
                        className="w-25 m-auto"
                        onClick={() => {
                          verifyadharotp();
                        }}
                        title="Confirm"
                      />
                      <br></br>
                      {adharOtpError && (
                        <p className="error mb-0">{adharOtpError}</p>
                      )}
                    </center>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal centered show={show2} onHide={handleClose2}>
              <div className="m-3 p-5 d-grid place-items-center align-item-center">
                <div className="d-flex align-item-center">
                  <img
                    alt="Success"
                    style={{
                      width: "60px",
                      margin: "auto",
                    }}
                    className="img-fluid SucessImg"
                    src={process.env.REACT_APP_STATIC_URL + "media/DMF/Success.png"}
                  />
                </div>
                <div className="text-center mt-4">
                  <p
                    style={{
                      fontSize: "1.7rem",
                      fontWeight: "700",
                    }}
                  >
                    Congratulations !
                  </p>
                </div>
                <div className="text-center">
                  <p
                    style={{
                      fontSize: "1.1em",
                      color: "gray",
                      fontWeight: "500",
                    }}
                  >
                    Your Aadhar details are verified Successfully.
                  </p>
                </div>
              </div>
            </Modal>
          </div>

          {/* if PAN name received */}
          {kycName != null && kycName !== "" && (
            <>
              <hr className="ProfileHr" />
              <div className="VerifyDetails">
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Please verify your name and click Next
                </p>
                {/* <small>Please verify your name and click Next</small> */}
                <div className="">
                  <div className="pn-rht-bx-txt">
                    <div
                      className="py-2 d-flex align-items-center"
                      style={{ display: "flex" }}
                    >
                      <div>
                        <img style={{ width: "32px" }}  src={process.env.REACT_APP_STATIC_URL + "media/DMF/verify.png"} alt="verify" />
                      </div>
                      <div>
                        <h4 className="m-0 PanName">
                          {kycName !== "" ? kycName : ""}
                        </h4>
                      </div>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.inputPanName}
                    </Form.Control.Feedback>
                  </div>
                  <div className="profile-btn-container fintoo-top-border pt-4 mt-4">
                    <p>
                      <span className="NotCorrect">Incorrect,</span>
                      <span
                        className="ChangePan"
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setField("inputPanName", "");
                          setKycName(null);
                          setIsDisabled(false);
                        }}
                      >
                        &nbsp;<b>Change PAN</b>
                      </span>
                    </p>
                    <FintooButton
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      title="Next"
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* if PAN name not received */}
          {kycName != null && kycName === "" && (
            <>
              <hr className="ProfileHr" />
              <div className="VerifyDetails">
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Please enter your name as per PAN
                </p>
                {/* <small>Please verify the name and click Next</small> */}
                <div className="pn-rht-bx">
                  <div className="pn-rht-bx-txt">
                    <Form.Control
                      type="text"
                      className="InputText shadow-none w-100"
                      id="inputPanName"
                      name="inputPanName"
                      placeholder="Enter Name"
                      aria-describedby="inputPanNameBlock"
                      maxLength={50}
                      onChange={(e) => {
                        if (e.target.value.match("^[a-zA-Z ]*$") != null) {
                          setField(e.target.id, e.target.value);
                        }
                      }}
                      isInvalid={!!errors.inputPanName}
                      value={inputPanName}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.inputPanName}
                    </Form.Control.Feedback>
                  </div>
                  <div className="profile-btn-container">
                    <FintooButton
                      type="button"
                      onClick={handleSubmit}
                      title="Next"
                    />
                  </div>
                </div>
              </div>
              <div className="MobilePanUI">
                <p>
                  <span className="NotCorrect">Incorrect,</span>
                  <span
                    className="ChangePan "
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setField("inputPanName", "");
                      setKycName(null);
                      setIsDisabled(false);
                    }}
                  >
                    &nbsp;<b>Change PAN</b>
                  </span>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  progressValue: state.progressValue,
  progressTitle: state.progressTitle,
});

export default connect(mapStateToProps)(Pan);
