// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_FinancialReport__XytmS {\n  /* background-color: #e8f6fc; */\n   background-color: #e8f6fc;\n  padding: 1rem;\n  display: grid;\n place-items: center;\n  align-items: center;\n}\n\n@media only screen and (max-width: 768px) {\n  .style_FinancialReport__XytmS {\n    background-color: #e8f6fc;\n    padding: 1rem;\n    display: block;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/HTML/FinancialPlan/FinancialReport/style.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;GAC9B,yBAAyB;EAC1B,aAAa;EACb,aAAa;CACd,mBAAmB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,yBAAyB;IACzB,aAAa;IACb,cAAc;EAChB;AACF","sourcesContent":[".FinancialReport {\n  /* background-color: #e8f6fc; */\n   background-color: #e8f6fc;\n  padding: 1rem;\n  display: grid;\n place-items: center;\n  align-items: center;\n}\n\n@media only screen and (max-width: 768px) {\n  .FinancialReport {\n    background-color: #e8f6fc;\n    padding: 1rem;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FinancialReport": "style_FinancialReport__XytmS"
};
export default ___CSS_LOADER_EXPORT___;
