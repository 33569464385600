// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.style_scrolltopdiv__rMo3B {\n    position: fixed;\n    z-index: 99999;\n    bottom: 160px;\n    right: 30px;\n    cursor: pointer;\n}\n.style_scrolltopdiv1__hgWd0{\n    bottom: 55px;\n    right: 30px;\n    cursor: pointer;\n    position: fixed;\n    z-index: 99999;\n}\n.style_scrolltopdiv1__hgWd0 img {\n    width: 40px;\n    height: 40px;\n}\n.style_scrolltopdiv__rMo3B img {\n    width: 40px;\n    height: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/HTML/ScrollToTop/style.module.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,cAAc;IACd,aAAa;IACb,WAAW;IACX,eAAe;AACnB;AACA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,eAAe;IACf,cAAc;AAClB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["\n.scrolltopdiv {\n    position: fixed;\n    z-index: 99999;\n    bottom: 160px;\n    right: 30px;\n    cursor: pointer;\n}\n.scrolltopdiv1{\n    bottom: 55px;\n    right: 30px;\n    cursor: pointer;\n    position: fixed;\n    z-index: 99999;\n}\n.scrolltopdiv1 img {\n    width: 40px;\n    height: 40px;\n}\n.scrolltopdiv img {\n    width: 40px;\n    height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrolltopdiv": "style_scrolltopdiv__rMo3B",
	"scrolltopdiv1": "style_scrolltopdiv1__hgWd0"
};
export default ___CSS_LOADER_EXPORT___;
