import React, { useRef, useState, useEffect } from "react";
import PortfolioLayout from "../../../components/Layout/Portfolio";
import pmc from "../../../components/Layout/Portfolio/portfolio.module.css";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import FintooBackButton from "../../../components/HTML/FintooBackButton";
import moment from "moment";
import { Link } from "react-router-dom";
import style from "./style.module.css";

import * as constClass from "../../../constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getUserId,
  loginRedirectGuest,
  apiCall,
  restApiCall,
  getRestApiHeaders,
  getParentUserId,
  getItemLocal,
  setItemLocal,
  setMemberId,
  fetchEncryptData,
  fetchData,
  getFpUserDetailsId,
} from "../../../common_utilities";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import IncompleteRegistration from "../../../components/IncompleteRegistration";
import FintooLoader from "../../../components/FintooLoader";
import OTPInput from "otp-input-react";

function LInkyourholdings(props) {
  const [show, setShow] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(120);
  const [modalType, setModalType] = useState(-1);
  // const [panEditable, setPanEditable] = useState(false);
  const panEditable = useRef(false);
 
  const [selectedMember, setSelectedMember] = useState({});
  // const [casResponse, setCasResponse] = useState("");
  const casResponse = useRef("");
  // const [waitforSms, setWaitforSms] = useState(false);
  // const [showlinkac, setShowLinkAc] = useState(false);
  const [panReadonly, setPanReadonly] = useState(false);

  const [otpInput, setOtpInput] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const interval = useRef(null);
  const [errors, setErrors] = useState({});
  const [sendDisabled, setSendDisabled] = useState(true);
  const mfToken = useRef("");
  const session = useRef("");
  const timeNewObj = useRef();
  const timeNewValue = useRef(120);
  const stopSmallCase = useRef(false);
  // const retryStatusCheckRef = useRef(0);


  const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6,
  };
  const maxLimit = 300;

  useEffect(() => {
    checksession();

    if (getUserId() == null) {
      loginRedirectGuest();
    }
    
    onLoadInit();
    document.body.classList.add("link-your-holding");
    return () => {
      document.body.classList.remove("link-your-holding");
      clearInterval(interval.current);
      clearInterval(timeNewObj.current);
      timeNewValue.current = 120;
    };
  }, []);

  const onLoadInit = async () => {
    try {
      await fetchMembers();
    } catch (e) {
      console.log(e);
    }
  };

  const checkHoldingStatus = async () => {
    try {

      let member_id = await getFpUserDetailsId();
      console.log("memberpp:", member_id);

      let reqData = {
        pan: selectedMember.pan,
        fp_user_id: member_id,
      };
    
      let checkiffetchedtoday = await fetchData({
          method: "post",
          url: constClass.DMF_CHECKIFPANEXISTS_API_URL,
          data: reqData,
        });
        
      if (checkiffetchedtoday.error_code == "101") {
        setModalType(3);
        return;
      }else {
        setModalType(0);
      }
     
     
      // const payload = {
      //   method: "post",
      //   url: constClass.RMF_GET_SC_CHECK_STATUS,
      //   data: {
      //     pan: selectedMember.pan,
      //     is_direct: constClass.IS_DIRECT
      //   }
      // }
      // let reqData = {
      //   pan: selectedMember.pan,
      //   is_direct: "1"
      // };

      // let checkiffetchedtoday = await restApiCall(
      //   constClass.ADVISORY_CHECK_HOLDINGS_FETCHED_TODAY_FROM_DMF,
      //   reqData,
      //   restHeaders
      // );

      // if (checkiffetchedtoday.error_code == "101") {
      //   // toastr.options.positionClass = "toast-bottom-left";
      //   // toastr.error(checkPan.message);
      //   setModalType(3);
      //   return;
      // }
      // else {
      //   setModalType(0);
      // }
      // // setModalType(0);


      // let res = await fetchData(payload);

      // console.log('nil67', res);
      // res = {error_code: 100, data: [{'status': 'success'}]}
      // if(res.error_code == 100) {
      //   // console.log('nil67', res.data);
      //   if(Array.isArray(res.data) && res.data.length > 0) {
      //     switch(res.data[0].status.toLowerCase()) {
      //       case 'success':
      //         setModalType(3);
      //         break;
      //       case 'pending':
      //         if(retryStatusCheckRef.current < 15) {
      //           retryStatusCheckRef.current = retryStatusCheckRef.current + 1;
      //           setTimeout(()=> checkHoldingStatus(), 20000);
      //         }
      //         setModalType(2);
      //         break;
      //       default:
      //         setModalType(0);
      //     }
      //   } else {
      //     setModalType(0);
      //   }
      // } else {
      //   setModalType(0);
      // }
    } catch {

    }
  }

  const fetchMembers = async () => {
    try {
      // const resp = await apiCall(constClass.GET_MEMBER_LIST, {
      //   user_id: getParentUserId(),
      //   is_direct: constClass.IS_DIRECT,
      //   ecas: "1",
      // });
      const resp = await fetchData({
        method: "post",
        url: constClass.GET_MEMBER_LIST,
        data: {
          user_id: getParentUserId(),
          is_direct: constClass.IS_DIRECT,
          // ecas: "1",
        }
      });
  

      const all = resp.data.map((v) => ({
        name: v.NAME ? v.NAME : v.NAME,
        id: v.id,
        fp_log_id: v.fp_log_id,
        parent_user_id: v.parent_user_id,
        pan: v.pan,
        mobile: v.mobile,
        email: v.fdmf_email,
        label: v.NAME ? v.NAME : v.fdmf_email,
        value: v.id,
        fp_user_id : v.fp_user_details_id,
      }));
      setAllMembers([...all]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleChange();
  }, [allMembers]);

  useEffect(() => {
    if(Object.keys(selectedMember).length > 0) {
      checkHoldingStatus();
    }
  }, [selectedMember]);

  useEffect(() => {
    const mobileErrors = findMobileErrors();
    const panErrors = findPANErrors();
    if (
      !panEditable.current &&
      selectedMember.pan != "" &&
      selectedMember.pan != null
    ) {
      if (Object.keys(panErrors).length > 0) {
        setErrors((v) => ({ ...v, ...panErrors }));
      }
    }
    if (selectedMember.mobile != "" && selectedMember.mobile != null) {
      if (Object.keys(mobileErrors).length > 0) {
        setErrors((v) => ({ ...v, ...mobileErrors }));
      }
    }
  }, [selectedMember.pan, selectedMember.mobile]);


  const findMobileErrors = () => {
    const newErrors = {};
    let regex = /^[6789]\d{9}$/;
    if (!selectedMember.mobile || selectedMember.mobile === "")
      newErrors.userMobile = "Please enter valid mobile number!";
    else if (selectedMember.mobile.length !== 10)
      newErrors.userMobile = "Please enter valid mobile number!";
    else if (!regex.test(selectedMember.mobile))
      newErrors.userMobile = "Please enter valid mobile number!";
    else if (
      selectedMember.mobile ||
      regex.test(selectedMember.mobile) ||
      selectedMember.mobile.length == 10
    )
      newErrors.userMobile = "";
    return newErrors;
  };

  const findOtpErrors = () => {
    const newErrors = {};
    if (!otpInput || otpInput === "")
      newErrors.otpInput = "Please enter valid otp!";
    else if (otpInput.length !== 6)
      newErrors.otpInput = "Please enter valid otp!";
    return newErrors;
  };

  const findPANErrors = (enteredPAN = "", change_flag = "0") => {
    const newErrors = {};
    let regex = /^[A-Za-z]{3}[HPhp]{1}[A-Za-z]{1}\d{4}[A-ZHPa-zhp]{1}$/;
    var pan = ""
    if (change_flag == "1") {
        pan = enteredPAN
    }
    else {
        pan = selectedMember.pan
    }

    if (!pan || pan === "") {
        newErrors.userPan = "Please enter pan number!";
    } else if (pan.length !== 10) {
        newErrors.userPan = "Please enter valid pan number!";
    } else if (!regex.test(pan)) {
        newErrors.userPan = "Please enter valid pan number!";
    } else if (
        pan ||
        regex.test(pan) ||
        pan.length == 10
    ) {
        newErrors.userPan = "";
    }
    return newErrors;
  };

  const checkPANRegistered = async (pan) =>{
    let url =
      constClass.ADVISORY_CHECK_PAN_REGISTERED +
      "?uid=" +
      btoa("00" + session.current.data.id) +
      "&pan=" +
      pan;
    let checkpan = await apiCall(url, "", false, false);
    return checkpan;
  }

  const checksession = async () => {
    try {
      let url = constClass.CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let session_data = await fetchEncryptData({
        method: "post",
        url: url,
        data: data
      });  

      if (session_data.error_code == "100") {
        session.current = session_data;
    
      } else {
        loginRedirectGuest();
      }
    } catch (error) {
      console.log(error);
     
    }
  };

  console.log("ssssession: ", session)

  const checkenterpanexists = async () => {
    let user_id = session.current.data.id;

    if (selectedMember.pan != "" && selectedMember.pan != null) {
      let url =
        constClass.ADVISORY_CHECK_PAN_EXISTSS +
        "?uid=" +
        btoa("00" + user_id) +
        "&pan=" +
        selectedMember.pan;
      let checkpan = await fetchData({
        url: url,
        data: ""
      });
  
      return checkpan;
    }
  };

  const getMFToken = async () => {
    let reqData = {
      user_id: getParentUserId(),
      fp_log_id: session.current.data.fp_log_id,
    };
   
    let mfTok = await fetchData({
      method: "post",
      url: constClass.ADVISORY_MF_GENERATE_TOKEN,
      data: reqData
    });

    if (mfTok.error_code == "100") {
      return mfTok;
    } else {
      console.log("mf token error", mfTok);
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message:  "Someting went wrong!",
          type: "error",
        },
      });
     
    }
  };

  const generateClientRefNo = () => {
    return 'minty_' + moment().format('DDMMYY_HHmmss')
  };

  let member_id = getFpUserDetailsId()
  console.log("member id: ", member_id);

  const mfEncrypt = async (data) => {
    let reqData = data;
  
    let response = await fetchData({
      method: "post",
      url: constClass.ADVISORY_MF_ENCRYPT,
      data: reqData
    });
    if (response.error_code == "100") {
      return response;
    } else {
      console.log("encrypt error", response);
     
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Someting went wrong!",
          type: "error",
        },
      });
      
    }
  };

  const mfSignature = async (data) => {
    let reqData = data;
    
    let response = await fetchData({
      method: "post",
      url: constClass.ADVISORY_MF_GENERATE_SIGNATURE,
      data: reqData
    });

    if (response.error_code == "100") {
      return response;
    } else {
      console.log("signature error", response);
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Someting went wrong!",
          type: "error",
        },
      });
     
    }
  };

  const mfDecrypt = async (data) => {
    let reqData = data;
   
    let response = await fetchData({
      method: "post",
      url: constClass.ADVISORY_MF_DECRYPT,
      data: reqData
    });

    if (response.error_code == "100") {
      return response;
    } else {
      console.log("decrypt error", response);
     
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Someting went wrong!",
          type: "error",
        },
      });
    }
  };

  const mfSubmitCasSummaryRequest = async (data) => {
    let reqData = data;
    
    let response = await fetchData({
      method: "post",
      url: constClass.ADVISORY_MF_SUBMIT_CAS_SUM_REQUEST,
      data: reqData
    });

    let decryptPayload = {
      token: data["token"],
      data: response["data"]["response"],
    };
    let decryptResponse = await mfDecrypt(decryptPayload);
    if (response.error_code == "100") {
      if (decryptResponse?.error_code == "100") {
        casResponse.current = decryptResponse["data"];
        return decryptResponse;
      } else {
        console.log("error", decryptResponse);
       
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Someting went wrong!",
            type: "error",
          },
        });
      }
    } else {
      var errMsg = decryptResponse["data"]["errors"][0]["message"];
      
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: errMsg,
          type: "error",
        },
      });
      return;
    }
  };

  const sendOTP = async (refresh = 0) => {
    try {
      const mobileErrors = findMobileErrors();
      const panErrors = findPANErrors();
      // const panRegistred = await checkPANRegistered(selectedMember.pan);

      const enterPanExists = await checkenterpanexists();
      if (!panEditable.current && selectedMember.pan && !enterPanExists) {
        panErrors.userPan = enterPanExists;
      }

      // if (refresh == 0 && panRegistred != true){
      //   panErrors.userPan = panRegistred
      // }

      if (
        (Object.keys(mobileErrors).length > 0 ||
          Object.keys(panErrors).length > 0) &&
        (mobileErrors.userMobile !== "" || panErrors.userPan !== "")
      ) {
        setErrors({ ...mobileErrors, ...panErrors });
        return;
      }

      // const checkPan = await checkIfPanExists();
      // if (checkPan !== true) {
      //   dispatch({
      //     type: "RENDER_TOAST",
      //     payload: {
      //       message: "Someting went wrong!",
      //       type: "error",
      //     },
      //   });
      //   return;
      // }

      const mfCentralToken = await getMFToken();

      if (mfCentralToken.error_code === "100") {
        const mF_Token = mfCentralToken.data;
        mfToken.current = mF_Token;

        const clientRefNo = generateClientRefNo();
        
        const param = {
          clientRefNo,
          pan: selectedMember.pan,
          mobile: selectedMember.mobile,
          email: "",
          pekrn: "",
          otherApi: "DET_SUM",
        };

        const request = { token: mF_Token, data: param };
        const encryptRequest = await mfEncrypt(request);

        if (encryptRequest?.error_code === "100") {
          const encryptResponse = encryptRequest.data;
          const signRequest = { token: mF_Token, data: encryptResponse };
          const generateSignature = await mfSignature(signRequest);

          if (generateSignature?.error_code === "100") {
            const submitRequestPayload = {
              token: mF_Token,
              data: generateSignature.data,
            };
            const sendOTPResponse = await mfSubmitCasSummaryRequest(
              submitRequestPayload
            );

            if (sendOTPResponse.error_code === "100") {
              clearInterval(timeNewObj.current);
              timeNewValue.current = 120;
              setModalType(1);
              setDefaultTimer();
              setOtpInput("");
              startTimer();
              setErrors({});
              return;
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendOTPOld = async () => {
    try {
      const mobileErrors = findMobileErrors();
      if (Object.keys(mobileErrors).length > 0) {
        setErrors(mobileErrors);
        return;
      }

      let checkPan = await checkIfPanExists();
      if (checkPan != true) {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: checkPan,
            type: "error",
          },
        });
        return;
      }

      let jwtTok = await getJwtToken();
      if (jwtTok.error_code == "100") {
        let trxnIdData = await getTransactionId(jwtTok.data.token);
        if (trxnIdData.error_code == "100") {
          let trxnId = trxnIdData.data.data.data.transactionId;
          let sendOTP = await sendSmallcaseOTP(trxnId);
          if (sendOTP.error_code == "100") {
            clearInterval(timeNewObj.current);
            timeNewValue.current = 120;
            setItemLocal("trxnId", trxnId);
            // setShow(true);
            setModalType(1);
            setDefaultTimer();
            setOtpInput("");
            startTimer();
            setErrors({});
            return;
          }
        }
      }

      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Someting went wrong!",
          type: "error",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getMfDocuments = async () => {
    let member_id = await getFpUserDetailsId();

    if (casResponse.current) {
      let request = {
        token: mfToken.current,
        reqId: casResponse.current["reqId"],
        clientRefNo: casResponse.current["clientRefNo"],
        pan: selectedMember.pan,
        mobile: selectedMember.mobile,
        user_id: getParentUserId(),
        fp_user_id: member_id,
        dmf: "1"
      };

    
      let response = await fetchData({
        url: constClass.ADVISORY_GET_ALL_CAS_DOCUMENTS,
        method: "post",
        data: request
      });

      if (response.error_code == "100") {
        return response;
      } else {
        console.log("encrypt error", response);
        
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Someting went wrong!",
            type: "error",
          },
        });
      }
    }
  };


  const verifyMfCentralOTP = async () => {
    const otpErrors = findOtpErrors();
    if (Object.keys(otpErrors).length > 0) {
      setErrors(otpErrors);
      return;
    }
    if (!casResponse.current) {
     
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Someting went wrong!",
          type: "error",
        },
      });
      return;
    }
    const verifyPayload = { ...casResponse.current, enteredOtp: otpInput };
    const encryptRequest = await mfEncrypt({
      token: mfToken.current,
      data: verifyPayload,
    });

    if (encryptRequest?.error_code !== "100") {
      
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Someting went wrong!",
          type: "error",
        },
      });

      return;
    }

    const encryptResponse = encryptRequest.data;
    const generateSignature = await mfSignature({
      token: mfToken.current,
      data: encryptResponse,
    });

    if (generateSignature?.error_code !== "100") {
      
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Someting went wrong!",
          type: "error",
        },
      });
      return;
    }

    let member_id = await getFpUserDetailsId();

    const submitRequestPayload = {
      token: mfToken.current,
      data: generateSignature.data,
      pan: selectedMember.pan,
      mobile: selectedMember.mobile,
      fp_user_id: member_id,
    };
    const verifyOTP = await mfInvestorConsent(submitRequestPayload);

    if (verifyOTP?.error_code !== "100") {
     
      // dispatch({
      //   type: "RENDER_TOAST",
      //   payload: {
      //     message: "Someting went wrong!",
      //     type: "error",
      //   },
      // });
      return;
    }
    setModalType(2);
   

    const getMfData = await getMfDocuments();

    if (getMfData?.error_code === "100") {
     
      // setTimeout(() => {
      //   window.location.href = `${process.env.PUBLIC_URL}/direct-mutual-fund/portfolio/dashboard` 
      //   }, 
      //   2000)

        navigate(
          process.env.PUBLIC_URL +
          "/direct-mutual-fund/portfolio/dashboard"
        );

        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Holding Data has been fetched successfully",
            type: "success",
          },
        });
  
      
    }
  };

  const mfInvestorConsent = async (data) => {
    let reqData = data;
   
    let response = await fetchData({
      method: "post",
      url: constClass.ADVISORY_MF_INVESTOR_CONSENT,
      data: reqData,
    });

    if (response.error_code == "100") {
      return response;
    } else {
      let decryptPayload = {
        token: data["token"],
        data: response["data"]["response"],
      };
      let decryptResponse = await mfDecrypt(decryptPayload);

      if (decryptResponse?.error_code == "100") {
        var errMsg = decryptResponse["data"]["errors"][0]["message"];
       
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: errMsg,
            type: "error",
          },
        });
        return response;
      }
      else{
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Someting went wrong!",
            type: "error",
          },
        });
        return response;
      }
    }
  };


  const handleOtpChange = (e) => {
    setOtpInput(e.target.value);
  };

  const checkIfPanExists = async () => {
    try {
      var reqData = {
        method: "post",
        url: constClass.DMF_CHECKIFPANEXISTS_API_URL,
        data: {
          pan: selectedMember?.pan,
          fp_user_id: selectedMember?.id,
        },
      };
      let checkPan = await fetchData(reqData);
      if (checkPan.error_code == "100") return true;
      else if (checkPan.error_code == "101") return message;
      return "Something went wrong!";
    } catch (e) {
      console.error(e);
    }
  };

  const getJwtToken = async () => {
    try {
      var reqData = {
        method: "post",
        url: constClass.GET_JWTTOKEN_API_URL,
        data: {
          user_id: selectedMember.id,
          is_chat_bot: 1,
        },
      };

      let jwtTok = await fetchData(reqData);
      if (jwtTok.error_code == "100") return jwtTok;
      return "Something went wrong!";
    } catch (e) {
      console.error(e);
    }
  };

  const getTransactionId = async (jwtToken) => {
    try {
      let trxnId = await fetchData({
        method: "post",
        url: constClass.GETTRANSACTION_API_URL,
        data: {
          token: jwtToken,
        },
      });
      if (trxnId.error_code == "100") return trxnId;
      return "Something went wrong!";
    } catch (e) {
      console.error(e);
    }
  };

  const sendSmallcaseOTP = async (trxnId) => {
    try {
      let payload = {
        method: "post",
        url: constClass.SEND_SC_OTP_API_URL,
        data: {
          transactionId: trxnId,
          pan: selectedMember?.pan,
          phone: selectedMember?.mobile,
        },
        // headers: { ...restHeaders },
      };
      console.log("hello", payload);

      let sendOTP = await fetchData(payload);
      if (sendOTP.error_code == "100") return sendOTP;
      return "Something went wrong!";
    } catch (e) {
      console.error(e);
    }
  };

  const verifySmallcaseOTP = async () => {
    try {
      const otpErrors = findOtpErrors();
      if (Object.keys(otpErrors).length > 0) {
        setErrors(otpErrors);
        return;
      }

      let trxnId = getItemLocal("trxnId");
      const payload = {
        method: "post",
        data: {
          transactionId: trxnId,
          pan: selectedMember?.pan,
          phone: selectedMember?.mobile,
          is_chat_bot: 1,
          otp: otpInput,
          is_direct: constClass.IS_DIRECT
        },
        url: constClass.VERIFY_SC_OTP_API_URL,
      };

      let verifyOTP = await fetchData(payload);

      let errMsg = "";

      if (verifyOTP.error_code == "100") {
        setModalType(2);
        interval.current = setInterval(() => {
          if (stopSmallCase.current == false) {
            getSmallCaseData();
          }
        }, 10000);
        return;
      } else if (verifyOTP.error_code) {
        let errResp = JSON.parse(verifyOTP.data.data.data);
        errMsg = errResp.errors[0]?.message;
      }

      if (errMsg.includes("Entered OTP appears to be incorrect")) {
        setErrors({ otpInput: errMsg });
        return;
      }
      setModalType(0);
      setDefaultTimer();
      setOtpInput("");
      setErrors({});
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: errMsg ? errMsg : "Someting went wrong!",
          type: "error",
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getSmallCaseData = async () => {
    try{
    let reqData = {
      method: "post",
      data: {
        pan: selectedMember?.pan,
        user_id: selectedMember?.id,
        
      },
      url: constClass.DMF_CHECK_ECAS_S3,
    };

    let checkS3Data = await fetchData(reqData);
    if (checkS3Data.error_code == "100") {
      stopSmallCase.current = true;

      let scReqData = {
        method: "post",
        url: constClass.DMF_GET_SC_DATA_API_URL,
        data: {
          pan: selectedMember?.pan,
          user_id: selectedMember?.id,
          fp_user_id: selectedMember?.fp_user_id,
          dmf: "",
        },
      };

      // if (selectedMember.fp_log_id !== undefined && selectedMember.fp_log_id !== "") {
      //   console.log("hello1");
      //   scReqData.data.fp_log_id = selectedMember.fp_log_id;
      // }

      let getScData = await fetchEncryptData(scReqData);

      if (getScData.error_code == "100") {
        let msg =
          "Hello! Your holdings have been successfully linked with Fintoo! Regards. -Team Fintoo";
        let whatsapptext =
          "Hello! Your holdings have been successfully linked with Fintoo!\nRegards,\nTeam Fintoo";
        var urlsms_success = {
          mobile: selectedMember?.mobile,
          whatsappmsg: whatsapptext,
        };
        var config1 = {
          method: "post",
          url: constClass.DMF_SENDWPSMS_API_URL,
          data: urlsms_success,
        };

        var resp_success = await fetchEncryptData(config1);
      } else {
        let msg =
          "Hello! Something went wrong while gathering your holding data. Please try again.\nRegards,\nTeam Fintoo";
        let whatsapptext_fail =
          "Hello! Something went wrong while gathering your holding data. Please try again.\nRegards,\nTeam Fintoo";
        var urlsms_fail = {
          mobile: selectedMember?.mobile,
          whatsappmsg: whatsapptext_fail,
        };
        var config2 = {
          method: "post",
          url: constClass.DMF_SENDWPSMS_API_URL,
          data: urlsms_fail,
        };

        var resp_fail = await fetchEncryptData(config2);
      }
    }
  }catch(e){
    console.error(e)
  }
  };
  const handleChange = async (e) => {
    try {
      if (Boolean(e) == false) {
        let all_members = allMembers;
        let user_id = getUserId();

        console.log("all", all_members, " user_id", user_id);
        let member = allMembers.filter((v) => v.id == getUserId());

        if (Object.keys(member).length > 0 && (member[0].pan != null || member[0].pan != undefined)) {
          setPanReadonly(true);
        }

        setSelectedMember({ ...member[0] });
        setSendDisabled(false);
      } else {
        setSelectedMember(e);
        setErrors({});
        setSendDisabled(false);
      }
    } catch (e) {}
  };

  useEffect(() => {
    setDefaultTimer();
  }, []);

  const startTimer = () => {
    timeNewObj.current = setInterval(function () {
      if (timeNewValue.current <= 0) {
        clearInterval(timeNewObj.current);
      } else {
        timeNewValue.current = timeNewValue.current - 1;
        setCount(timeNewValue.current);
      }
    }, 1000);
  };

  const setDefaultTimer = () => {
    timer.current.counter = timer.current.default;
  };

  const handlePANChange = (e) => {
    const enteredPAN = e.target.value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 10);
    setSelectedMember({ ...selectedMember, pan: ("" + enteredPAN).toUpperCase() });

    const newErrors = findPANErrors(enteredPAN, "1");
    setErrors({ ...errors, ...newErrors });
  };

  const handleMobileChange = (e) => {
    const newMobile = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
    setSelectedMember({ ...selectedMember, mobile: newMobile });

    if (e.target.value.length == 10) {
      findMobileErrors();
    }
  };

  useEffect(() => {
    console.log("sdds", show, modalType);
    if (show && modalType) {
      document.getElementById("root").classList.add("blur-bg");
    } else {
      document.getElementById("root").classList.remove("blur-bg");
    }
  }, [show, modalType]);

  return (
    <PortfolioLayout>
      {/* <FintooLoader isLoading={isLoading} /> */}
      <IncompleteRegistration
        // open={isProfileIncomplete}
        onCloseModal={() => {
          // setIsProfileIncomplete(false);
          setSelectedMember({});
        }}
        handleSubmit={() => {
          setMemberId(selectedMember.id);
          {
            status === "N"
              ? navigate(
                  process.env.PUBLIC_URL + "/direct-mutual-fund/Profile?s=Birth"
                )
              : navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/profile");
          }
        }}
      />

      <div className={`cotaininer ${pmc.linkholdings}`}>
        <div className="row">
          <div className="col-4 m-auto">
            <div className="holdings-form-box p-3">
              {modalType == 0 && (
                <>
                  <div className={`${pmc.headerbox}`}>
                    <div className={`text-center ${pmc.Heading} d-flex pb-3`}>
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          "/direct-mutual-fund/portfolio/dashboard"
                        }
                      >
                        <img
                          style={{
                            transform: "rotate(180deg)",
                          }}
                          width={20}
                          height={20}
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/icons/chevron.png"
                          }
                        />
                      </Link>
                      <p className="text-center mb-0">
                        Link Your Mutual Fund Holdings
                      </p>
                    </div>
                  </div>
                  <div className={`${pmc.formSection} pt-3`}>
                    <div className={`text-center ${pmc.Text}`}>
                      Please select the respective member from the dropdown menu
                      to fetch holdings
                    </div>
                    {console.log('allMembers', allMembers)}
                    <div className={`${pmc.FormsFillup}`}>
                      {getItemLocal("family") && (
                        <div className="mt-md-5">
                          <span className={`${pmc.FormlableName}`}>
                            Member *
                          </span>
                          <Select
                            style={{
                              width: "100% !Important",
                            }}
                            classNamePrefix="sortSelect"
                            isSearchable={false}
                            options={allMembers}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            // value={allMembers.filter(
                            //   (v) => v.id == selectedUser
                            // )}
                          />
                        </div>
                      )}

                      <div className="mt-md-5">
                        <div className="">
                          <span className={`${pmc.FormlableName}`}>PAN *</span>
                          <br />
                          <input
                            placeholder="Enter Your PAN"
                            // className={``}
                            type="text"
                            value={selectedMember.pan}
                            className={`${pmc.inputs} w-100 ${panReadonly === true ?
                              "disabled"
                              : ""
                              }`}
                            readOnly={panReadonly}
                            onChange={(e) => handlePANChange(e)}
                          />
                          {errors.userPan && (
                            <p className="error">{errors.userPan}</p>
                          )}
                        </div>
                      </div>

                      <div className="mt-md-5">
                        <div className="">
                          <span className={`${pmc.FormlableName}`}>
                            Mobile Number *
                          </span>
                          <br />
                          <input
                            maxLength={10}
                            placeholder="Enter Mobile Number"
                            className={`${pmc.inputs} w-100`}
                            type="text"
                            value={selectedMember?.mobile}
                            onChange={(e) => handleMobileChange(e)}
                          />
                          {errors.userMobile && (
                            <p className="error">{errors.userMobile}</p>
                          )}
                        </div>
                      </div>
                      <div className={`${pmc.OtpBtn}`}>
                        <button
                          disabled={sendDisabled}
                          onClick={() => sendOTP()}
                        >
                          Send OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {modalType == 1 && (
                <>
                  <div className="d-flex justify-center ">
                    <div>
                      {" "}
                      <FintooBackButton
                        onClick={() => {
                          setModalType(0);
                        }}
                        onChange={(e) => handleOtpChange(e)}
                      />
                    </div>
                    <div
                      className="DeleteBank text-center pb-3 w-100"
                      style={{
                        borderBottom: "1px solid #eeee",
                      }}
                    >
                      <h3 className="mb-0 pb-0">Please Enter OTP</h3>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="">
                        <div>
                          <div className="modal-whitepopup-box-item  border-top-0 text-center">
                            <p>
                              We have sent you an OTP on your mobile number{" "}
                              <b>
                                +91{" "}
                                {selectedMember?.mobile
                                  .split("")
                                  .map((v, i) => (i > 2 && i < 8 ? "*" : v))
                                  .join("")}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div
                          className={`d-flex justify-center align-items-center  ${style.enterbox}`}
                        >
                          <div className="m-auto">
                            <OTPInput
                              value={otpInput}
                              onChange={setOtpInput}
                              autoFocus
                              className="link-holdings-otp w-100"
                              style={{
                                border: "none",
                              }}
                              OTPLength={6}
                              otpType="number"
                              disabled={false}
                            />
                            {errors.otpInput && (
                              <p className="otp-error">{errors.otpInput}</p>
                            )}
                          </div>
                        </div>
                        <div className="text-center grey-color">
                          {count == 0 && (
                            <p
                              className="pointer blue-color"
                              onClick={() => {
                                sendOTP();
                              }}
                            >
                              Resend OTP
                            </p>
                          )}
                          {count > 0 && (
                            <p>
                              Expire in &nbsp;
                              <strong>
                                {moment()
                                  .startOf("day")
                                  .seconds(count)
                                  .format("mm:ss")}
                              </strong>
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={` ${pmc.OTpConfirm}`}
                        onClick={() => {
                          verifyMfCentralOTP();
                        }}
                      >
                        Submit
                      </div>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </>
              )}
              {modalType == 2 && (
                <>
                  <div className={style["modal-cntn"] + " p-4"}>
                    <div>
                      <p className="pt-3">
                        <strong>
                          Your external mutual Fund portfolio sync is in
                          progress
                        </strong>
                      </p>
                      <p>
                        This may take 10 to 15 minutes. We will notify you once
                        your external portfolio is synced.
                      </p>
                    </div>
                    <div>
                      <button
                        className={style["long-btn"]}
                        onClick={() => {
                          navigate(
                            process.env.PUBLIC_URL +
                              "/direct-mutual-fund/portfolio/dashboard"
                          );
                        }}
                      >
                        DONE
                      </button>
                    </div>
                  </div>
                </>
              )}
              {modalType == 3 && (
                <>
                  <div className={style["modal-cntn"] + " p-4"}>
                    <div>
                      <p className="pt-3">
                        <strong>
                          Your external mutual Fund portfolio synced today.
                        </strong>
                      </p>
                      <p>
                      You can only link your holdings once every 24 hours. Try again later.
                      </p>
                    </div>
                    <div>
                      <button
                        className={style["long-btn"]}
                        onClick={() => {
                          navigate(
                            process.env.PUBLIC_URL +
                              "/direct-mutual-fund/portfolio/dashboard"
                          );
                        }}
                      >
                        DONE
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </PortfolioLayout>
  );
}

export default LInkyourholdings;
