import { useState } from "react";
import { useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import DatagatherReportLayout from "../../components/Layout/Datagather/Reports";
import RetirementCorpus from "../../Assets/Datagathering/Graph/RetirementCorpus";
import { Link } from "react-router-dom";
import FintooLoader from "../../components/FintooLoader";
import {
  CHECK_SESSION,
  ADVISORY_GET_RETIREMENT_INFO_API,
  ADVISORY_GET_RETIREMENT_GOAL_RECOMMENDATION_API,
  ADVISORY_GET_RETIREMENT_CORPUS_API,
  ADVISORY_GET_RETIREMENT_CASHFLOW_API,
  ADVISORY_GET_RECOMMENDED_RETIRE_CASHFLOW_API,
} from "../../constants";
import {
  getParentUserId,
  getItemLocal,
  apiCall,
  loginRedirectGuest,
  rsFilter,
  fetchEncryptData,
  setBackgroundDivImage,
} from "../../common_utilities";
import commonEncode from "../../commonEncode";
import RetirementCashflow from "./RetirementPlanningReport/RetirementCashflow";
import RetirementInfo from "./RetirementPlanningReport/RetirementInfo";
import RetirementCorpusPage from "./RetirementPlanningReport/RetirementCorpusPage";
import { ScrollToTop } from "./ScrollToTop";
const RetirementPlanning = () => {
  const [tab, setTab] = useState("tab1");
  const [secondtab, setSecondtab] = useState("tab4");
  const [isLoading, setIsLoading] = useState(true);
  const [retirementCashflowData, setRetirementCashflowData] = useState([]);
  const [recommendRetCashflowData, setRecommendRetCashflowData] = useState([]);
  const [retirementInfoData, setRetirementInfoData] = useState([]);
  const [retirementGoalData, setRetirementGoalData] = useState([]);
  const [screenHeader, setScreenHeader] = useState({});

  useEffect(() => {
    document.body.classList.add("dg-layout");
    document.body.classList.add("rp-layout");
    document.getElementById("report-bg-retirement").style.background =
      "url(" +
      imagePath +
      "/static/assets/img/reports/ill-retirment-planning.svg)" +
      " no-repeat right top";

    getMemberList();
    setBackgroundDivImage();

    return () => {
      document.body.classList.remove("dg-layout");
      document.body.classList.remove("rp-layout");
    };
  }, []);

  const retirementInfoAPI = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
    };
    let config = {
      method: "POST",
      url: ADVISORY_GET_RETIREMENT_INFO_API,
      data: api_data,
    };
    let response = await fetchEncryptData(config);
    if (response["error_code"] == "100") {
      let data = response["data"];
      let tempRetirementInfoData = { ...data };

      if (response["data"]["postretlife"]) {
        tempRetirementInfoData = {
          ...tempRetirementInfoData,
          screenheader_post_ret_life: response["screenheader_post_ret_life"],
          postretlife: response["data"]["postretlife"],
        };
      }

      if (response["data"]["lifetime_expense"]) {
        tempRetirementInfoData = {
          ...tempRetirementInfoData,
          screenheader_lifetime_exp: response["screenheader_lifetime_exp"],
          lifetime_expense: response["data"]["lifetime_expense"],
        };
      }

      if (response["data"]["limited_expense"]) {
        tempRetirementInfoData = {
          ...tempRetirementInfoData,
          screenheader_limited_exp: response["screenheader_limited_exp"],
          limited_expense: response["data"]["limited_expense"],
        };
      }

      if (response["data"]["income"]) {
        tempRetirementInfoData = {
          ...tempRetirementInfoData,
          screenheader_annual_income: response["screenheader_annual_income"],
          income: response["data"]["income"],
        };
      }

      if (response["data"]["income"]) {
        const updatedIncome = [];

        // for (let i = 0; i < response["data"]["income"].length; i++) {
        //   if (!Boolean(response["data"]["income"][i].annual_amount) || response["data"]["income"][i].annual_amount <= 0) {
        //     continue;
        //   }
        //   updatedIncome.push(response["data"]["income"][i]);
        // }

        let combinedIncome = [
          ...tempRetirementInfoData.income,
          ...updatedIncome,
        ];

        if (
          response["data"].onetime_insurance &&
          response["data"].recurring_insurance
        ) {
          combinedIncome = [
            ...combinedIncome,
            ...response["data"].onetime_insurance,
            ...response["data"].recurring_insurance,
          ];
        } else if (response["data"].onetime_insurance) {
          combinedIncome = [
            ...combinedIncome,
            ...response["data"].onetime_insurance,
          ];
        } else if (response["data"].recurring_insurance) {
          combinedIncome = [
            ...combinedIncome,
            ...response["data"].recurring_insurance,
          ];
        }

        tempRetirementInfoData = {
          ...tempRetirementInfoData,
          screenheader_annual_income: response["screenheader_annual_income"],
          income: combinedIncome,
        };
      }
      setRetirementInfoData({ ...tempRetirementInfoData });
    }
  };

  const retirementCorpusAPI = async (session_data) => {
    let api_data = {
      user_id: session_data?.["data"]?.["id"],
      fp_log_id: session_data?.["data"]?.["fp_log_id"],
    };
    let config = {
      method: "POST",
      url: ADVISORY_GET_RETIREMENT_CORPUS_API,
      data: api_data,
    };
    let response = await fetchEncryptData(config);
    if (response["error_code"] == "100") {
      let total_corpus_required = response.data["total_corpus_required"] || 0;
      let total_income_corpus = response.data["total_income_corpus"] || 0;
      setScreenHeader({ screenheader_corpus: response.screenheader_corpus });
      retirementGoalRecommendationAPI(
        session_data,
        total_corpus_required,
        total_income_corpus
      );
    }
  };

  const retirementGoalRecommendationAPI = async (
    session_data,
    total_corpus_required,
    total_income_corpus
  ) => {
    let api_data = {
      user_id: session_data?.["data"]?.["id"],
      fp_log_id: session_data?.["data"]?.["fp_log_id"],
      fp_user_id: session_data?.["data"]?.["fp_user_id"],
    };
    let config = {
      method: "POST",
      url: ADVISORY_GET_RETIREMENT_GOAL_RECOMMENDATION_API,
      data: api_data,
    };
    let response = await fetchEncryptData(config);
    if (response["error_code"] == "100") {
      var link_by_fintoo = [];
      for (var i in response.data.goal_achieved) {
        if (response.data.goal_achieved[i]["goal_category_id"] == "96") {
          var linked_assets_by_you = [];
          var linked_assets_by_fintoo = [];
          var gross_total_fintoo = 0;
          var gross_total_newinvest = 0;
          var gross_total_you = 0;
          var goal_amount = 0;
          var income_corpus = 0;

          if (
            response.data.goal_achieved[i].hasOwnProperty("linked_goal_assets")
          ) {
            response.data.goal_achieved[i]["linked_goal_assets"].forEach(
              function (assetss) {
                var new_obj = {};
                goal_amount = response.data.goal_achieved[i]["future_value"];
                income_corpus = response.data.goal_achieved[i]["income_corpus"];
                if (assetss["investment_type"] == "0") {
                  new_obj.asset_name =
                    assetss["name"] +
                    " | " +
                    assetss["category"] +
                    " | " +
                    assetss["member"];
                  new_obj.asset_future_value = assetss["asset_future_value"];
                  gross_total_you += assetss["asset_future_value"];
                  linked_assets_by_you.push(new_obj);
                } else if (assetss["investment_type"] == "1") {
                  new_obj.asset_name =
                    assetss["name"] +
                    " | " +
                    assetss["category"] +
                    " | " +
                    assetss["member"];
                  new_obj.asset_future_value = assetss["asset_future_value"];
                  gross_total_you += assetss["asset_future_value"];
                  linked_assets_by_you.push(new_obj);
                } else if (assetss["investment_type"] == "2") {
                  new_obj.asset_name =
                    assetss["asset_data"]["name"] +
                    " | " +
                    assetss["asset_data"]["category"] +
                    " | " +
                    assetss["asset_data"]["member"];
                  new_obj.asset_future_value = assetss["oa_goal_fv"];

                  gross_total_fintoo += assetss["oa_goal_fv"];
                  linked_assets_by_fintoo.push(new_obj);
                } else if (assetss["investment_type"] == "3") {
                  new_obj.asset_name = assetss["name"];
                  new_obj.asset_future_value = assetss["fv"];
                  gross_total_newinvest += assetss["fv"];
                } else if (assetss["investment_type"] == "4") {
                  new_obj.asset_name =
                    assetss["name"] +
                    " | " +
                    assetss["category"] +
                    " | " +
                    assetss["member"];
                  new_obj.asset_future_value = assetss["asset_future_value"];
                  gross_total_fintoo += assetss["asset_future_value"];
                  linked_assets_by_fintoo.push(new_obj);
                  // if (!$scope.assetcatlist.includes(""+assetss['asset_category_id'])){
                } else if (assetss["investment_type"] == "5") {
                  new_obj.asset_name =
                    assetss["name"] +
                    " | " +
                    assetss["category"] +
                    " | " +
                    assetss["member"];
                  new_obj.asset_future_value = assetss["asset_future_value"];
                  gross_total_fintoo += assetss["asset_future_value"];
                  linked_assets_by_fintoo.push(new_obj);
                }
              }
            );
          }

          var diff_afterlinkange = 0;
          diff_afterlinkange =
            gross_total_you +
            gross_total_fintoo -
            (goal_amount - income_corpus);
          var goal_new_obj = {
            total_asset_value_by_fintoo: gross_total_fintoo,
            total_new_investment_by_fintoo: gross_total_newinvest,
            linked_assets_by_fintoo: linked_assets_by_fintoo,
            diff_afterlinkange: diff_afterlinkange,
          };
          link_by_fintoo.push(goal_new_obj);
        }
      

      if (response.data.goal_achieved[i]["category_name"] == "Retirement") {
        let data = response.data.goal_achieved[i];
        let tempRetirementGoalData = { ...data };
        var linkedgoalassets = [];
        var newInvestments = [];

        for (var j in response.data.goal_achieved[i]["linked_goal_assets"]) {
          if (
            response.data.goal_achieved[i]["linked_goal_assets"][j][
              "investment_type"
            ] == 0 ||
            response.data.goal_achieved[i]["linked_goal_assets"][j][
              "investment_type"
            ] == 1
          ) {
            linkedgoalassets.push(
              response.data.goal_achieved[i]["linked_goal_assets"][j]
            );
          } else if (
            response.data.goal_achieved[i]["linked_goal_assets"][j][
              "investment_type"
            ] == 3
          ) {
            newInvestments.push(
              response.data.goal_achieved[i]["linked_goal_assets"][j]
            );
          }
          // else if (response.data.goal_achieved[i]['linked_goal_assets'][j]['investment_type'] == 4 ) {
          //   linkedbyfintoo.push(response.data.goal_achieved[i]['linked_goal_assets'][j]);
          // }
          // else if (response.data.goal_achieved[i]['linked_goal_assets'][j]['investment_type'] == 5 ) {
          //   linkedbyfintoo.push(response.data.goal_achieved[i]['linked_goal_assets'][j]);
          // }
        }

        // NEW ADDITIONAL INVESTMENT DATA SEGREGATION FUNCTION //
        const transformedData = newInvestments.reduce((acc, curr) => {
          const existingIndex = acc.findIndex(
            (item) => item.name === curr.name
          );
          if (existingIndex !== -1) {
            // Merge data with existing entry
            acc[existingIndex] = {
              ...acc[existingIndex],
              ...curr,
            };
          } else {
            // Add new entry if not already present
            acc.push(curr);
          }
          return acc;
        }, []);

        // NEW ADDITIONAL INVESTMENT DATA SEGREGATION FUNCTION //

        var TotalLinkedGoalAssets = 0;
        for (var k in linkedgoalassets) {
          TotalLinkedGoalAssets =
            TotalLinkedGoalAssets + linkedgoalassets[k]["asset_future_value"];
        }

        var TotalnewInvestments = 0;
        for (var k in transformedData) {
          TotalnewInvestments = TotalnewInvestments + transformedData[k]["fv"];
        }

        var Totallinkedbyfintoo = 0;
        for (var k in link_by_fintoo) {
          Totallinkedbyfintoo =
            Totallinkedbyfintoo + link_by_fintoo[k]["asset_future_value"];
        }

        var ShortSurpValue =
          TotalLinkedGoalAssets - (total_corpus_required - total_income_corpus);
        if (ShortSurpValue >= 0) {
          var ShortSurpText = "Surplus";
        } else {
          var ShortSurpText = "Shortfall";
        }
        var graphdata = [];
        var colorArr = [];

        if (total_corpus_required != undefined && total_corpus_required != 0) {
          graphdata.push({
            name: ["Goal Amount"],
            data: ["", Math.floor(total_corpus_required)],
          });
          colorArr.push("#9ac449");
        }

        if (TotalnewInvestments != undefined && TotalnewInvestments != 0) {
          graphdata.push({
            name: ["Assets Recommended by Fintoo"],
            data: [Math.abs(TotalnewInvestments), ""],
          });
          colorArr.push("#24a7df");
        }

        if (Totallinkedbyfintoo != undefined && Totallinkedbyfintoo != 0) {
          graphdata.push({
            name: ["Assets Linked by Fintoo"],
            data: [Math.floor(Totallinkedbyfintoo), ""],
          });
          colorArr.push("#e1b624");
        }

        if (TotalLinkedGoalAssets != undefined && TotalLinkedGoalAssets != 0) {
          graphdata.push({
            name: ["Assets Linked by You"],
            data: [Math.floor(TotalLinkedGoalAssets), ""],
          });
          colorArr.push("#f88221");
        }

        if (total_income_corpus != undefined && total_income_corpus != 0) {
          graphdata.push({
            name: ["Corpus Created For Post Retirement Income"],
            data: [Math.floor(total_income_corpus), ""],
          });
          colorArr.push("#1c94ad");
        }

        tempRetirementGoalData = {
          ...tempRetirementGoalData,
          graphdata: graphdata,
          colorArr: colorArr,
          total_corpus_required: total_corpus_required,
          total_income_corpus: total_income_corpus,
          ShortSurpText: ShortSurpText,
          ShortSurpValue: ShortSurpValue,
          linkedgoalassets: linkedgoalassets,
          linkedbyfintoo: link_by_fintoo,
          additionalInv: transformedData,
        };
        setRetirementGoalData(tempRetirementGoalData);
      }
    }
    }
  };

  const retirementCashflowAPI = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
    };
    let config = {
      method: "POST",
      url: ADVISORY_GET_RETIREMENT_CASHFLOW_API,
      data: api_data,
    };
    var response = await fetchEncryptData(config);
    if (response["error_code"] == "100") {
      let data = response["data"];
      let tempRetirementCashflowData = { ...data };
      tempRetirementCashflowData = {
        ...tempRetirementCashflowData,
        isRetirementData: data["is_retirementdata"],
        screenHeader: response["screen_header"],
        title:
          "Post Retirement Cashflow (Based On Accumulation From Existing Investments)",
      };
      let retirement_cashflow_data = response["data"];

      if (
        retirement_cashflow_data["yearly_lifetime_inc"].length > 0 &&
        retirement_cashflow_data["yearly_lifetime_insurance"].length > 0
      ) {
        tempRetirementCashflowData = {
          ...tempRetirementCashflowData,
          yearlyLifetimeInc: calculateYearlyIncome(
            retirement_cashflow_data["yearly_lifetime_inc"],
            retirement_cashflow_data["yearly_lifetime_insurance"]
          ),
        };
      } else if (
        retirement_cashflow_data["yearly_lifetime_insurance"].length > 0
      ) {
        tempRetirementCashflowData = {
          ...tempRetirementCashflowData,
          yearlyLifetimeInc:
            retirement_cashflow_data["yearly_lifetime_insurance"],
        };
      } else if (retirement_cashflow_data["yearly_lifetime_inc"].length > 0) {
        tempRetirementCashflowData = {
          ...tempRetirementCashflowData,
          yearlyLifetimeInc: retirement_cashflow_data["yearly_lifetime_inc"],
        };
      }

      if (
        retirement_cashflow_data["limited_inc"].length > 0 &&
        retirement_cashflow_data["limited_insurance"].length > 0
      ) {
        tempRetirementCashflowData = {
          ...tempRetirementCashflowData,
          yearlyLimitedInc: calculateYearlyIncome(
            retirement_cashflow_data["limited_inc"],
            retirement_cashflow_data["limited_insurance"]
          ),
        };
      } else if (retirement_cashflow_data["limited_insurance"].length > 0) {
        tempRetirementCashflowData = {
          ...tempRetirementCashflowData,
          yearlyLimitedInc: retirement_cashflow_data["limited_insurance"],
        };
      } else if (retirement_cashflow_data["limited_inc"].length > 0) {
        tempRetirementCashflowData = {
          ...tempRetirementCashflowData,
          yearlyLimitedInc: retirement_cashflow_data["limited_inc"],
        };
      }

      tempRetirementCashflowData = {
        ...tempRetirementCashflowData,
        formula: calculateFormula(retirement_cashflow_data),
      };

      let index;
      let value;
      for (
        index = 0;
        index < retirement_cashflow_data["closing_balance"].length;
        index++
      ) {
        const keys = Object.keys(
          retirement_cashflow_data["closing_balance"][index]
        );
        for (let i = 0; i < keys.length; i++) {
          if (retirement_cashflow_data["closing_balance"][index][keys[i]] < 0) {
            value = retirement_cashflow_data["closing_balance"][index][keys[i]];
            break;
          }
        }
        if (value) {
          break;
        }
      }
      tempRetirementCashflowData = {
        ...tempRetirementCashflowData,
        opening_balance: retirement_cashflow_data["opening_balance"].slice(
          0,
          index + 1
        ),
        closing_balance: retirement_cashflow_data["closing_balance"].slice(
          0,
          index + 1
        ),
      };

      setRetirementCashflowData({ ...tempRetirementCashflowData });
    }
  };

  const recommendRetireCashflowAPI = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
      fp_user_id: session_data["data"]["fp_user_id"],
    };
    let payload_data = commonEncode.encrypt(JSON.stringify(api_data));
    let res = await apiCall(
      ADVISORY_GET_RECOMMENDED_RETIRE_CASHFLOW_API,
      payload_data,
      false,
      false
    );
    let response = JSON.parse(commonEncode.decrypt(res));
    if (response["error_code"] == "100") {
      setIsLoading(false);
      let data = response["data"];
      let tempRecommendedRetireData = { ...data };
      tempRecommendedRetireData = {
        ...tempRecommendedRetireData,
        isRetirementData: data["is_retirementdata"],
        screenHeader: response["data1"],
        title: "Post Retirement Cashflow",
      };
      let recommend_retirement_data = response["data"];

      if (
        recommend_retirement_data["yearly_lifetime_inc"].length > 0 &&
        recommend_retirement_data["yearly_lifetime_insurance"] != 0
      ) {
        tempRecommendedRetireData = {
          ...tempRecommendedRetireData,
          yearlyLifetimeInc: calculateYearlyIncome(
            recommend_retirement_data["yearly_lifetime_inc"],
            recommend_retirement_data["yearly_lifetime_insurance"]
          ),
        };
      } else if (recommend_retirement_data["yearly_lifetime_insurance"] != 0) {
        tempRecommendedRetireData = {
          ...tempRecommendedRetireData,
          yearlyLifetimeInc:
            recommend_retirement_data["yearly_lifetime_insurance"],
        };
      } else if (recommend_retirement_data["yearly_lifetime_inc"].length > 0) {
        tempRecommendedRetireData = {
          ...tempRecommendedRetireData,
          yearlyLifetimeInc: recommend_retirement_data["yearly_lifetime_inc"],
        };
      }

      if (
        recommend_retirement_data["limited_inc"].length > 0 &&
        recommend_retirement_data["limited_insurance"] != 0
      ) {
        tempRecommendedRetireData = {
          ...tempRecommendedRetireData,
          yearlyLimitedInc: calculateYearlyIncome(
            recommend_retirement_data["limited_inc"],
            recommend_retirement_data["limited_insurance"]
          ),
        };
      } else if (recommend_retirement_data["limited_insurance"] != 0) {
        tempRecommendedRetireData = {
          ...tempRecommendedRetireData,
          yearlyLimitedInc: recommend_retirement_data["limited_insurance"],
        };
      } else if (recommend_retirement_data["limited_inc"].length > 0) {
        tempRecommendedRetireData = {
          ...tempRecommendedRetireData,
          yearlyLimitedInc: recommend_retirement_data["limited_inc"],
        };
      }

      tempRecommendedRetireData = {
        ...tempRecommendedRetireData,
        formula: calculateFormula(recommend_retirement_data),
      };

      setRecommendRetCashflowData({ ...tempRecommendedRetireData });
    } else {
      setIsLoading(false);
    }
  };

  const getMemberList = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };

      let session_data = await apiCall(url, data, true, false);
      if (session_data.error_code == "102") {
        loginRedirectGuest();
      } else {
        retirementInfoAPI(session_data);
        retirementCorpusAPI(session_data);
        // retirementGoalRecommendationAPI(session_data);
        retirementCashflowAPI(session_data);
        recommendRetireCashflowAPI(session_data);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const calculateYearlyIncome = (list1, list2) => {
    const yearly_income_result = {};
    for (let item of [...list1, ...list2]) {
      for (let [key, value] of Object.entries(item)) {
        yearly_income_result[key] = (yearly_income_result[key] || 0) + value;
      }
    }
    return Object.entries(yearly_income_result).map(([key, value]) => ({
      [key]: value,
    }));
  };

  const calculateFormula = (data) => {
    const limited_exp =
      data["limited_exp"].length > 0 ? data["limited_exp"] : 0;
    const yearly_lifetime_inc =
      data["yearly_lifetime_inc"].length > 0 ? data["yearly_lifetime_inc"] : 0;
    const limited_inc =
      data["limited_inc"].length > 0 ? data["limited_inc"] : 0;

    if (limited_exp && yearly_lifetime_inc && limited_inc) {
      return "(B+C)-(D+E)";
    } else if (limited_exp && yearly_lifetime_inc) {
      return "(B)-(D+E)";
    } else if (yearly_lifetime_inc && limited_inc) {
      return "(B+C)-(D)";
    } else if (limited_inc) {
      return "(C)-(D)";
    } else if (yearly_lifetime_inc) {
      return "(B)-(D)";
    } else if (limited_exp && limited_inc) {
      return "(D+E)";
    } else if (limited_exp) {
      return "(D+E)";
    } else {
      return "(D)";
    }
  };

  return (
    <DatagatherReportLayout>
      <FintooLoader isLoading={isLoading} />
      <div className="reports ">
        <div className="">
          <div className="background-div">
            <div class="bg active" id="report-bg-retirement"></div>
          </div>
          <div className="white-box">
            <div className="d-flex justify-content-md-center tab-box">
              <div className="d-flex top-tab-menu noselect">
                <div
                  className={`tab-menu-item ${tab == "tab1" ? "active" : ""}`}
                  onClick={() => setTab("tab1")}
                >
                  <div className="tab-menu-title">INTRODUCTION</div>
                </div>
                <div
                  className={`tab-menu-item ${tab == "tab2" ? "active" : ""}`}
                  onClick={() => setTab("tab2")}
                >
                  <div className="tab-menu-title">RETIREMENT CORPUS</div>
                </div>
                <div
                  className={`tab-menu-item ${tab == "tab3" ? "active" : ""}`}
                  onClick={() => setTab("tab3")}
                >
                  <div className="tab-menu-title">CASHFLOW</div>
                </div>
              </div>
            </div>

            <div>
              <div className={tab == "tab1" ? "d-block" : "d-none"}>
                <div>
                  <RetirementInfo data={retirementInfoData} />
                </div>
                <div>
                  <div className="row py-2">
                    <div className=" text-center">
                      <div>
                        <div className="btn-container fixedBtn">
                          <div className="d-flex justify-content-center">
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                "/report/cash-flow-management"
                              }
                            >
                              <div className="previous-btn form-arrow d-flex align-items-center">
                                <FaArrowLeft />
                                <span
                                  className="hover-text"
                                  style={{ maxWidth: 100 }}
                                >
                                  Previous&nbsp;
                                </span>
                              </div>
                            </Link>
                            <div
                              className="next-btn form-arrow d-flex align-items-center"
                              onClick={() => {
                                ScrollToTop();
                                setTab("tab2");
                              }}
                            >
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Next&nbsp;
                              </span>
                              <FaArrowRight />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={tab == "tab2" ? "d-block" : "d-none"}>
                <div>
                  <RetirementCorpusPage
                    data={retirementGoalData}
                    screen_header={screenHeader}
                  />
                </div>
                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <div
                            className="previous-btn form-arrow d-flex align-items-center"
                            onClick={() => {
                              ScrollToTop();
                              setTab("tab1");
                            }}
                          >
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                          {/* <button className="default-btn gradient-btn save-btn">
                                  Save & Add More
                                </button> */}
                          <div
                            className="next-btn form-arrow d-flex align-items-center"
                            onClick={() => {
                              ScrollToTop();
                              setTab("tab3");
                            }}
                          >
                            <span
                              className="hover-text"
                              style={{ maxWidth: 100 }}
                            >
                              Next&nbsp;
                            </span>
                            <FaArrowRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={tab == "tab3" ? "d-block" : "d-none"}>
                <div className="d-flex justify-content-md-center tab-box">
                  <ul
                    className=" nav-buttons nav-secoandary d-inline-flex"
                    id="intro-appendix"
                  >
                    <li
                      className={`tab-menu-item ${
                        secondtab == "tab4" ? "active" : ""
                      }`}
                    >
                      <a href onClick={() => setSecondtab("tab4")}>
                        Actual Cashflow
                      </a>
                    </li>
                    <li
                      className={`tab-menu-item ${
                        secondtab == "tab5" ? "active" : ""
                      }`}
                    >
                      <a href onClick={() => setSecondtab("tab5")}>
                        Fintoo Recommends Cashflow
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className={secondtab == "tab4" ? "d-block" : "d-none"}>
                    <RetirementCashflow
                      data={retirementCashflowData}
                      type={"Actual Cashflow"}
                    />
                    <div className="row py-2">
                      <div className=" text-center">
                        <div>
                          <div className="btn-container fixedBtn">
                            <div className="d-flex justify-content-center">
                              <div
                                className="previous-btn form-arrow d-flex align-items-center"
                                onClick={() => {
                                  ScrollToTop();
                                  setTab("tab2");
                                }}
                              >
                                <FaArrowLeft />
                                <span className="hover-text">
                                  &nbsp;Previous
                                </span>
                              </div>
                              <div
                                className="next-btn form-arrow d-flex align-items-center"
                                onClick={() => {
                                  ScrollToTop();
                                  setSecondtab("tab5");
                                }}
                              >
                                <span
                                  className="hover-text"
                                  style={{ maxWidth: 100 }}
                                >
                                  Next&nbsp;
                                </span>
                                <FaArrowRight />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={secondtab == "tab5" ? "d-block" : "d-none"}>
                    <RetirementCashflow
                      data={recommendRetCashflowData}
                      type={"Recommends Cashflow"}
                    />
                    <div className="row py-2">
                      <div className=" text-center">
                        <div>
                          <div className="btn-container fixedBtn">
                            <div className="d-flex justify-content-center">
                              <div
                                className="previous-btn form-arrow d-flex align-items-center"
                                onClick={() => {
                                  ScrollToTop();
                                  setSecondtab("tab4");
                                }}
                              >
                                <FaArrowLeft />
                                <span className="hover-text">
                                  &nbsp;Previous
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DatagatherReportLayout>
  );
};
export default RetirementPlanning;
