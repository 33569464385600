import { Link, useNavigate } from "react-router-dom";
import { CheckSession, getPublicMediaURL, getUserId } from "../../../../common_utilities";
import style from './TaxPricingView.module.css'
import { useEffect } from "react";
import { BASE_API_URL, imagePath } from "../../../../constants";
import commonEncode from "../../../../commonEncode";
import { useSelector } from "react-redux";
// import {
//     imagePath
// } from "../constants";

const category_name = "Assisted Advisory"

const TaxPricingView = ({ taxPricingData }) => {

    const loggedIn = useSelector(state => state.loggedIn);

    const navigate = useNavigate();

    console.log('getUserId()', getUserId())

    const getCallbackFun = async () => {
        if (!loggedIn) {
            return CheckSession();
        }
        let users = JSON.parse(commonEncode.decrypt(localStorage.getItem("allMemberUser")));
        let memberUsers = JSON.parse(commonEncode.decrypt(localStorage.getItem("member")));

        const currentUserData = users.filter(data => data.id == getUserId())[0]

        const memberUsersData = memberUsers.filter(data => data.id == getUserId())[0]

        console.log('currentUserDatacurrentUserData', currentUserData)

        const payload = {
            "fullname": memberUsersData.name,
            "mobile": currentUserData.mobile,
            "mailid": currentUserData.email,
            "country_code": 91,
            "tags": "itr_notice",
            "status": "Entry",
            "service": 35,
            "rm_id": 24
        };
        try {
            const response = await fetch(BASE_API_URL + "restapi/callback/", {
                method: 'POST',
                // headers: myHeaders,
                body: JSON.stringify(payload),
            });

            console.log('getCallbackFun', response)
            if (response.ok) {
                navigate(`${process.env.PUBLIC_URL}/thank-you-page`)
                return;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };


    return (
        <div className="container-fluid white-bg">
            <br /><br />
            <div>
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12 w-100">
                        <div className="text-center mb-80">
                            <h2 className="upperText">
                                Looking for Tax Advisor? Our team of Tax Experts is at your service!
                            </h2>
                            <p
                                className="mt-2 BottomText"
                                style={{
                                    color: "gray",
                                }}
                            >
                                Your friends can advise you on movies, let Fintoo Tax Experts take care of your taxes
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`${style.TaxPricingContainer}`}>

                    <div className={`${style.TaxPricingMainContainer}`}>
                        <div className={`${style.TaxPricingMainContainerTitle}`}>How it works</div>

                        <div className={`${style.TaxPricingStepsContainer}`} >
                            <div className={`${style.TaxPricingStepItem}`}>
                                <div className={`${style.TaxPricingStepItemImg}`}> <img src={getPublicMediaURL("static/media/DG/Images/book_expert.svg")} alt="" /> </div>
                                <div className={`${style.TaxPricingStepsText}`}>Select the Expert</div>
                            </div>
                            <div>
                                <div className={`${style.TaxPricingStepItemConnector}`}> <img src={getPublicMediaURL("static/media/DG/Images/steps_after.svg")} alt="" /> </div>
                            </div>
                            <div className={`${style.TaxPricingStepItem}`}>
                                <div className={`${style.TaxPricingStepItemImg}`}> <img src={getPublicMediaURL("static/media/DG/Images/pay-fees.svg")} alt="" /> </div>
                                <div className={`${style.TaxPricingStepsText}`}>Pay for Consultancy</div>
                            </div>
                            <div>
                                <div className={`${style.TaxPricingStepItemConnector}`}> <img src={getPublicMediaURL("static/media/DG/Images/steps_after.svg")} alt="" /> </div>
                            </div>
                            <div className={`${style.TaxPricingStepItem}`}>
                                <div className={`${style.TaxPricingStepItemImg}`}> <img src={getPublicMediaURL("static/media/DG/Images/pricing_calender.svg")} alt="" /> </div>
                                <div className={`${style.TaxPricingStepsText}`}>Book an Appointment</div>
                            </div>
                            <div>
                                <div className={`${style.TaxPricingStepItemConnector}`}> <img src={getPublicMediaURL("static/media/DG/Images/steps_after.svg")} alt="" /> </div>
                            </div>
                            <div className={`${style.TaxPricingStepItem}`}>
                                <div className={`${style.TaxPricingStepItemImg}`}> <img src={getPublicMediaURL("static/media/DG/Images/get_call.svg")} alt="" /> </div>
                                <div className={`${style.TaxPricingStepsText}`}>Upload Documents</div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="pric-tables price-table">
                                <div className="PricingBox">
                                    {
                                        taxPricingData.map((x, idx) =>
                                            <div style={{ marginTop: "2rem" }}
                                                key={idx}
                                                className={`item sm-mb50 item-active`}
                                            >
                                                <div className="type Price-type text-center mb-15">
                                                    <div style={{ fontWeight: "bold", color: "#fff" }}>
                                                        {x.category_name.toUpperCase()}
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="amount2 text-center mb-40">
                                                    {x.id == 8 && (
                                                        <h4>₹ {x.monthly_amount}</h4>
                                                    )}
                                                    {x.id == 9 && (
                                                        <h4>{x.monthly_amount}</h4>
                                                    )}
                                                </div>

                                                {x.id == 9 ? (
                                                    <div className="text-center custombtmText text-gray p">
                                                        Get a completely customised plan according
                                                        to your unique requirement
                                                    </div>
                                                )
                                                    :
                                                    <div className="text-center custombtmText text-gray p">
                                                        Onwards
                                                    </div>

                                                }

                                                <div className="feat">
                                                    <ul>
                                                        {
                                                            x.offered_services.map((data, idx) =>
                                                                <li className="d-flex align-items-center" key={idx}>
                                                                    <span>
                                                                        {data.is_available == 0 && (
                                                                            <img
                                                                                className="pe-2"
                                                                                style={{ width: "16px" }}
                                                                                src={
                                                                                    process.env.REACT_APP_STATIC_URL +
                                                                                    "media/Pricing/cancel.png"
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                        {data.is_available == 1 && (
                                                                            <img
                                                                                className="pe-2"
                                                                                style={{ width: "16px" }}
                                                                                src={imagePath + "/web/static/media/Pricing/check.svg"}
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </span>
                                                                    <span
                                                                        className={`ml-2`}
                                                                    >
                                                                        {data.title}
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="BtnBox">
                                                    {x.id == 9 ? (
                                                        <button onClick={() => getCallbackFun()}>
                                                            {" "}
                                                            Request a Callback{" "}
                                                        </button>
                                                    )
                                                        :
                                                        <Link to={`${process.env.PUBLIC_URL}/expert?service=tax-planning`}>
                                                            <button>
                                                                Get Started
                                                            </button>
                                                        </Link>
                                                    }
                                                </div>
                                                <br></br>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                </div>


            </div>

        </div>


    );
};
export default TaxPricingView;
