import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import HDFC from "../../../Assets/Images/hdfc.png";
// import IoIosMore from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Delete from "../../../components/Assets/Dashboard/delete_Gray.png";
import DeleteBank from "../../../components/Assets/Dashboard/delete_bank.png";
import List from "../../../components/Assets/Dashboard/Bank_list.png";
import axios from "axios";
import { DMF_BASE_URL, DMF_DELETEBANK_API_URL, DMF_GETUSERBANKS_API_URL } from "../../../constants";
import commonEncode from "../../../commonEncode";
import { CheckSession, getUserId } from "../../../common_utilities";
import { ToastContainer, toast } from "react-toastify";
import ProfileBank from "./Profile.bank.module.css"
function Profilebank(props) {
  const [removed, setRemoved] = useState(false);
  const [show, setShow] = useState(false);
  const [deletedBank, setDeletedBank] = useState(false);
  const [userBanks, setUserBanks] = useState([]);
  const [mandateList, setMandateList] = useState([{}]);
  const navigate = useNavigate();
  const [mandate, setmandatestatus] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    // CheckSession();

  }, []);


  useEffect(function () {
    onLoadInIt();
  }, []);

  const onLoadInIt = async () => {
    try {
      var mandaterequest = { bank_id: String(props.userBanks.bank_id), };
      var data = commonEncode.encrypt(JSON.stringify(mandaterequest));

      var config = {
        method: "post",
        url: DMF_BASE_URL + "api/bank/getmandatelist",
        data: data,
      };
      var res = await axios(config);
      var responsegetmandate = commonEncode.decrypt(res.data);
      setMandateList(JSON.parse(responsegetmandate)["data"]);
      var mandate = JSON.parse(response);

      var mandatebankid = mandate.mandate_bankid;
      var mandate_status = mandate.error_code;
    } catch (e) {

      //   setError(true);
    }

    var user_id = getUserId();
    try {
      var urldata = { user_id: user_id };
      var data = commonEncode.encrypt(JSON.stringify(urldata));
      var config = {
        method: "post",
        url: DMF_GETUSERBANKS_API_URL,
        data: data,
      };

      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      var res_data = (JSON.parse(response)["data"]);
      setUserBanks(res_data);
    } catch (e) {
      setError(true);
    }
  };

  var userid = getUserId();
  const renderDeleteBank = async () => {
    var urldatadelete = {
      bank_id: String(props.userBanks.bank_id),
      bank_user_id: userid,
    };
    var data = commonEncode.encrypt(JSON.stringify(urldatadelete));
    var config = {
      method: "post",
      url: DMF_DELETEBANK_API_URL,
      data: data,
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    setDeletedBank(JSON.parse(response));
    var response_obj = JSON.parse(response);
    let error_code = response_obj.error_code;

    if (error_code == "100") {
      toast.success("Account deleted successfully.", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    }
  };

  return removed ? (
    <></>
  ) : (
    <>
      <ToastContainer />
      <div className="Bank-P-de">
        <div className={`Bank-details ${ProfileBank.ProfileBankBox}`}>

          <div className="w-100">
            <div className={` ${ProfileBank.ProfileBankDetails}`}>
              <div className="d-flex align-items-center">
                <div className="bank-name d-block">
                  <div style={{ padding: "3px" }} className="bank-logo">
                    <img className="rounded-circle" src={`${process.env.REACT_APP_STATIC_URL}/media/bank_logo/${props.userBanks.bank_bse_code ? props.userBanks.bank_bse_code : 'img_default'}.png`} />
                  </div>
                </div>
                <div className="ms-3">
                  <div className={`${ProfileBank.BankName}`}>{props.userBanks.bank_name}</div>
                  <div className={`${ProfileBank.BankStatus}`}>Digital Autopay Supported</div>
                </div>
              </div>
              <div className="d-md-flex  align-items-center">
                <div className="d-none d-md-block">
                  {props.userBanks.emandate_allow == '1' && (
                    <button className={`${ProfileBank.AddMandate}`}>
                      {/* <Link to={process.env.PUBLIC_URL + `/direct-mutual-fund/profile/dashboard/bankaccount/ProfileMandate?bank_id=${props.userBanks.bank_id}`}> */}
                      <Link to={process.env.PUBLIC_URL + `/direct-mutual-fund/mycart-mandate-limit?bank_id=${btoa(props.userBanks.bank_id)}`}>
                        + Add Mandate
                      </Link>{" "}
                    </button>
                  )}

                </div>
                <div className="d-none d-md-block">
                  {props.userBanks.emandate_allow == '1' && props.userBanks.mandate_status == '1' && (
                    <button className={`ms-3 ${ProfileBank.MandateList}`}>
                      <Link to={process.env.PUBLIC_URL + `/direct-mutual-fund/profile/dashboard/bankaccount/ProfileMandate/Manadatestatus?bank_id=${props.userBanks.bank_id}`}>
                        Mandate List
                      </Link>
                    </button>
                  )}
                </div>
                <div className="">
                  {props.hideDelete == false && (
                    <div className="delete-bank ms-3">
                      <img
                        style={{ width: "15px", cursor: "pointer" }}
                        onClick={handleShow}
                        src={process.env.REACT_APP_STATIC_URL + "media/DMF/Delete_Bank.svg"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`align-items-center mt-3  ${ProfileBank.ProfileBankDetails} ${ProfileBank.ProfileBankSpace}`}>
              <div>
                <div className={`${ProfileBank.text1}`}>Account Number</div>
                  <div className={`${ProfileBank.text2}`}>{props.userBanks.bank_acc_no}</div>
              </div>
              <div>
                <div className={`${ProfileBank.text1}`}>IFSC Code</div>
                  <div className={`${ProfileBank.text2}`}>{props.userBanks.bank_ifsc_code}</div>
              </div>
              <div>
                <div className={`${ProfileBank.text1}`}>Branch Name</div>
                  <div className={`${ProfileBank.text2}`}>{props.userBanks.bank_branch}</div>
              </div>
            </div>
            <div className={`d-flex d-md-none align-items-center mt-3 ${ProfileBank.ProfileBankDetails}`}>
              <div>
                  {props.userBanks.emandate_allow == '1' && (
                    <button className="Add-manadate">
                      <Link to={process.env.PUBLIC_URL + `/direct-mutual-fund/profile/dashboard/bankaccount/ProfileMandate?bank_id=${props.userBanks.bank_id}`}>
                        + Add Mandate
                      </Link>{" "}
                    </button>
                  )}
              </div>
              <div>
                {props.userBanks.emandate_allow == '1' && props.userBanks.mandate_status == '1' && (
                  <button className={`ms-3 ${ProfileBank.MandateList}`}>
                    <Link to={process.env.PUBLIC_URL + `/direct-mutual-fund/profile/dashboard/bankaccount/ProfileMandate/Manadatestatus?bank_id=${props.userBanks.bank_id}`}>
                      Mandate List
                    </Link>
                  </button>
                )}
              </div>
            </div>
            <div className={`d-none d-md-block ${ProfileBank.noteSection}`}>
              <span>Note :</span> You cannot edit and delete this bank as you have used this bank for your investments.
            </div>
          </div>
        </div>
      </div>

      {/* Delete Bank Popup */}
      <Modal
        className="deleteBankPopup modal-md"
        show={show}
        centered
        onHide={handleClose}
        dialogClassName="modal-medium"
        size="lg"
      >
        <div className="d-flex justify-center p-4">
          <div
            className="DeleteBank text-center pb-3 w-100"
            style={{
              borderBottom: "1px solid #eeee",
            }}
          >
            Delete Bank Account
          </div>
        </div>
        <Modal.Body>
          <center>
            <div className="">
              <div>
                <img
                  style={{
                    width: "130px",
                  }}
                  src={DeleteBank}
                />
              </div>
              <div
                style={{
                  fontSize: "1em",
                  fontWeight: "500",
                }}
              >
                Do you really want delete bank account ?
              </div>
            </div>
            <div className="mt-4 buttonsDe">
              <button variant="secondary" onClick={handleClose}>
                Cancel
              </button>
              <button variant="primary" onClick={() => renderDeleteBank()}>
                Yes
              </button>
            </div>
          </center>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Profilebank;
