import React, { useEffect, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FintooBackButton from "../../../components/HTML/FintooBackButton";
import OTPInput from "otp-input-react";
import commonEncode from "../../../commonEncode";
import { BASE_API_URL, IS_DIRECT } from "../../../constants";
import { useDispatch } from "react-redux";
import * as constClass from "../../../constants";

import {
  fetchEncryptData,
  getUserId,
} from "../../../common_utilities";

import FintooDatePicker from "../../../components/HTML/FintooDatePicker";

import moment from "moment";

function AddNominee(props) {
  const userid = getUserId();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show123, setShow123] = useState(false);
  const handleClose123 = () => setShow123(false);
  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(120);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [nomineerelationship, setnomineerelationship] = useState("");
  const [dateofbirth, setdateofbirth] = useState("");
  const [gardianname, setgardianname] = useState("");
  const [OTP, setOTP] = useState("");
  const [user_mobile, setusermobile] = useState("");
  const [useremail, setuseremail] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const randomOTP = useRef(Math.floor(Math.random() * 90000) + 10000);
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    onLoadInIt();
  }, [user_mobile, useremail]);
  const onLoadInIt = async () => {
    try {
      var form_data_user = { user_id: userid };
      var config = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: form_data_user,
      };
      var res = await fetchEncryptData(config);
      var response = res.data;
      setusermobile(response.mobile);
      setuseremail(response.email);
    } catch (e) {
      console.warn("e --> ", e);
      setError({});
    }
  };

  var total = parseInt(window.localStorage.getItem("total"));
  if (isNaN(total)) {
    total = 0;
  }

  const startTimer = () => {
    setOTP("");
    clearInterval(timer.current.obj);
    timer.current.counter = timer.current.default;
    setCount(timer.current.counter);
    timer.current.obj = setInterval(() => {
      if (timer.current.counter == 0) {
        clearInterval(timer.current.obj);
        timer.current.counter = timer.current.default;
        return;
      }
      timer.current.counter = timer.current.counter - 1;
      setCount(timer.current.counter);
    }, 1000);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      if(name.length<2){
        console.log("hhh")
        setValidated(true);
      }
    }
  };
  
  const validateForm = () => {
    const tempEerror = {};
    if (name.trim() == "") tempEerror.name = "Name is required";
    if(name.length <2 && name != "") tempEerror.name = "Please Provide Valid nominee first name ";
    if (Boolean(dateofbirth) == false) tempEerror.dateofbirth = "Date of birth is required";
    if (gardianname.trim() == "" && moment().diff(moment(dateofbirth), "years") < 18 )
      tempEerror.guardianName = "Parent/Guardian name is required. ";
    if(gardianname.length <2 && gardianname !="") tempEerror.guardianName ="Parent/Guardian name is required"
    if (nomineerelationship.trim() == "") tempEerror.nomineerelationship = "Relation is required";
    if(gardianname.length<2 && gardianname != "") tempEerror.guardianName = "Please Provide Valid nominee guardian name!";

    setError({ ...tempEerror });
    return !Boolean(Object.keys(tempEerror).length);
  };

  const addnomineedetails = async () => {
    try {
      var addnominee_req = {
        nominee_first_name: name,
        nominee_dob: moment(dateofbirth).format("YYYY-MM-DD"),
        nominee_applicable: "100",
        nominee_guardian_name: gardianname,
        nominee_relation: nomineerelationship,
        user_id: userid,
        is_direct: IS_DIRECT,
        is_authenticated: "1",
      };
     
      if (randomOTP.current != OTP && randomOTP.current != OTP) {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "INVALID OTP", type: "error" },
        });
        return;
      }
      var form_data = addnominee_req;
      if (form_data.nominee_guardian_name.trim().length == 0) {
        form_data.nominee_guardian_name = undefined;
      }

      var config = {
        method: "post",
        url: constClass.DMF_ADDNOMINEE_API_URL,
        data: form_data,
      };
      var response = await fetchEncryptData(config);
      let error_code = response.error_code;

      if (error_code == "100") {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Nominee Added Successfully!", type: "success" },
        });

        setShow123(false);
        clientRegistration();
      }
    } catch (e) {
      console.warn(e)
    }
  };
 
  const clientRegistration = async () => {
    let data_sent = {
      user_id: userid,
      is_direct: constClass.IS_DIRECT,
    };

    var config = {
      method: "post",
      url: constClass.DMF_CLIENT_REGISTRATION_API_URL,
      data: data_sent,
    };
    var res = await fetchEncryptData(config);
    var response = commonEncode.decrypt(res.data);
    props.refreshPage();
  };

  const fetchSms = async () => {
    var urlsms = {
      mobile: user_mobile,
      otp: randomOTP.current,
      key:"add_nominee",
      is_direct :IS_DIRECT
    };
    var config = {
      method: "post",
      url: BASE_API_URL + "restapi/sendsmsApi/",
      data: urlsms,
    };

    var res = await fetchEncryptData(config);
  };

  const fetchMail = async () => {
    var urlmail = {
      userdata: {
        to: useremail,
      },
      subject: "Fintoo - Verification for your new account",
      template: "otp_message_template.html",
      contextvar: { otp: randomOTP.current },
    };

    var config = {
      method: "post",
      url: BASE_API_URL + "restapi/sendmail/",
      data: urlmail,
    };

    var res = await fetchEncryptData(config);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <>
        <Row>
          <Col xs={12} lg={8}>
            <Row className="Bank-details">
              <Col xs={12} lg={12} className="b-layout">
                <div className="bank-name nomiee">
                  <div className="sm:w-100">
                    <div className="memeber-logo">
                      <img
                       src={process.env.REACT_APP_STATIC_URL + "media/DMF/uni-user-ic.png"}
                      />
                    </div>
                  </div>
                  <div className="bank-data DesktopNomineeName">
                    <div className="bank-label">
                      <p>Nominee Name</p>
                    </div>
                    <div className="bank-info Nominee-name">
                      <div>
                        <Form.Control
                          aria-label="Default select example"
                          className="shadow-none w-50"
                          placeholder="Name"
                          maxlength="40"
                          value={name}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              /^[a-z A-Z]+$/.test(e.target.value)
                            ) {
                              setName(e.target.value);
                            }
                          }}
                          required
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {/* Nominee Name Required */}
                          {/* {name.length<2?"Nominee Name Required":"Nominee Name Required"} */}
                          {error.name}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="lg:mt-2"
                  >
                    <div className="Edit-nominee">
                      <button
                        type="submit"
                        onClick={() => {
                          if(validateForm() == false) {
                            return}
                          else{
                            randomOTP.current =
                            Math.floor(Math.random() * 90000) + 10000;}
                            setShow123(true);
                            startTimer();
                            fetchMail();
                            fetchSms();
                        }}
                      >
                        Save
                      </button>
                    </div>
                    {/* <div className="delete-nominee">
                      <img onClick={()=> props.refreshPage()} src={Delete} />
                    </div> */}
                  </div>
                </div>
              </Col>

              <div className="b-subdetails col-12">
                <Row>
                  <Col xs={12} lg={12} className="b-layout mobileNomineeName ">
                    <div className="bank-data">
                      <div className="bank-label">
                        <p>Nominee Name</p>
                      </div>
                      <div className="bank-info Nominee-name">
                        <div>
                          <Form.Control
                            aria-label="Default select example"
                            className="shadow-none w-100"
                            placeholder="Name"
                          ></Form.Control>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={3} className="b-layout">
                    <div className="bank-name">
                      <div>
                        <div className="bank-log"></div>
                      </div>
                      <div className="bank-data">
                        <div className="bank-label nomiee-label">
                          <p>Percentage Allocation</p>
                        </div>
                        <div className="bank-info">
                          <Form.Control
                            aria-label="Default select example"
                            className="shadow-none "
                            value="100 %"
                            required
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {/* Nominee Name Required */}
                            {error.name }
                          </Form.Control.Feedback>
                          <span
                            style={{
                              color: "gray",
                              position: "relative",
                              float: "right",
                              top: "-17px",
                              right: "10px",
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={3} className="b-layout ">
                    <div className="bank-label nomiee-label bank-data">
                      <p>Nominee Relationship</p>
                    </div>
                    <div className="bank-info">
                      <Form.Select
                        aria-label="Default select example"
                        className="shadow-none "
                        value={nomineerelationship}
                        onChange={(e) => setnomineerelationship(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Mother">Mother</option>
                        <option value="Father">Father</option>
                        <option value="Son">Son</option>
                        <option value="Husband">Husband</option>
                        <option value="Wife">Wife</option>
                        <option value="Daughter">Daughter</option>
                        <option value="Siblings">Sibling</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {/* Date of Birth */}
                        {error.nomineerelationship}
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  {}
                  <Col
                    DatePicker
                    maxDate={moment().toDate()}
                    xs={12}
                    lg={3}
                    className="b-layout "
                  >
                    <div className="bank-label nomiee-label bank-data">
                      <p>Date of Birth </p>
                    </div>
                    <div className="bank-info">
                      <FintooDatePicker
                        onChange={(v) => {
                          setdateofbirth(v);
                          // setdateofbirth(moment(v).format('YYYY-MM-DD'))
                          console.log(v, "ddd>>>>>");
                        }}
                        maxDate={moment().toDate()}
                        // value ={dateofbirth}
                        showMonthDropdown
                        showYearDropdown
                        selected={
                          dateofbirth ? moment(dateofbirth).toDate() : null
                        }
                        // required
                      />
                      {"dateofbirth" in error && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {error.dateofbirth}
                              </Form.Control.Feedback>
                            )}
                    
                      
                    </div>
                  </Col>

                  {moment().diff(moment(dateofbirth), "years") < 18 && (
                    <Col xs={12} lg={3} className={`b-layout `}>
                      <div className="bank-label nomiee-label bank-data">
                        <p>Parent/Guardian full name </p>
                      </div>
                      <div className="bank-info">
                        <Form.Control
                          aria-label="Default select example"
                          className="shadow-none "
                          placeholder="Name"
                          maxlength="40"
                          value={gardianname}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              /^[a-z A-Z]+$/.test(e.target.value)
                            ) {
                              setgardianname(e.target.value);
                              //
                            }
                          }}
                          required
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                                Parent/Guardian name is required.
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Row>
          </Col>
          
        </Row>
        <div className={`mt-3, mx-2 ${
                        moment().diff(moment(dateofbirth), "years") < 18 
                          ? ""
                          : "d-none"
                      }`} style={{fontFamily: "Red Hat Text"}}>
          <p className="mt-4">Since your nominee is a minor, please enter his/her guardian details. The guardian should not be the same as the Fintoo account holder -
          that’s you!</p><strong>Note:</strong> Account holder(s) cannot be a Guardian.</div>
        {/* {<p>hii</p>} */}
        
        <Modal
          className="NomineeModal white-modal"
          centered
          show={show}
          onHide={handleClose}
        >
          {/* <Modal.Header className="py-3">
         <div> <FintooBackButton onClick={() => props.onBack()} /></div>
          <div>
            <div className="modal-title">
              Do you really want delete Nominee ?
            </div>
            <div className="modal-title">Enter OTP</div>
          </div>
        </Modal.Header>
        > */}
          <div className="d-flex justify-center p-4">
            <div>
              {" "}
              <FintooBackButton onClick={handleClose} />
            </div>
            <div
              className="DeleteBank text-center pb-3 w-100"
              style={{
                borderBottom: "1px solid #eeee",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                }}
              >
                {" "}
                Do you really want delete Nominee ?
              </div>
              <div
                className="pt-2"
                style={{
                  fontWeight: "600",
                }}
              >
                Enter OTP
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="p-4">
                <div className="modal-whitepopup-box-item  border-top-0 text-center">
                  <p>Sent to</p>
                  <p>XXXXX XX9323</p>
                </div>
                <div className="d-flex justify-center align-items-center">
                  <center>
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      style={{
                        width: "8%",
                      }}
                      autoFocus
                      className="rounded rounded-otp"
                      OTPLength={5}
                      otpType="number"
                      disabled={false}
                      // secure
                    />
                  </center>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #eeee",
                  }}
                  className="text-center p-4 grey-color"
                >
                  {count == 0 && (
                    <p
                      className="pointer blue-color"
                      onClick={() => startTimer()}
                    >
                      Resend OTP
                    </p>
                  )}
                  {count > 0 && (
                    <p>
                      Expire in &nbsp;
                      <strong>
                        {moment().startOf("day").seconds(count).format("mm:ss")}
                      </strong>
                    </p>
                  )}
                </div>
              </div>
              <div
                className="mt-5  OTpConfirm"
                onClick={() => {
                  props.onRemove();
                }}
              >
                Continue
              </div>
            </div>
          </div>
        </Modal>
        {/* Save Nominee */}
        <Modal
          className="NomineeModal white-modal"
          centered
          show={show123}
          onHide={handleClose123}
        >
          {/* <Modal.Header className="py-3">
         <div> <FintooBackButton onClick={() => props.onBack()} /></div>
          <div>
            <div className="modal-title">
              Do you really want delete Nominee ?
            </div>
            <div className="modal-title">Enter OTP</div>
          </div>
        </Modal.Header>
        > */}
          <div className="d-flex justify-center p-4">
            <div>
              {" "}
              <FintooBackButton onClick={handleClose123} />
            </div>
            <div
              className="DeleteBank text-center pb-3 w-100"
              style={{
                borderBottom: "1px solid #eeee",
              }}
            >
              <div
                style={{
                  fontWeight: "500",
                }}
              >
                {" "}
                Confirm Details
              </div>
              <div
                className="pt-2"
                style={{
                  fontWeight: "600",
                }}
              >
                Enter OTP
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="p-3">
                <div className="modal-whitepopup-box-item  border-top-0 text-center">
                  <p>Sent to</p>
                  <p>XXXXXXX{user_mobile.slice(7)}</p>
                </div>
                <div className="d-flex justify-center align-items-center">
                  <center>
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      style={{
                        width: "8%",
                      }}
                      autoFocus
                      className="rounded rounded-otp"
                      OTPLength={5}
                      otpType="number"
                      disabled={false}
                      // secure
                    />
                  </center>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #eeee",
                  }}
                  className="text-center p-3 grey-color"
                >
                  {count == 0 && (
                    <p
                      className="pointer blue-color"
                      onClick={() => {
                        randomOTP.current =
                          Math.floor(Math.random() * 90000) + 10000;
                        startTimer();
                        fetchMail();
                        fetchSms();
                      }}
                    >
                      Resend OTP
                    </p>
                  )}
                  {count > 0 && (
                    <p>
                      Expire in &nbsp;
                      <strong>
                        {moment().startOf("day").seconds(count).format("mm:ss")}
                      </strong>
                    </p>
                  )}
                </div>
              </div>
              <div
                className="mt-3  OTpConfirm"
                onClick={() => {
                  addnomineedetails();
                }}
              >
                Continue
              </div>
            </div>
          </div>
        </Modal>
      </>
    </Form>
  );
}

export default AddNominee;
