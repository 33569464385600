import { FloatingLabel, Form, Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import FintooRadio2 from "../../../components/FintooRadio2";
import ReactDatePicker from "../../../components/HTML/ReactDatePicker/ReactDatePicker";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import {
    ADVISORY_CHECK_PROFILE_STATUS_API_URL, imagePath,
    ADVISORY_GET_KNOW_YOUR_STATUS_API_URL,
    ADVISORY_SAVE_UPDATE_FPUSER_DATA_API_URL, ADVISORY_UPDATE_RETIREMENT_DATE_API_URL, BASE_API_URL, CHECK_SESSION, ADVISORY_CHECK_PAN_EXISTS, ADVISORY_GET_FP_USER_DATA, ADVISORY_GET_FP_LOG
} from "../../../constants";
import { apiCall, getItemLocal, getParentFpLogId, getParentUserId, getUserId, loginRedirectGuest, setBackgroundDivImage } from "../../../common_utilities";
import axios from "axios";
import moment from "moment";
import commonEncode from "../../../commonEncode";
import * as toastr from 'toastr'
import 'toastr/build/toastr.css';
import FintooLoader from "../../../components/FintooLoader";
import customStyles from "../../../components/CustomStyles";
import { ScrollToTop } from '../ScrollToTop';
function YourInfo(props) {
    const [animateaddress, setAnimateAddress] = useState(false)
    const defaultInfoData = {
        salary_range: "2",
        gender: "male",
        relation_id: 1,
        user_id: 0,
        fp_log_id: 0,
        fp_user_id: 0,
        first_name: "",
        last_name: "",
        pin_code: "",
        pan: "",
        retirement_age: "",
        life_expectancy: "",
        occupation: 0,
        dob: null,
        alternate_address: "",
        remark: "",
        alternate_landline: "",
        city: "",
        country: "",
        country_code: "",
        height: "",
        id: "",
        is_epf_linked: "0",
        isdependent: "0",
        life_cycle_status: 0,
        state: null,
        tax_slab: "",
        uan: "",
        updated_datetime: "",
        user_isactive: "1",
        weight: "",
        fp_userid: 0,
        email: "",
        mobile: "",
        chat_email: "",
        chat_mobile: "",
        chat_user_id: "",
        justLoginFlag: "",
        user_name: "",

        user_details: {
            id: "",
            user_id: 0,
            fp_log_id: 0,
            first_name: "",
            last_name: "",
            gender: "male",
            relation_id: 1,
            parent_user_id: 0,
            dob: null,
            alternate_mobile: "",
            alternate_landline: "",
            alternate_address: "",
            City: "",
            PAN: "",
            State: "",
            Country: "",
            pin_code: "",
            salary_range: "2",
            tax_slab: "",
            life_expectancy: 0,
            retirement_age: 0,
            isdependent: "0",
            occupation: "",
            remark: "",
            user_isactive: "1",
            Created_By: "",
            Updated_By: "",
            height: "",
            weight: "",
            uan: "",
            is_epf_linked: "",
            name: "",
            email: "",
            mobile: "",
            country_code: "",
            plandate: "",
        },

        user_avatar: "",
        rm_id: "",
        fp_plan_type: "",
        fp_plan_service: "",
        lead_id: "",
        plan_id: "",
        plan_date: "",
        fp_lifecycle_status: 0,
        plam_payment_status: "",
        fp_plan_sub_cat: "",
        alternate_mobile: ""
    }

    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [occupationList, setOccupationList] = useState([]);
    const [session, setSession] = useState("");
    const [infoData, setInfoData] = useState(defaultInfoData);
    const [age, setAge] = useState(0);
    const [isNotEditable, setisNotEditable] = useState(false);
    const [isSavedFieldsEditable, setSavedFieldsEditable] = useState({ "lastname": false, "pincode": false, "pan": false });
    const [fieldError, setFieldError] = useState({ "panError": "", "retAgeError": "", "lifeExpError": "", "lastNameError": "" });
    const fpLogId = getParentFpLogId();

    // const customStyles = {
    //     option: (base, { data, isDisabled, isFocused, isSelected }) => {
    //         return {
    //             ...base,
    //             backgroundColor: isFocused ? "#ffff" : "#005263",
    //             color: isFocused ? "#000" : "#fff",
    //             cursor: "pointer",
    //         };
    //     },
    //     menuList: (base) => ({
    //         ...base,
    //         height: "100px",
    //         overflowY: "scroll",
    //         scrollBehavior: "smooth",
    //         "::-webkit-scrollbar": {
    //             width: "4px",
    //             height: "0px",
    //         },
    //         "::-webkit-scrollbar-track": {
    //             background: "#fff",
    //         },
    //         "::-webkit-scrollbar-thumb": {
    //             background: "#005263",
    //         },
    //         "::-webkit-scrollbar-thumb:hover": {
    //             background: "#555",
    //         },
    //     }),
    // };

    useEffect(() => {

        checksession();
        setBackgroundDivImage();
        try {
            axios.get(BASE_API_URL + 'restapi/getoccuptionlist/?web=1')
                .then(response => {
                    const occ_list = JSON.parse(commonEncode.decrypt(response.data));
                    var occ_other_index = '';
                    var occ_new = [];
                    for (var i = 0; i < occ_list.length; i++) {
                        if (occ_list[i]['occupation_name'] != 'Unknown') {
                            if (occ_list[i]['occupation_name'] == 'Others') {
                                occ_other_index = {
                                    'value': occ_list[i]['occupation_id'],
                                    'label': occ_list[i]['occupation_name']
                                };
                            } else {
                                var occ_obj = {
                                    'value': occ_list[i]['occupation_id'],
                                    'label': occ_list[i]['occupation_name']
                                }
                                occ_new.push(occ_obj);
                            }
                        }

                    }
                    occ_new.push(occ_other_index);
                    setOccupationList(occ_new)

                })
        }
        catch (e) {
            console.log('Error fetching list', e);
        }
    }, []);

    const formatDate = (dateString) => {
        if (dateString) {
            if (dateString.indexOf('/') == -1) {
                var dateObject = new Date(dateString);
            }
            else {
                const dateStringSplit = dateString.split('/')
                const dateStringFormatted = dateStringSplit[2] + '-' + dateStringSplit[1] + '-' + dateStringSplit[0]
                var dateObject = new Date(dateStringFormatted);
            }
            const day = String(dateObject.getDate()).padStart(2, '0');
            const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = dateObject.getFullYear();
            const formattedDate = `${day}/${month}/${year}`;

            const dob = moment(dateObject);
            const ageInYears = moment().diff(dob, 'years');
            setAge(ageInYears);

            return formattedDate;
        }

    }
    const getFpLog = async (session) => {
        if (session.data.fp_log_id) {
            getFPUserData(session)
        }
        else {
            let url = ADVISORY_GET_FP_LOG;
            let data = { user_id: session.data.id };
            let fp_log_resp = await apiCall(url, data, false, false);
            if (fp_log_resp['error_code'] == "100") {
                if (fp_log_resp.data.fp_log_id != '') {
                    session.data.fp_log_id = fp_log_resp.data.fp_log_id
                    getFPUserData(session)
                }
                else {
                    window.location.href = process.env.PUBLIC_URL + '/pricing'
                }

            }
            else {
                window.location.href = process.env.PUBLIC_URL + '/pricing'
            }

        }
    }
    const getFPUserData = async (session) => {
        let url = ADVISORY_GET_FP_USER_DATA + '?user_id=' + btoa(commonEncode.encrypt((session.data.id).toString())) + '&fp_log_id=' + btoa(commonEncode.encrypt((fpLogId).toString())) + '&fp_user_id=' + btoa(commonEncode.encrypt((session.data.fp_user_id).toString())) + '&is_direct=1';

        let fpUserData = await apiCall(url, "", true, false);
        console.log("fpUserData", fpUserData);
        if (fpUserData) {

            fpUserData.data.forEach(userData => {
                const isNotEditable = userData.life_cycle_status === 2;
                setisNotEditable(isNotEditable)

                if (userData.last_name.trim() != '') {
                    setSavedFieldsEditable(field => ({
                        ...field,
                        lastname: true
                    }))
                }
                if (userData.pan != '' && userData.pan) {
                    setSavedFieldsEditable(field => ({
                        ...field,
                        pan: true
                    }))
                }
                if (userData.pin_code != '' && userData.pin_code) {
                    setSavedFieldsEditable(field => ({
                        ...field,
                        pincode: true
                    }))
                }

                setInfoData(data => ({
                    ...data,
                    gender: userData.gender,
                    relation_id: userData.relation_id,
                    user_id: userData.user_id,
                    fp_log_id: userData.fp_log_id,
                    fp_user_id: userData.id,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    pin_code: userData.pin_code,
                    pan: userData.pan,
                    retirement_age: userData.retirement_age,
                    life_expectancy: userData.life_expectancy,
                    occupation: userData.occupation,
                    dob: formatDate(userData.dob),
                    alternate_address: userData.alternate_address,
                    remark: userData.remark,
                    alternate_landline: userData.alternate_landline,
                    city: userData.city,
                    country: userData.country,
                    country_code: userData.country_code,
                    height: userData.height,
                    id: userData.id,
                    is_epf_linked: userData.is_epf_linked,
                    isdependent: userData.isdependent,
                    life_cycle_status: session.data.fp_lifecycle_status,
                    state: userData.State,
                    tax_slab: userData.tax_slab,
                    uan: userData.uan,
                    updated_datetime: userData.updated_datetime,
                    user_isactive: userData.user_isactive,
                    weight: userData.weight,
                    fp_userid: userData.id,
                    email: session.data.email,
                    mobile: session.data.mobile,
                    chat_email: session.data.email,
                    chat_mobile: session.data.mobile,
                    chat_user_id: userData.user_id,
                    justLoginFlag: session.data.justLoginFlag,
                    user_name: session.data.user_name,
                    alternate_mobile: userData.alternate_mobile,
                    user_details: {
                        ...data.user_details,
                        id: userData.id,
                        user_id: userData.user_id,
                        fp_log_id: userData.fp_log_id,
                        first_name: userData.first_name,
                        last_name: userData.last_name,
                        gender: userData.gender,
                        relation_id: userData.relation_id,
                        parent_user_id: userData.parent_user_id,
                        dob: userData.dob,
                        alternate_mobile: userData.alternate_mobile,
                        alternate_landline: userData.alternate_landline,
                        alternate_address: userData.alternate_address,
                        City: userData.city,
                        PAN: userData.pan,
                        State: userData.state,
                        Country: userData.country,
                        pin_code: userData.pin_code,
                        // salary_range: userData.salary_range,
                        tax_slab: userData.tax_slab,
                        life_expectancy: userData.life_expectancy,
                        retirement_age: userData.retirement_age,
                        isdependent: userData.isdependent,
                        occupation: userData.occupation,
                        remark: userData.remark,
                        user_isactive: userData.user_isactive,
                        Created_By: userData.created_by,
                        Updated_By: userData.updated_by,
                        height: userData.height,
                        weight: userData.weight,
                        uan: userData.uan,
                        is_epf_linked: userData.is_epf_linked,
                        name: userData.name,
                        email: session.data.email,
                        mobile: session.data.mobile,
                        country_code: userData.country_code,

                    }


                }))
            });

        }


    }
    const checksession = async () => {
        let url = CHECK_SESSION;
        let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
        let session_data = await apiCall(url, data, true, false);

        setSession(session_data)

        if (session_data['error_code'] == "100") {
            let infoDetails = {}
            // if(session_data.data.fp_log_id!=''){
            if (session_data.data.user_details.last_name != '' && session_data.data.user_details.last_name) {
                setSavedFieldsEditable(field => ({
                    ...field,
                    lastname: true
                }))
            }
            if (session_data.data.user_details.PAN != '' && session_data.data.user_details.PAN) {
                setSavedFieldsEditable(field => ({
                    ...field,
                    pan: true
                }))
            }
            if (session_data.data.user_details.pin_code != '' && session_data.data.user_details.pin_code) {
                setSavedFieldsEditable(field => ({
                    ...field,
                    pincode: true
                }))
            }
            getFpLog(session_data)
            // }
            // else{
            //     window.location.href=process.env.PUBLIC_URL+'/pricing'
            // }
            // loginRedirectGuest()

            // infoDetails = {
            //     salary_range:  session_data.data.user_details.salary_range,
            //     gender: session_data.data.user_details.gender,
            //     relation_id: session_data.data.user_details.relation_id,
            //     user_id: session_data.data.user_details.user_id,
            //     fp_log_id: session_data.data.fp_log_id,
            //     fp_user_id: session_data.data.fp_user_id,
            //     first_name: session_data.data.user_details.first_name,
            //     last_name: session_data.data.user_details.last_name,
            //     pin_code: session_data.data.user_details.pin_code,
            //     pan: session_data.data.user_details.PAN,
            //     retirement_age: session_data.data.user_details.retirement_age,
            //     life_expectancy: session_data.data.user_details.life_expectancy,
            //     occupation: session_data.data.user_details.occupation,
            //     dob: formatDate(session_data.data.user_details.dob),
            //     alternate_address: session_data.data.user_details.alternate_address,
            //     remark: session_data.data.user_details.remark,
            //     alternate_landline: session_data.data.user_details.alternate_landline,
            //     city: session_data.data.user_details.City,
            //     country: session_data.data.user_details.Country,
            //     country_code: session_data.data.user_details.country_code,
            //     height: session_data.data.user_details.height,
            //     id: session_data.data.user_details.id,
            //     is_epf_linked: session_data.data.user_details.is_epf_linked,
            //     isdependent: session_data.data.user_details.isdependent,
            //     life_cycle_status: session_data.data.fp_lifecycle_status,
            //     state: session_data.data.user_details.State,
            //     tax_slab: session_data.data.user_details.tax_slab,
            //     uan: session_data.data.user_details.uan,
            //     updated_datetime: session_data.data.user_details.Updated_Datetime,
            //     user_isactive: session_data.data.user_details.user_isActive,
            //     weight: session_data.data.user_details.weight,
            //     fp_userid: session_data.data.fp_user_id,
            //     email: session_data.data.email,
            //     mobile: session_data.data.mobile,
            //     chat_email: session_data.data.chat_email,
            //     chat_mobile: session_data.data.chat_mobile,
            //     chat_user_id: session_data.data.chat_user_id,
            //     justLoginFlag: session_data.data.justLoginFlag,
            //     user_name: session_data.data.user_name,

            //     user_details: {
            //         id: session_data.data.user_details.id,
            //         user_id: session_data.data.user_details.user_id,
            //         fp_log_id: session_data.data.fp_log_id,
            //         first_name: session_data.data.user_details.first_name,
            //         last_name: session_data.data.user_details.last_name,
            //         // gender: session_data.data.user_details.gender,
            //         relation_id: session_data.data.user_details.relation_id,
            //         parent_user_id: session_data.data.user_details.parent_user_id,
            //         dob: formatDate(session_data.data.user_details.dob),
            //         alternate_mobile: session_data.data.user_details.alternate_mobile,
            //         alternate_landline: session_data.data.user_details.alternate_landline,
            //         alternate_address: session_data.data.user_details.alternate_address,
            //         City: session_data.data.user_details.City,
            //         PAN: session_data.data.user_details.PAN,
            //         State: session_data.data.user_details.State,
            //         Country: session_data.data.user_details.Country,
            //         pin_code: session_data.data.user_details.pin_code,
            //         salary_range: session_data.data.user_details.salary_range,
            //         tax_slab: session_data.data.user_details.tax_slab,
            //         life_expectancy: session_data.data.user_details.life_expectancy,
            //         retirement_age: session_data.data.user_details.retirement_age,
            //         isdependent: session_data.data.user_details.isdependent,
            //         occupation: session_data.data.user_details.occupation,
            //         remark: session_data.data.user_details.remark,
            //         user_isactive: session_data.data.user_details.user_isActive,
            //         Created_By: session_data.data.user_details.Created_By,
            //         Updated_By: session_data.data.user_details.Updated_By,
            //         height: session_data.data.user_details.height,
            //         weight: session_data.data.user_details.weight,
            //         uan: session_data.data.user_details.uan,
            //         is_epf_linked: session_data.data.user_details.is_epf_linked,
            //         name: session_data.data.user_details.name,
            //         email: session_data.data.user_details.email,
            //         mobile: session_data.data.user_details.mobile,
            //         country_code: session_data.data.user_details.country_code,
            //         plandate: session_data.data.user_details.plandate,
            //     },

            //     user_avatar: session_data.data.user_avatar,
            //     rm_id: session_data.rm_id,
            //     fp_plan_type: session_data.data.fp_plan_type,
            //     fp_plan_service: session_data.data.fp_plan_service,
            //     lead_id: session_data.data.lead_id,
            //     plan_id: session_data.data.plan_id,
            //     plan_date: session_data.data.plan_date,
            //     fp_lifecycle_status: session_data.data.fp_lifecycle_status,
            //     plan_payment_status: session_data.data.plan_payment_status,
            //     fp_plan_sub_cat: session_data.data.fp_plan_sub_cat,
            //     alternate_mobile: session_data.data.alternate_mobile,

            //     // // Keep Retirement Age and Life Expectancy editable, but update them with session_data values if they are different.
            //     // retirement_age: infoData.retirement_age !== defaultInfoData.retirement_age ? session_data.user_details.retirement_age : defaultInfoData.retirement_age,
            //     // life_expectancy: infoData.life_expectancy !== defaultInfoData.life_expectancy ? session_data.user_details.life_expectancy : defaultInfoData.life_expectancy,
            // };
            // setInfoData(infoDetails)
        }

        // if (isNotEditable) {
        // Fill the infoData object with data from the session_data

        // getFPUserData(session_data)


        // }
        // else{

        // setInfoData(data=>({
        //     ...data,
        //         salary_range: session_data.data.user_details.salary_range,
        //         gender: session_data.data.user_details.gender,
        //         relation_id: session_data.data.user_details.relation_id,
        //         user_id: session_data.data.user_details.user_id,
        //         fp_log_id: session_data.data.fp_log_id,
        //         fp_user_id: session_data.data.fp_user_id,
        //         first_name: session_data.data.user_details.first_name,
        //         last_name: session_data.data.user_details.last_name,
        //         pin_code: session_data.data.user_details.pin_code,
        //         pan: session_data.data.user_details.PAN,
        //         retirement_age: session_data.data.user_details.retirement_age,
        //         life_expectancy: session_data.data.user_details.life_expectancy,
        //         occupation: session_data.data.user_details.occupation,
        //         dob: formatDate(session_data.data.user_details.dob),
        //         alternate_address: session_data.data.user_details.alternate_address,
        //         remark: session_data.data.user_details.remark,
        //         alternate_landline: session_data.data.user_details.alternate_landline,
        //         city: session_data.data.user_details.City,
        //         country: session_data.data.user_details.Country,
        //         country_code: session_data.data.user_details.country_code,
        //         height: session_data.data.user_details.height,
        //         id: session_data.data.user_details.id,
        //         is_epf_linked: session_data.data.user_details.is_epf_linked,
        //         isdependent: session_data.data.user_details.isdependent,
        //         life_cycle_status: session_data.data.fp_lifecycle_status,
        //         state: session_data.data.user_details.State,
        //         tax_slab: session_data.data.user_details.tax_slab,
        //         uan: session_data.data.user_details.uan,
        //         updated_datetime: session_data.data.user_details.Updated_Datetime,
        //         user_isactive: session_data.data.user_details.user_isActive,
        //         weight: session_data.data.user_details.weight,
        //         fp_userid: session_data.data.fp_user_id,
        //         email: session_data.data.email,
        //         mobile: session_data.data.mobile,
        //         chat_email: session_data.data.chat_email,
        //         chat_mobile: session_data.data.chat_mobile,
        //         chat_user_id: session_data.data.chat_user_id,
        //         justLoginFlag: session_data.data.justLoginFlag,
        //         user_name: session_data.data.user_name,
        // }))
        // getFPUserData(session_data)

        // }
    }

    const checkifpanexists = async () => {

        if (infoData.pan != '') {


            let url = ADVISORY_CHECK_PAN_EXISTS + '?uid=' + btoa("00" + session.data.id) + '&pan=' + infoData.pan + '&is_direct=1';

            let checkPan = await apiCall(url, "", false, false);
            if (checkPan != true) {
                setFieldError(data => ({
                    ...data,
                    panError: checkPan
                }))
            }
            else {
                setFieldError(data => ({
                    ...data,
                    panError: ""
                }))
            }
        }

    };
    function handlePanInput(e) {
        const upperCaseValue = e.target.value.toUpperCase();

        setInfoData({
            ...infoData,
            pan: upperCaseValue,
            user_details: {
                ...infoData.user_details,
                pan: e.target.value,
            },
        })
    };

    const setDate = (date) => {
        setInfoData({
            ...infoData,
            dob: moment(date).format("DD/MM/YYYY"),
            user_details: {
                ...infoData.user_details,
                dob: moment(date).format("DD/MM/YYYY"),
            },
        });

        const dob = moment(date);
        const ageInYears = moment().diff(dob, 'years');
        setAge(ageInYears);
        checkRetirementAgeAfterDOBChange(ageInYears);
        checkLifeExpectancyAgeAfterDOBChange(ageInYears);
    };

    const saveupdatefpuserdata = async (e) => {
        // e.preventDefault();
        try {
            let url = ADVISORY_SAVE_UPDATE_FPUSER_DATA_API_URL;
            let payload = infoData;
            payload['is_direct'] = 1;

            let addInfoData = await apiCall(url, payload, true, false);

            if (addInfoData["error_code"] == "100") {
                toastr.options.positionClass = "toast-bottom-left";
                toastr.success(addInfoData.message);
                setShow(true);
                props.setTab("tab2");
                setIsLoading(false)
                getknowyourstatus();
                checkprofilestatus();
                updateretirementdate();


            }
            else {
                if (addInfoData['error_code'] == 102) {
                    toastr.error((addInfoData.data['data']))
                }
                if (addInfoData['error_code'] != 100) {
                    toastr.error("Oops something went wrong!!");
                }
                setIsLoading(false)

            }
        }
        catch (e) {
            console.log('Error', e);
        }
    };

    const handleSubmit = (e) => {
        const shouldAnimate = infoData.alternate_address.length >= 50;
        setAnimateAddress(shouldAnimate);
        e.preventDefault();
        e.stopPropagation();
        if (infoData.occupation == 0) {
            setFieldError(data => ({
                ...data,
                occupationError: 'Please select occupation'
            }))
        }
        else {
            setFieldError(data => ({
                ...data,
                occupationError: ''
            }))
        }
        if (infoData.last_name.trim() == '') {
            setFieldError(data => ({
                ...data,
                lastNameError: 'Please enter last name'
            }))
        }
        else {
            setFieldError(data => ({
                ...data,
                lastNameError: ''
            }))
        }
        if (infoData.retirement_age === 0 || infoData.retirement_age == '' || !infoData.retirement_age) {
            setFieldError(data => ({
                ...data,
                retAgeError: 'Please enter retirement age'
            }))
        }
        else {
            if (fieldError.retAgeError == '') {
                setFieldError(data => ({
                    ...data,
                    retAgeError: ''
                }))
            }

        }
        if (infoData.life_expectancy == '0' || infoData.life_expectancy == '' || !infoData.life_expectancy) {
            setFieldError(data => ({
                ...data,
                lifeExpError: 'Please enter life expectancy age'
            }))
        }
        else {
            if (fieldError.lifeExpError == '') {
                setFieldError(data => ({
                    ...data,
                    lifeExpError: ''
                }))
            }

        }
        if (isNotEditable) {
            if (infoData.occupation != 0 && infoData.retirement_age != 0 && infoData.life_expectancy != '' && fieldError.lifeExpError == '' && fieldError.retAgeError == '' && fieldError.lastNameError == '' && infoData.last_name.trim() != '') {
                setIsLoading(true)
                saveupdatefpuserdata();

            }
            else {
                simpleValidator.current.showMessages()
            }
        }
        else {
            if (simpleValidator.current.allValid() && infoData.occupation != 0 && infoData.retirement_age != 0 && infoData.life_expectancy != '' && fieldError.lifeExpError == '' && fieldError.retAgeError == '' && fieldError.lastNameError == '' && infoData.last_name.trim() != '') {
                setIsLoading(true)

                saveupdatefpuserdata();

            }
            else {
                simpleValidator.current.showMessages()
            }
        }
        // saveupdatefpuserdata();
        // setShow(true);
        // props.setTab("tab2");
    };

    const updateretirementdate = async () => {

        let session_data = session
        try {
            var retire_data = {
                fp_log_id: session_data["data"]["user_details"]["fp_log_id"],
                fp_user_id: session_data["data"]["user_details"]["user_id"],
                is_direct: 1
            }

            var payload_retire_data = commonEncode.encrypt(JSON.stringify(retire_data));

            var config_ret = await apiCall(
                ADVISORY_UPDATE_RETIREMENT_DATE_API_URL,
                payload_retire_data,
                false,
                false
            );

            var res_ret = JSON.parse(commonEncode.decrypt(config_ret));
        }
        catch (e) {
            console.log('Error', e);
        }
    };

    const getknowyourstatus = async () => {

        let session_data = session
        try {
            var gtys_data = {
                fp_log_id: session_data["data"]["user_details"]["fp_log_id"],
                user_id: session_data["data"]["user_details"]["user_id"],
                web: 1,
                is_direct: 1
            }

            var payload_gtys_data = commonEncode.encrypt(JSON.stringify(gtys_data));

            var config_gtys = await apiCall(
                ADVISORY_GET_KNOW_YOUR_STATUS_API_URL,
                payload_gtys_data,
                false,
                false
            );

            var res_gtys = JSON.parse(commonEncode.decrypt(config_gtys));
        }
        catch (e) {
            console.log('Error', e);
        }
    };

    const checkprofilestatus = async () => {

        let session_data = session
        try {
            var pfs_data = {
                user_id: session_data["data"]["user_details"]["user_id"],
                fp_log_id: session_data["data"]["user_details"]["fp_log_id"],
                web: 1
            }

            var payload_pfs_data = commonEncode.encrypt(JSON.stringify(pfs_data));

            var config_pfs = await apiCall(
                ADVISORY_CHECK_PROFILE_STATUS_API_URL,
                payload_pfs_data,
                false,
                false
            );

            var res_pfs = JSON.parse(commonEncode.decrypt(config_pfs));
        }
        catch (e) {
            console.log('Error', e);
        }
    };

    const checkRetirementAge = (retAge) => {
        if (retAge && age) {
            if (parseInt(retAge) <= parseInt(age)) {
                setFieldError(data => ({
                    ...data,
                    retAgeError: 'Please enter value more than age'
                }))
            }
            else if (parseInt(retAge) > 80) {
                setFieldError(data => ({
                    ...data,
                    retAgeError: 'Please enter age less than or equal to 80'
                }))
            }
            else if (parseInt(retAge) > parseInt(infoData.life_expectancy)) {
                setFieldError(data => ({
                    ...data,
                    retAgeError: 'Please enter retirement age less than life expectancy age'
                }))
            }
            else {
                setFieldError(data => ({
                    ...data,
                    retAgeError: ''
                }))
            }
        }
        else if (parseInt(retAge) > 80) {
            setFieldError(data => ({
                ...data,
                retAgeError: 'Please enter age less than or equal to 80'
            }))
        }
        else if (parseInt(retAge) > parseInt(infoData.life_expectancy)) {
            setFieldError(data => ({
                ...data,
                retAgeError: 'Please enter retirement age less than life expectancy age'
            }))
        }
        else {
            setFieldError(data => ({
                ...data,
                retAgeError: ''
            }))
        }
    }

    const checkRetirementAgeAfterDOBChange = (age) => {
        let retAge = infoData.retirement_age ? infoData.retirement_age : 0;
        if (retAge && age) {
            if (parseInt(retAge) <= parseInt(age)) {
                setFieldError(data => ({
                    ...data,
                    retAgeError: 'Please enter value more than age'
                }))
            }
            else if (parseInt(retAge) > 80) {
                setFieldError(data => ({
                    ...data,
                    retAgeError: 'Please enter age less than or equal to 80'
                }))
            }
            else if (parseInt(retAge) > parseInt(infoData.life_expectancy)) {
                setFieldError(data => ({
                    ...data,
                    retAgeError: 'Please enter retirement age less than life expectancy age'
                }))
            }
            else {
                setFieldError(data => ({
                    ...data,
                    retAgeError: ''
                }))
            }
        }
        else if (parseInt(retAge) > 80) {
            setFieldError(data => ({
                ...data,
                retAgeError: 'Please enter age less than or equal to 80'
            }))
        }
        else if (parseInt(retAge) > parseInt(infoData.life_expectancy)) {
            setFieldError(data => ({
                ...data,
                retAgeError: 'Please enter retirement age less than life expectancy age'
            }))
        }
        else {
            setFieldError(data => ({
                ...data,
                retAgeError: ''
            }))
        }
    }
    const checkLifeExpectancyAge = (lifeExpAge) => {
        if (lifeExpAge && infoData.retirement_age) {
            if (parseInt(lifeExpAge) <= parseInt(infoData.retirement_age)) {
                setFieldError(data => ({
                    ...data,
                    lifeExpError: 'Please enter value more than retirement age'
                }))
            }
            else if (parseInt(lifeExpAge) > 100) {
                setFieldError(data => ({
                    ...data,
                    lifeExpError: 'Please enter age less than or equal to 100'
                }))
            }
            else {
                setFieldError(data => ({
                    ...data,
                    lifeExpError: ''
                }))

            }
        }
        else if (parseInt(lifeExpAge) > 100) {
            setFieldError(data => ({
                ...data,
                lifeExpError: 'Please enter age less than or equal to 100'
            }))
        }
        else {
            setFieldError(data => ({
                ...data,
                lifeExpError: ''
            }))
        }
    }

    const checkLifeExpectancyAgeAfterDOBChange = (age) => {
        let lifeExpAge = infoData.life_expectancy ? infoData.life_expectancy : 0;
        if (lifeExpAge && infoData.retirement_age) {
            if (parseInt(lifeExpAge) <= parseInt(infoData.retirement_age)) {
                setFieldError(data => ({
                    ...data,
                    lifeExpError: 'Please enter value more than retirement age'
                }))
            }
            else if (parseInt(lifeExpAge) > 100) {
                setFieldError(data => ({
                    ...data,
                    lifeExpError: 'Please enter age less than or equal to 100'
                }))
            }
            else {
                setFieldError(data => ({
                    ...data,
                    lifeExpError: ''
                }))

            }
        }
        else if (parseInt(lifeExpAge) > 100) {
            setFieldError(data => ({
                ...data,
                lifeExpError: 'Please enter age less than or equal to 100'
            }))
        }
        else {
            setFieldError(data => ({
                ...data,
                lifeExpError: ''
            }))
        }
    }
    const isValid =
        age >= 0 && // Ensure age is a non-negative value
        age <= 99 && // Ensure age is less than or equal to 99
        infoData.retirement_age >= 0 && // Ensure retirement_age is a non-negative value
        infoData.retirement_age <= 99 && // Ensure retirement_age is less than or equal to 99
        infoData.life_expectancy >= 0 && // Ensure life_expectancy is a non-negative value
        infoData.life_expectancy <= 100 && // Ensure life_expectancy is less than or equal to 100
        infoData.retirement_age >= age &&
        infoData.life_expectancy >= infoData.retirement_age &&
        infoData.life_expectancy >= age;


    const shouldAnimateAddress = () => {
        return isNotEditable;
    };
    const handleInputChange = (e) => {
        // Check if the field is editable before updating the state
        if (!isNotEditable) {
            setInfoData({
                ...infoData,
                alternate_address: e.target.value,
                user_details: {
                    ...infoData.user_details,
                    alternate_address: e.target.value,
                },
            });
            setAnimateAddress(false);
        }
    };

    useEffect(() => {
        // Start animation when isNotEditable is true
        setAnimateAddress(isNotEditable);
    }, [isNotEditable]);
    return (
        <div>
            <FintooLoader isLoading={isLoading} />

            <form noValidate="novalidate" name="goldassetform">
                <div className="col-md-12">

                    {infoData.life_cycle_status > 1 &&
                        <div
                            style={{ color: "#F0806D", paddingTop: 20, paddingRight: 20 }}
                            className=""
                        >
                            Note : 1) As you have already generated the report you can
                            only edit Retirement Age and Life Expectancy Age in "YOUR
                            INFO" section.
                        </div>
                    }

                    {infoData.life_cycle_status > 1 &&
                        <div
                            className="pt-2"
                            style={{ color: "#F0806D", marginLeft: 43 }}
                        >
                            2) To change your mobile number or email id, send a request to{" "}
                            <a
                                href="mailto:support@fintoo.in"
                                style={{ color: "#6151c9" }}
                            >
                                <u>support@fintoo.in</u>
                            </a>
                            .
                        </div>
                    }

                    {infoData.life_cycle_status < 2 &&
                        <div
                            className="pt-2"
                            style={{ color: "#F0806D", marginLeft: 43 }}
                        >
                            Note: To change your mobile number or email id, send a request to{" "}
                            <a
                                href="mailto:support@fintoo.in"
                                style={{ color: "#6151c9" }}
                            >
                                <u>support@fintoo.in</u>
                            </a>
                            .
                        </div>
                    }

                </div>
                <p
                    style={{
                        height: "1rem",
                    }}
                ></p>
                <div className="row">
                    <div className="col-md-4 col-md-4 col-12 custom-input">
                        <div className={`form-group mt-2 ${infoData.first_name ? "inputData" : null}`}>
                            <input type="text" id="first_name" name="first_name" value={infoData.first_name}
                                onChange={(e) => {
                                    setInfoData({
                                        ...infoData,
                                        first_name: e.target.value,
                                        user_details: {
                                            ...infoData.user_details,
                                            first_name: e.target.value,
                                        },
                                    });
                                }}
                                readOnly required autoComplete="off" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">First Name*</label>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group mt-2 ${infoData.last_name ? "inputData" : null}`}>
                            <input type="text" id="last_name" name="last_name" value={infoData.last_name}
                                onChange={(e) => {
                                    // Check if the field is editable before updating the state
                                    if (!isSavedFieldsEditable.lastname) {
                                        setFieldError(data => ({
                                            ...data,
                                            lastNameError: ""
                                        }))
                                        setInfoData({
                                            ...infoData,
                                            last_name: e.target.value,
                                            user_details: {
                                                ...infoData.user_details,
                                                last_name: e.target.value,
                                            },
                                        });
                                    }
                                }}
                                onBlur={() => {
                                    // Check if the field is editable before running the validation
                                    if (!isSavedFieldsEditable.lastname) {
                                        simpleValidator.current.showMessageFor('last_name');

                                    }
                                }}
                                readOnly={isSavedFieldsEditable.lastname} // Set readOnly attribute based on isNotEditable value
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">Last Name*</label>
                            {/* Display error message only if the field is not editable */}
                            {/* {!isSavedFieldsEditable.lastname && ( */}

                            <>{simpleValidator.current.message('last_name', infoData.last_name, 'required|alpha_space', { messages: { required: 'Please enter last name', alpha_space: 'Alphabets are allowed only.' } })}</>
                            {/* )} */}
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group w-100 mt-2  ${infoData.alternate_address ? "inputData" : null}`}>
                            <span>
                                <input
                                    id="alternate_address"
                                    className={(infoData?.alternate_address && infoData?.alternate_address.length >= 50 && shouldAnimateAddress()) ? 'animateaddress' : null}
                                    type="text"
                                    name="alternate_address"
                                    value={infoData.alternate_address}
                                    onChange={handleInputChange}
                                    readOnly={isNotEditable}
                                    autoComplete="off"
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="name">Residential Address</label>
                            </span>
                            <span className="info-hover-box">
                                <span className="icon">
                                    <img
                                        alt="More information"
                                        src="https://images.minty.co.in/static/assets/img/more_information.svg"
                                    />
                                </span>
                                <span className="msg">
                                    Ex: Flat no, Building Name, Road/street, Locality
                                </span>
                            </span>
                        </div>
                        <>{simpleValidator.current.message('alternate_address', infoData.alternate_address, 'min:10', { messages: { min: 'Please enter atleast 10 characters' } })}</>
                    </div>
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group w-100 mt-2  ${infoData.pin_code ? "inputData" : null}`}>
                            <span>
                                <input type="text" id="pincode" name="pincode" value={infoData.pin_code}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        // Check if the field is editable before updating the state
                                        if (!isNotEditable && /^\d*$/.test(value)) {
                                            setInfoData({
                                                ...infoData,
                                                pin_code: value.slice(0, 6),
                                                user_details: {
                                                    ...infoData.user_details,
                                                    pin_code: value.slice(0, 6),
                                                },
                                            });
                                        }
                                    }}
                                    onBlur={() => {
                                        // Check if the field is editable before running the validation
                                        if (!isNotEditable) {
                                            simpleValidator.current.showMessageFor('pincode');

                                        }
                                    }}
                                    readOnly={isSavedFieldsEditable.pincode}
                                    required autoComplete="off" />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="name">Pincode*</label>
                            </span>
                            <span className="info-hover-box">
                                <span className="icon">
                                    <img
                                        alt="More information"
                                        src="https://images.minty.co.in/static/assets/img/more_information.svg"
                                    />
                                </span>
                                <span className="msg">Ex: 400097</span>
                            </span>
                        </div>
                        <>{simpleValidator.current.message('pincode', infoData.pin_code, 'required|numeric|min:6|max:6', { messages: { required: 'Please enter valid pincode', min: 'Please enter valid pincode' } })}</>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group mt-2 ${infoData.email ? "inputData" : null}`}>
                            <input type="text" id="email" name="email" value={infoData.email}
                                onChange={(e) => {
                                    setInfoData({
                                        ...infoData,
                                        email: e.target.value,
                                        user_details: {
                                            ...infoData.user_details,
                                            email: e.target.value,
                                        },
                                    })
                                }}
                                readOnly required autoComplete="off" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">Email Address*</label>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group w-100 mt-2 ${infoData.pan && infoData.pan?.length <= 10 ? "inputData" : ""}`}>
                            <span>
                                <input
                                    type="text"
                                    id="pan"
                                    name="pan"
                                    value={infoData.pan || ""}
                                    onChange={(e) => {
                                        setFieldError(data => ({
                                            ...data,
                                            panError: ""
                                        }));
                                        handlePanInput(e);
                                    }}
                                    onBlur={() => {
                                        if (!isSavedFieldsEditable.pan) {
                                            simpleValidator.current.showMessageFor("PAN");
                                            checkifpanexists();
                                        }
                                    }}
                                    maxLength={10}
                                    readOnly={isSavedFieldsEditable.pan}
                                    autoComplete="off"
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="name">PAN</label>
                            </span>
                            <span className="info-hover-box">
                                <span className="icon">
                                    <img
                                        alt="More information"
                                        src="https://images.fintoo.in/static/assets/img/more_information.svg"
                                    />
                                </span>
                                <span className="msg">Ex: BPYPK1234C</span>
                            </span>
                        </div>
                        {infoData.pan?.length <= 10 && !isNotEditable && (
                            <>
                                {simpleValidator.current.message('PAN', infoData.pan, [{ regex: /^[A-Za-z]{3}[HPhp]{1}[A-Za-z]{1}\d{4}[A-ZHPa-zhp]{1}$/ }], { messages: { regex: 'Please enter valid PAN' } })}
                                <div style={{
                                    display: infoData.pan === "0" ? "none" : "block"
                                }} className="error">{fieldError.panError}</div>
                            </>
                        )}
                    </div>

                </div>
                <div className="row mt-2">
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group mt-2 inputData`}>
                            <input type="text" id="mobile" name="mobile" value={`+${infoData.country_code} ${infoData.mobile}`}
                                onChange={(e) => {
                                    setInfoData({
                                        ...infoData,
                                        mobile: e.target.value,
                                        user_details: {
                                            ...infoData.user_details,
                                            mobile: e.target.value,
                                        },
                                    })
                                }}
                                readOnly required autoComplete="off" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">Mobile Number*</label>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group mt-2 ${infoData.alternate_mobile ? "inputData" : null}`}>
                            <input type="text" id="alternate_mobile" name="alternate_mobile" value={infoData.alternate_mobile}
                                onChange={(e) => {
                                    if (!isNotEditable) {
                                        // checkInput(e);
                                        setInfoData({
                                            ...infoData,
                                            alternate_mobile: e.target.value.slice(0, 10),
                                            user_details: {
                                                ...infoData.user_details,
                                                alternate_mobile: e.target.value.slice(0, 10),
                                            },
                                        });
                                    }
                                }}
                                onBlur={() => {
                                    // Check if the field is editable before running the validation
                                    if (!isNotEditable) {
                                        simpleValidator.current.showMessageFor("alternate_mobile");

                                    }

                                }} autoComplete="off" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">Alternate Mobile Number</label>
                        </div>
                        {(
                            <>
                                <>{simpleValidator.current.message('alternate_mobile', infoData.alternate_mobile, 'numeric|min:8|max:10', { messages: { min: 'Please enter at least 8 characters.' } })}</>
                            </>

                        )}
                    </div>
                </div>
                <div className="row d-flex align-items-center">
                    <div className="col-md-4 col-12">
                        <div className="dark-label">
                            <Form.Label>DOB*</Form.Label>
                            <div
                                className="dt-conbx"
                                style={{
                                    borderBottom: "1px solid #dadada",
                                    paddingTop: "9px",
                                    marginTop: "1px !important",
                                }}
                            >
                                <ReactDatePicker
                                    readOnly={isNotEditable}
                                    select_date={moment(infoData.dob, "DD/MM/YYYY").toDate()}
                                    setDate={(date) => {
                                        // Check if the field is editable before updating the state
                                        if (!isNotEditable) {
                                            setDate(date);
                                        }
                                    }}
                                    minDate={moment().subtract(100, "years")}
                                    maxDate={moment().subtract(18, "years")}
                                    className="pt-4"

                                />
                            </div>
                            {!isNotEditable && (
                                <div style={{ position: 'absolute' }}>{simpleValidator.current.message('DOB', infoData.dob, 'required', { messages: { required: 'Please select date of birth' } })}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group inputData`} style={{ paddingTop: "17px" }}>
                            <input id="Age" type="Number" name="Age" value={age}
                                readOnly autoComplete="off" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">Age</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4 col-12 custom-input">
                        <div
                            className="material mt-2"
                            style={{
                                width: "100% !important",
                            }}
                        >
                            <Form.Label>Occupation*</Form.Label>
                            {occupationList && (
                                <Select
                                    id="occupationSelect"
                                    name="occupation"
                                    style={{ marginBottom: "0" }}
                                    classNamePrefix="sortSelect"
                                    isSearchable={false}
                                    styles={customStyles}
                                    options={occupationList}
                                    isDisabled={isNotEditable}
                                    value={infoData.occupation !== 0 ? occupationList.find((option) => option.value === infoData.occupation) : null}
                                    onChange={(e) => {
                                        setInfoData({
                                            ...infoData,
                                            occupation: e.value,
                                            user_details: {
                                                ...infoData.user_details,
                                                occupation: e.value,
                                            },
                                        })

                                        setFieldError(data => ({
                                            ...data,
                                            occupationError: ''
                                        }))

                                    }}

                                />

                            )}
                        </div>
                        {!isNotEditable && (
                            <div style={{ position: "absolute", bottom: '-9px' }} className="srv-validation-message">{fieldError.occupationError}</div>
                        )}
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="dark-label">
                            <Form.Label>Gender*</Form.Label>
                            <div className="d-flex pt-4" style={{ clear: "both" }}>
                                <FintooRadio2
                                    checked={infoData.gender == "male"}
                                    onClick={() => {
                                        !isNotEditable ?
                                            setInfoData({
                                                ...infoData,
                                                gender: "male",
                                                user_details: {
                                                    ...infoData.user_details,
                                                    gender: "male",
                                                },
                                            })
                                            : undefined
                                    }}


                                    title="Male"
                                />
                                <FintooRadio2
                                    checked={infoData.gender == "female"}
                                    onClick={() => {
                                        !isNotEditable ?
                                            setInfoData({
                                                ...infoData,
                                                gender: "female",
                                                user_details: {
                                                    ...infoData.user_details,
                                                    gender: "female",
                                                },
                                            })
                                            : undefined

                                    }}

                                    title="Female"
                                />
                                <FintooRadio2
                                    checked={infoData.gender == "other"}
                                    onClick={() => {
                                        !isNotEditable ?
                                            setInfoData({
                                                ...infoData,
                                                gender: "other",
                                                user_details: {
                                                    ...infoData.user_details,
                                                    gender: "other",
                                                },
                                            })
                                            : undefined

                                    }}

                                    title="Other"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group w-100 mt-2  ${infoData.retirement_age ? "inputData" : null}`}>
                            <span>
                                <input
                                    id="retirement_age"
                                    type="text"
                                    name="retirement_age"
                                    pattern="^[1-9][0-9]?$"
                                    value={infoData.retirement_age}
                                    inputMode="numeric"
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const isValidInput = /^(?:[1-9][0-9]?)?$/.test(inputValue);

                                        setInfoData({
                                            ...infoData,
                                            retirement_age: isValidInput ? inputValue : infoData.retirement_age,
                                            user_details: {
                                                ...infoData.user_details,
                                                retirement_age: isValidInput ? inputValue : infoData.retirement_age,
                                            },
                                        });

                                        if (isValidInput) {
                                            checkRetirementAge(inputValue);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        simpleValidator.current.showMessageFor("Retirement Age")
                                    }}
                                    required autoComplete="off" />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="name">Retirement Age*</label>
                            </span>
                            <span className="info-hover-box">
                                <span className="icon">
                                    <img
                                        alt="More information"
                                        src="https://images.minty.co.in/static/assets/img/more_information.svg"
                                    />
                                </span>
                                <span className="msg">
                                    Put an age by when you wish to retire.
                                </span>
                            </span>
                        </div>
                        <div className="srv-validation-message">{fieldError.retAgeError}</div>
                    </div>
                    <div className="col-md-4 col-12 custom-input">
                        <div className={`form-group w-100 mt-2  ${infoData.life_expectancy ? "inputData" : null}`}>
                            <span>
                                <input
                                    id="life_expectancy"
                                    type="text"
                                    name="life_expectancy"
                                    pattern="^(?:[1-9][0-9]?|100)?$"
                                    value={infoData.life_expectancy}
                                    inputMode="numeric"
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const isValidInput = /^(?:[1-9][0-9]?|100)?$/.test(inputValue);

                                        setInfoData({
                                            ...infoData,
                                            life_expectancy: isValidInput ? inputValue : infoData.life_expectancy,
                                            user_details: {
                                                ...infoData.user_details,
                                                life_expectancy: isValidInput ? inputValue : infoData.life_expectancy,
                                            },
                                        });

                                        if (isValidInput) {
                                            checkLifeExpectancyAge(inputValue);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        simpleValidator.current.showMessageFor("Life Expectancy")
                                    }}
                                    required autoComplete="off" />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="name">Life Expectancy*</label>
                            </span>
                            <span className="info-hover-box">
                                <span className="icon">
                                    <img
                                        alt="More information"
                                        src="https://images.minty.co.in/static/assets/img/more_information.svg"
                                    />
                                </span>
                                <span className="msg">
                                    Type an age not less than 70. Keep in mind your family
                                    history. Also, note that women live longer than men.
                                </span>
                            </span>
                        </div>
                        <div className="srv-validation-message">{fieldError.lifeExpError}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9 col-12 custom-input">
                        <div className={`form-group mt-4 ${infoData.remark ? "inputData" : null} `}>
                            <input id="remark" type="text" value={infoData.remark} name="Remarks"
                                onChange={(e) => {
                                    setInfoData({
                                        ...infoData,
                                        remark: e.target.value,
                                        user_details: {
                                            ...infoData.user_details,
                                            remark: e.target.value,
                                        },
                                    })
                                }} autoComplete="off" />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label htmlFor="name">Remarks</label>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="d-flex justify-content-center mt-2">
                            <button
                                className="default-btn gradient-btn save-btn outline-none"
                                onClick={(e) => {
                                    ScrollToTop();
                                    handleSubmit(e)
                                }
                                }
                            >
                                Save & Continue
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <Modal className="popupmodal popupmodal-new" centered show={show}>
                <Modal.Header className="ModalHead">
                    <div className="text-center m-popup-header"> Yooho !!</div>
                </Modal.Header>
                <div className=" p-2 d-grid place-items-center align-item-center">
                    <div className="row HeaderModal ">
                        <div className="col-md-12">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    style={{
                                        maxWidth: "40%",
                                    }}
                                    src="https://static.fintoo.in/static/assets/img/About-you-1.png"
                                />
                            </div>
                            <p className="text-center"
                                style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    fontStyle: 'italic'
                                }}
                            >
                                "Your Data Is Secured."
                            </p>
                        </div>

                        <div
                            style={{
                                lineHeight: "26px",
                                padding: '0rem 3rem'
                            }}
                        >
                            <div>
                                We understand data sensitivity and assure you that your data
                                will not be shared without your consent. So please, do not
                                hesitate to fill in the required information for your Financial
                                Planning.
                            </div>
                            <div>
                                To know more about our security, visit the
                                <Link
                                    style={{ color: "blue", paddingLeft: "4px" }}
                                    // target="_blank"
                                    to={`${process.env.PUBLIC_URL}/privacy-policy`}
                                >
                                    <b>
                                        <i>
                                            <u>Security Statement</u>
                                        </i>
                                    </b>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center pb-4">
                    <button
                        onClick={() => setShow(false)}
                        className="outline-btn secuirtyBtn m-2"
                    >
                        Ok
                    </button>
                </div>
            </Modal>
        </div>
    )
};

export default YourInfo;