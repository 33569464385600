// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_insurance-clients-testemonial-section__iUyiW {\n    background-color: #E8F6FC;\n    padding: 5rem 0;\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/Insurance/InsuranceClientTestimonialSection/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".insurance-clients-testemonial-section {\n    background-color: #E8F6FC;\n    padding: 5rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"insurance-clients-testemonial-section": "style_insurance-clients-testemonial-section__iUyiW"
};
export default ___CSS_LOADER_EXPORT___;
