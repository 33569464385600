import React, { useEffect, useState } from "react";
import FaqSection from "../components/HTML/TaxPlanning/FaqSection";
import SecuritySection from "../components/HTML/SecuritySection";
import TaxPlanningHeaderSection from "../components/HTML/TaxPlanning/TaxPlanningHeaderSection";
import TaxPlanningVideoSection from "../components/HTML/TaxPlanning/TaxPlanningVideoSection";
import WhyTaxPlanningSection from "../components/HTML/TaxPlanning/WhyTaxPlanningSection";
import WealthContactForm from "../components/WealthManagementContactForm/";
import backImg from "../components/Layout/Fullpage/assets/tax-planning-contact-bg.jpg";
import Fullpage from "../components/Layout/Fullpage";
import { servicesTypes } from "../components/WealthManagementContactForm/model"
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";
import WidgetSection from "../components/HTML/TaxPlanning/WidgetSection";
import TPCardSection from "../components/HTML/TaxPlanning/TPCardSection";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
function TaxPlanning() {
  const [show, SetShow] = useState(false)
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);
  return (
    <Fullpage>
      <div style={{ backgroundColor: "white" }}>
        <TaxPlanningHeaderSection />
        <TPCardSection />
        {/* <WhyTaxPlanningSection /> */}
        <WidgetSection />
        {/* <TaxPlanningVideoSection /> */}
        <SecuritySection />
        {/* <section style={{

        }} id="GetStarted">
          {utmSource && tagval ? (
            <AppointmentBox
              extraParams={{ "utm_source": utmSource, "service": 98 }}
              eventCode={tagval}
              serviceName="Tax Planning"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-tax-planning-website?hide_event_type_details=1"}
            />
          ) : (
            <AppointmentBox
              eventCode={'Callback_mintyApp_9'}
              serviceName="Tax Planning"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-tax-planning-website?hide_event_type_details=1"}
              planId = "44"
            />
          )}
        </section> */}
          {/* <AppointmentBox eventCode={'Callback_mintyApp_9'} serviceName="Tax Planning" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-tax-planning-website?hide_event_type_details=1" /> */}
          {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.taxPlanning} onSuccess={() => SetShow(true)} /> */}
        <FaqSection />
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
      </div>
    </Fullpage>
  );

}

export default TaxPlanning;
