import HDFC from "../../Assets/hdfc.png";
import NextArrow from "../../Assets/NextStep.png";
import Link from "../../MainComponents/Link";
import commonEncode from "../../../commonEncode";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { IoCompassOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import transactioncss from "./transaction.module.css";
import Tooltip from "react-tooltip";
import { maskBankAccNo, indianRupeeFormat } from "../../../common_utilities";
const hanldeselectbankid = (props) => {
  let bank_id = props.data.bank_id;
  let bankid = commonEncode.encrypt(JSON.stringify(bank_id));
  console.log("bank", bankid);
  localStorage.removeItem("selbankid");
  localStorage.setItem("selbankid", bankid);
};

function InvestSelectMandate({ data, bankdata , onSelect, selected, handleConfirmSIP }) {
  const [next, setNext] = useState("");
  const condiNavigation = () => {
    let x = localStorage.getItem("cart_data");
    let y = commonEncode.decrypt(x);
    let types = JSON.parse(y).map((v) => v.cart_purchase_type);
    types = [...new Set(types)];
    if (types.length > 1) {
      setNext("/direct-mutual-fund/MyCartAutoPay");
    } else {
      if (types[0] == 2) {
        setNext("/direct-mutual-fund/MyCartAutoPay");
      } else {
        setNext("/direct-mutual-fund/MyCartPaymentMode");
      }
    }
  };
  const tooltipStyle = {
    backgroundColor: "white",
  };
  useEffect(() => {
    condiNavigation();
  }, []);
  const handleSelect = () => {
    onSelect(data);
  };

  const displayStatus = (mandate_status) => {
    mandate_status = parseInt(mandate_status)
    switch(mandate_status) {
      case 2:
        return <span
        style={{
          color: "#14AE5C",
          marginLeft: "5px",
          fontWeight: "500",
        }}
      >
        Approved
      </span>;
        break;
      case 1:
        return <span
        style={{
          color: "#FFA500",
          marginLeft: "5px",
          fontWeight: "500",
        }}
      >
        Rejected {" "}
        <sup>
          <img
            data-tip
            data-for="waitingapproval"
            alt="inprocess"
            width={20}
            src={
              process.env.REACT_APP_STATIC_URL +
              "media/DMF/Inproceess.svg"
            }
          />
        </sup>
      </span>;
      break;
      case 0:
        return <span
        style={{
          color: "#FFA500",
          marginLeft: "5px",
          fontWeight: "500",
        }}
      >
        Inprocess{" "}
        <sup>
          <img
            data-tip
            data-for="waitingapproval"
            alt="inprocess"
            width={20}
            src={
              process.env.REACT_APP_STATIC_URL +
              "media/DMF/Inproceess.svg"
            }
          />
        </sup>
      </span>;
      break;
    }
  }
  return (
    <div
      className={`InvestSelectBank mb-3 pointer ${
        selected ? transactioncss.selectedBank : "pb-4"
      } ${data.mandate_status == '1' ||  data.mandate_status == '0' ? 'disabled-bank' : ''}`}
      onClick={()=> {
        if(data.mandate_status == '2') {
          handleSelect();
        }
      }}
    >
      
      <div className="bank-details ">
        <div
          style={{ padding: "1rem  .5rem" }}
          className="row bank-data align-items-center"
        >
          <div className="col-md-5 col-6 d-flex align-items-center">
            <div className={`${transactioncss.cartBankLogo}`}>
              <img
                src={`${process.env.REACT_APP_STATIC_URL}/media/bank_logo/${
                  bankdata.bank_bse_code ? bankdata.bank_bse_code : "img_default"
                }.png`}
              />
            </div>
            <div className="ms-2">
              <div
                title={data.bank_name}
                className={`${transactioncss.CartBankName}`}
              >
                {data.bank_name}
              </div>
              <div
                className={`${transactioncss.Bottomcarttext1} , ${transactioncss.Bottomcarttext2}`}
              >
                Digital Autopay Supported
                {data?.mandate_status != "N" && displayStatus(data?.mandate_status)}
              </div>
              <Tooltip id="waitingapproval" place="top" style={tooltipStyle}>
                Waiting for the mandate approval
              </Tooltip>
            </div>
          </div>
          <div className={` col-md-4 col-6 `}>
            <div className={`ms-3 ${transactioncss.accountNumbersection}`}>
              <div className={`${transactioncss.bankinfohead}`}>
                Account Number
              </div>
              <div className={`${transactioncss.bankinfodata}`}>
                {maskBankAccNo(data.bank_acc_no)}
              </div>
            </div>
          </div>
          <div className=" col-md-3 " style={{ position: "relative" }}>
            <div className={`ms-md-3 ${transactioncss.mobileBranchName}`}>
              {/* <div>
                <div className={`${transactioncss.bankinfohead}`}>
                  Branch Name
                </div>
                <div title={banklist.bank_branch} className={`${transactioncss.bankinfodata}`}>
                  {banklist.bank_branch}
                </div>
              </div> */}
              <div className={`${transactioncss.bankinfohead}`}>
                {data?.mandate_status == "N" ? "Branch Name" : "Maximum Limit"}
              </div>
              <div
                title={data.bank_branch}
                className={`${transactioncss.bankinfodata}`}
              >
                {data?.mandate_status == "N" ? data.bank_branch : indianRupeeFormat(data.mandate_amount, 0)}
              </div>
              {data?.mandate_status == 0 && <div
                style={{
                  background: "rgba(0, 224.54, 104.98, 0.09)",
                  borderRadius: 3,
                  border: "0.50px #00E169 dotted",
                  width: "max-content",
                  padding: "6px",
                  marginTop: "10px",
                  float: "right",
                  position: "absolute",
                  right: "0",
                  // bottom: "-22px",
                }}
              >
                5 - 7 days
              </div>}
            </div>
          </div>
          {selected && <div className="col-md-12">
            <div className="d-block mt-5">
            <button
              style={{ float: 'right' }}
                className={` ${transactioncss["my-btn"]}`}
                onClick={() => {
                  handleConfirmSIP();
                }}
                >
                Confirm SIP
              </button>
            </div>
          </div>}
        </div>
        {/* <div className="mobile-next d-none">
          <Link
            to={next}
            onClick={(e) => {
              hanldeselectbankid(props);
            }}
          >
            <MdOutlineArrowForwardIos style={{ color: "#000", fontSize: "20px" }} className=" mt-4" />
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default InvestSelectMandate;
