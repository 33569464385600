import AdvisorycardSection from "../components/HTML/InvestmentPlanning/AdvisorySection";
import HeaderSection from "../components/HTML/InvestmentPlanning/HeaderSection";
import FaqSection from "../components/HTML/InvestmentPlanning/FaqSection";
import SecuritySection from "../components/HTML/SecuritySection";
import YoutubevideoSection from "../components/HTML/InvestmentPlanning/YoutubevideoSection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/advisor-min.jpg";
import StepsInvestmentPlan from "../components/HTML/InvestmentPlanning/StepsInvestmentPlan";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import React, { useEffect, useState } from "react";
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";
import KeyDifferentiators from "../components/HTML/InvestmentPlanning/KeyDifferentiators";
import Equity_Offerings from "../components/HTML/InvestmentPlanning/Equity_Offerings";
import DebtInvestments from "../components/HTML/InvestmentPlanning/DebtInvestments";
import AlternateInvestments from "../components/HTML/InvestmentPlanning/AlternateInvestments";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
const InvestPlanning = () => {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  useEffect(() => {
    dispatch({ type: "HIDE_FOOTER", payload: true });
  }, []);
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);
  return (
    <Fullpage>
      <HeaderSection />
      <AdvisorycardSection />
      <section
        style={{
          backgroundColor: "#e8f6fc",
        }}
      >
        <KeyDifferentiators />
        {/* <StepsInvestmentPlan /> */}
      </section>
      <Equity_Offerings />

      {/* <section
        style={{
          backgroundColor: "#fff",
        }}
      >
        {/* <StepsInvestmentPlan /> */}
      {/* </section> */}
      <section
        style={{
          backgroundColor: "#e8f6fc",
        }}
      >
        <DebtInvestments />
      </section>
      <AlternateInvestments />
      {/* <YoutubevideoSection /> */}
      <SecuritySection DynamicBgColor={"#e8f6fc"} />
      <section style={{}} id="book-appointment">

        {utmSource && tagval ? (
          <AppointmentBox
            extraParams={{ "utm_source": utmSource, "service": 98 }}
            eventCode={tagval}
            serviceName="Investment Planning"
            eventUrl="https://calendly.com/fintoo/15-min-consultation-call-investment-planning"
             planId = "18"
          />
        ) : (
          <AppointmentBox
            eventCode={'Callback_mintyApp_57'}
            serviceName="Investment Planning"
            eventUrl="https://calendly.com/fintoo/15-min-consultation-call-investment-planning"
            planId = "18"
          />
        )}
        {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.investmentPlanning} onSuccess={() => SetShow(true)} /> */}
      </section>
      <FaqSection />
      {show && <ThankyouSection onClose={() => SetShow(false)} />}
    </Fullpage>
  );
};
export default InvestPlanning;
