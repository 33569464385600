import React, { useEffect, useRef, useState } from "react";
import Styles from "./Expertfp.module.css";
import HideHeader from "../components/HideHeader";
import HideFooter from "../components/HideFooter";
import { Link } from "react-router-dom";
import FintooCheckbox from "../components/FintooCheckbox/FintooCheckbox";
import {
  apiCall,
  fetchData,
  fetchEncryptData,
  getItemLocal,
  getParentUserId,
  loginRedirectGuest,
  setItemLocal,
} from "../common_utilities";
import {
  ADVISORY_EXPERT_FP_DOC,
  ADVISORY_FETCH_FP_AGREEMENT,
  ADVISORY_SIGNDESK_API_CALL,
  ADVISORY_SIGNDESK_API_CHECK,
  ADVISORY_UPDATE_CURRENT_STEP,
  BASE_API_URL,
  CHECK_SESSION,
} from "../constants";
import FintooLoader from "../components/FintooLoader";
function Expertfp() {
  const [termsError, setTermsMsg] = useState("");
  const [terms, setTerms] = useState("");
  const [fpData, setFpData] = useState("");
  const [sessiondata, setSessionData] = useState({});
  const [signDeskResponse, setSignDeskResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const session_data = useRef();

  useEffect(() => {
    getSessionData();
  }, []);

  useEffect(() => {
    if (
      sessiondata["fp_lifecycle_status"] != "1" &&
      (sessiondata["fp_plan_type"] == "8" || sessiondata["fp_plan_type"] == "6")
    ) {
      setIsLoading(false);
      window.location.href = BASE_API_URL + "datagathering/about-you/";
    }
  }, [sessiondata]);

  const getSessionData = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };

      session_data.current = await apiCall(url, data, true, false);
      if (session_data.current.error_code == "102") {
        loginRedirectGuest();
      } else {
        setSessionData(session_data.current.data);
        fetchFpAgreement(session_data.current.data);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const fetchFpAgreement = async (sesssionData) => {
    try {
      let payload = {
        user_id: sesssionData["id"],
        fp_log_id: sesssionData["fp_log_id"],
      };
      let config = {
        method: "POST",
        url: ADVISORY_FETCH_FP_AGREEMENT,
        data: payload,
      };
      let response = await fetchEncryptData(config);
      if (response["error_code"] == "100") {
        setFpData(response["data"]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        window.location.href = process.env.PUBLIC_URL + "/commondashboard/";
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const handleCheckboxClick = (e) => {
    if (e.target.checked == false) {
      setTermsMsg("Please agree to proceed");
    } else {
      setTermsMsg("");
    }
    setTerms(e.target.checked);
  };

  const proceedSignature = async () => {
    if (terms == true && termsError == "") {
      try {
        setIsLoading(true);
        let fpBody = {
          user_id: sessiondata["id"].toString(),
          fp_log_id: sessiondata["fp_log_id"].toString(),
          flag: "fp",
        };
        let config = {
          method: "POST",
          url: ADVISORY_EXPERT_FP_DOC,
          data: fpBody,
        };
        let response = await fetchData(config);
        if (response["error_code"] == "100") {
          var b64FileString = response["data"]["b64_file_string"];
          let signDeskConfig = {
            method: "POST",
            url: ADVISORY_SIGNDESK_API_CALL,
            data: {
              user_id: sessiondata["id"],
              fp_log_id: sessiondata["fp_log_id"],
              file_content: b64FileString,
            },
          };
          let signDeskResponse = await fetchData(signDeskConfig);
          if (signDeskResponse["error_code"] == "100") {
            setSignDeskResponse(signDeskResponse["details"]);
            let widgetURL =
              signDeskResponse["details"]["signer_info"][0]["invitation_link"];
            const openInWidgetWindow = (url) => {
              const widgetWindow = window.open(
                url,
                "widgetWindow",
                "width=1080,height=840"
              );

              const checkStatusInterval = setInterval(async () => {
                var conf = {
                  method: "POST",
                  url: ADVISORY_SIGNDESK_API_CHECK,
                  data: {
                    user_id: sessiondata["id"],
                  },
                };
                let checkResponse = await fetchData(conf);
                if (checkResponse["error_code"] === "100") {
                  await updateLifecycleStatus(2, sessiondata["fp_log_id"]);
                  clearInterval(checkStatusInterval);
                  widgetWindow.close();
                  setItemLocal("generate_report", 1);
                  window.location.href = process.env.PUBLIC_URL + "/commondashboard/";
                } else {

                  clearInterval(checkStatusInterval);
                  widgetWindow.close();
                  window.location.href =
                    process.env.PUBLIC_URL + "/datagathering/about-you";
                }
              }, 1000);
            };

            openInWidgetWindow(widgetURL);
          } else {
            console.log("Error has occurred.", signDeskResponse);
            // toastr.error((response.data['message']));
            // window.location.href = '/datagathering/about-you';
          }
        } else {
          console.log("Error has occurred.", response);
          if (response["error_code"] == 103) {
            console.log(response["data"]);
          }
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    } else {
      setTermsMsg("Please agree to proceed");
      setTerms(!terms);
    }
  };

  const updateLifecycleStatus = async (step,fp_log_id) => {
    try {
      var config = {
        method: "POST",
        url: ADVISORY_UPDATE_CURRENT_STEP,
        data: {
          step: step,
          fp_log_id: fp_log_id,
        },
      };
      let response = await fetchData(config);
      if (response["error_code"] == "100") {
        
      } else {
        console.error(err);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <HideHeader />
      <FintooLoader isLoading={isLoading} />
      <div className={`${Styles.backgrounddiv}`}>
        <div className={`${Styles.expertfp}`}></div>
      </div>
      <div className={`${Styles.whitebox}`}>
        <div className="container-fluid">
          <div>
            <Link to={process.env.PUBLIC_URL + "/datagathering/about-you"}>
              <div className={`${Styles.backarrow}`}>
                <img src="https://static.fintoo.in/static/userflow/img/icons/back-arrow.svg" />
              </div>
            </Link>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="col-md-10 mt-5 h-100">
                  <div className="h-100 mt-5">
                    <p
                      className={`${Styles.fpcontent}`}
                      dangerouslySetInnerHTML={{
                        __html: fpData ? fpData : "",
                      }}
                    ></p>
                  </div>
                  {/*  */}

                  <div
                    className=" checkbox-container position-relative"
                    style={{ margin: "4rem 0px 0px" }}
                  >
                    <input
                      type="checkbox"
                      tabIndex="1"
                      className="custom-checkbox"
                      onClick={handleCheckboxClick}
                    />
                    <label
                      for="rememberMe"
                      // className="checkbox-label"
                      style={{
                        paddingTop: "2px",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      I Agree to the terms and conditions
                    </label>
                    <span className="error">{termsError}</span>
                  </div>
                  <button
                    type={"button"}
                    onClick={proceedSignature}
                    className={`${Styles.Proceedbtn}`}
                  >
                    Proceed to Signature
                  </button>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HideFooter />
    </>
  );
}

export default Expertfp;
