// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_direct-mf-testemonial-section__3c8OQ {\n    padding-top: 3rem;\n    padding-bottom: 5rem;\n    background-color: #E8F6FC;\n}\n.style_section-title__t2rkg {\n    color: #060606;\n    font-size: 2.3rem;\n    font-weight: 700;\n    line-height: 3.5rem;\n    text-align: center;\n    color: #2f3436;\n    font-family: \"Poppins\", Sans-serif;\n    letter-spacing: 1px;\n}\n.style_DiffText__fn\\+LD{\n    color: #21A6DE;\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/DirectMF/DirectMFTestimonials/style.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,yBAAyB;AAC7B;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,kCAAkC;IAClC,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB","sourcesContent":[".direct-mf-testemonial-section {\n    padding-top: 3rem;\n    padding-bottom: 5rem;\n    background-color: #E8F6FC;\n}\n.section-title {\n    color: #060606;\n    font-size: 2.3rem;\n    font-weight: 700;\n    line-height: 3.5rem;\n    text-align: center;\n    color: #2f3436;\n    font-family: \"Poppins\", Sans-serif;\n    letter-spacing: 1px;\n}\n.DiffText{\n    color: #21A6DE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"direct-mf-testemonial-section": "style_direct-mf-testemonial-section__3c8OQ",
	"section-title": "style_section-title__t2rkg",
	"DiffText": "style_DiffText__fn+LD"
};
export default ___CSS_LOADER_EXPORT___;
