import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.css";
import AnimateText from "./AnimateText";
import { fetchUserData, getPublicMediaURL } from "../../../common_utilities";
import { CiSearch } from "react-icons/ci";
import { getUserId } from "../../../common_utilities";
import { useDispatch } from "react-redux";
const WelcomeSection = () => {
  const dispatch = useDispatch();

  const [chatUrl, setChatUrl] = useState("https://fintoo.hellotars.com/conv/z_dfOY/?_nav");
  const [counter, setCounter] = useState(0);
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };
  const interval = useRef(null);
  const timer = useRef(0);
  const startTimer = () => {
    // if idle for more than 5 mins logout
    if (timer.current >= 4) {
      clearInterval(interval.current)
      if (getUserId()) {
        window.location.href = "https://stg.minty.co.in/web/logout"
      }
    }
    else {
      timer.current = timer.current + 1
    }
  }
  const incrementTimer = () => {
    interval.current = setInterval(() => {
      startTimer()
    }, 60000);
  }
  const resetTimer = () => {
    clearInterval(interval.current)
  }

  useEffect(() => {
    // incrementTimer();
  }, []);

  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => {
    dispatch({ type: 'OPENCHATBOT', payload: true });
  };

  console.log(showResults, "shw", counter);

  return (
    <section className="pb-5"
      onMouseEnter={() => {
        resetTimer();
      }}
      onMouseLeave={() => {
        resetTimer();
      }}
    >
      <div className={`container ${styles.container}`}>
        <div className="d-flex justify-content-center">
          <div className={styles.BannerTitle}>
            Wealth and Tax Advice on the go
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className={styles.BannerText}>
            How may I make your day special Today ?
          </div>
        </div>
        <div className={styles.searchbox}>

          <a href="#tarsbot" style={{ textDecoration: 'none' }}>
            <div className={styles.animateBox} >
              {/* <div className={styles.animateBox} onClick={onClick}> */}
              <span>
                {" "}
                <CiSearch />
              </span>{" "}
              <AnimateText />
            </div>
          </a>

        </div>
        <div className="d-flex justify-content-center mt-md-5 mt-5">
          <div>
            <a
              target="_blank"
              style={{ paddingTop: ".5rem !important", padding: 0 }}
              href="https://play.google.com/store/apps/details?id=com.financialhospital.admin.finh"
            >
              <img
                width={140}
                alt="Download Fintoo app on play store"
                className=" ls-is-cached lazyloaded"
                src={process.env.REACT_APP_STATIC_URL + '/media/footer/minty-android-app.png'}

              />
            </a>
          </div>
          <div className="ms-md-3 ms-3">
            <a
              target="_blank"
              style={{ paddingTop: ".5rem !important", padding: 0 }}
              href="https://apps.apple.com/in/app/fintoo/id1339092462"
            >
              <img
                width={140}
                alt="Download Fintoo app on app store"
                className=" ls-is-cached lazyloaded"
                src={process.env.REACT_APP_STATIC_URL + '/media/footer/minty-app-store.png'}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WelcomeSection;
