import React, { useState, useEffect } from "react";
import ProfileInsiderLayout from "../../../components/Layout/ProfileInsiderLayout";
import { useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NomineeList from "./NomineeList";
import Delete from "../../../components/Assets/Dashboard/delete_Gray.png";
import Edit from "../../../components/Assets/Dashboard/edit icon.png";
import { DMF_BASE_URL } from "../../../constants";
import axios from "axios"; //api calling
import commonEncode from "../../../commonEncode"; //encrypt decrypt data
import AddNominee from "./AddNominee";
import * as constClass from "../../../constants";

import { ToastContainer, toast } from "react-toastify";
import {
  CheckSession,
  apiCall,
  successAlert,
  errorAlert,
  loginRedirectGuest,
  getUserId,
} from "../../../common_utilities";
import NomineeNoData from "../../../components/ProfileInsider/Nominee/NoData";


const Nominee = (props) => {
  const [nomineeList, getNomineeList] = React.useState("");
  const [dynamicNomineeList, getDynamicNomineeList] = useState([]);
  const [NomineeDatadelete, getNomineeDatadelete] = useState([]);
  const [dummyNomineeData, setdummyNomineeData] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const results = [];
  const [total1, setTotal1] = useState();
  const dispatch = useDispatch();
  const [nomineeflag ,setnomineeflag] =useState("");
  

  useEffect(function () {
    console.log("10000")
    if (getUserId() == null)
      loginRedirectGuest();
    else
      userdetails();
      onLoadInIt();
      
      // console.log("out")
  }, []);

  useEffect(() => {
    if(nomineeflag === "N" && dynamicNomineeList <1){
    setShowAddForm(true);
    console.log("inn")}
    else{
      console.log("out")
      setShowAddForm(false);}
  
})

  const refreshPage = () => {
    onLoadInIt();
    setShowAddForm(false);
  }

  useEffect(() => {
    // CheckSession();
    // onLoadInIt();
    userdetails()
    console.log("1000rep")
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  

  const userdetails = async () => {
    try {
      var userid = getUserId();
      var form_data_user = { user_id: userid };
      var data = commonEncode.encrypt(JSON.stringify(form_data_user));

      var config_list = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: data,
      };

      var res = await axios(config_list);
      var response = commonEncode.decrypt(res.data);
      let responseobj = JSON.parse(response);
      let user_data = responseobj.data;
      setnomineeflag(user_data.nominee_status);
      // setuseremail(user_data.email);
      // setusermobile(user_data.mobile);
    } catch (e) {}
  };

  const onLoadInIt = async () => {
    var user_id = getUserId();
    
    try {

      var dynamicNomineeData = { "user_id": user_id, "is_direct": '1' }
      var data = commonEncode.encrypt(JSON.stringify(dynamicNomineeData))

      var config = {
        method: "post",
        url: constClass.DMF_NOMINEELIST_API_URL,
        data: data,
      };

      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      console.log(response,"1000rep")
      getDynamicNomineeList((JSON.parse(response))['data']);

    } catch (e) {
      
    }
  };
  const HideAddForm = () => {
    setShowAddForm(false);
  }

  

  return (
    <ProfileInsiderLayout>
      <ToastContainer />
      <div className="ProfileDashboard">
        <div className="ml-10 md:mt-14 mt-4 p-2 md:p-3 rounded-3xl">
          <div className="text-label-info">
            <Row>
              <Col xs={12} lg={12}>
                <Row>
                  <Col>
                    <div
                      className="col-12 col-lg-8 nomineedata"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="text-nominee">
                        <p className="text-label">Nominee</p>
                        <span className="secondTextinfo desktopView">
                        You have added the following nominee for your investments.
                        </span>
                      </div>
                    </div>
                    <p className="Hrline "></p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div>
            <div className="col-12 text-nominee mobileNomineeText">
              <span className="secondTextinfo">
                You have set of following nominees, for your investments
              </span>
            </div>
            {/* <>
              <Row>
                <Col xs={12} lg={8}>
                  <div
                    style={{
                      float: "right",
                    }}

                    onClick={() => {
                      if (dynamicNomineeList.length >= 1) {
                        if (dynamicNomineeList.length >= 1)
                          
                          dispatch({ type: "RENDER_TOAST", payload: {message: "You cannot add more than one nominee.", type: 'error'} });
                          return;
                        
                      }
                      else {
                        
                        setShowAddForm(true);
                        props.setEditNominee();
                      }
                    }}
                  >
                    {dynamicNomineeList.length > 0 ? "":
                    <div
                      className="pointer"
                      style={{
                        color: "#24a7df",
                        fontWeight: "500",
                      }}
                    >
                      + Add New Nominee
                    </div>}
                  </div>
                </Col>
              </Row>
            </> */}
            {dynamicNomineeList.length > 0 && (
              dynamicNomineeList.map((item) => (
                <NomineeList refreshPage={() => refreshPage()} NomineeData={item} />
              ))
            )}
            
            {/* {Boolean(showAddForm) === false && dynamicNomineeList.length === 0 && <NomineeNoData onAdd={()=> {
              setShowAddForm(true);
            }} />} */}
            
            {Boolean(showAddForm) && <AddNominee refreshPage={() => refreshPage()} onRemove={() => HideAddForm()} />}
            
          </div>
        </div>
      </div>
    </ProfileInsiderLayout>
  );
};

export default Nominee;
