// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_Notice-section__Kv1EC {\n  background-color: #E8F6FC;\n  color: #000;\n}\n.style_Notice-type-Section__LS\\+fl {\n  margin-top: 2rem;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(3, 1fr);\n  grid-column-gap: 0px;\n  grid-row-gap: 0px;\n}\n.style_BoxSection__iuqyG {\n  display: flex;\n  margin-bottom: 2rem;\n}\n.style_BoxSection__iuqyG svg{\n  font-size: 2rem;\n}\n.style_BoxSectionTitle__za9XG {\n  margin-left: 1rem;\n  font-size: 1.3rem;\n}\n.style_BottomText__KrI0-{\n  font-size: 1.3rem;\n}\n@media only screen and (max-width: 768px) {\n  .style_Notice-type-Section__LS\\+fl{\n   display: block;\n  }  \n}\n\n", "",{"version":3,"sources":["webpack://./src/components/HTML/Notices/NoticesType/style.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,qCAAqC;EACrC,kCAAkC;EAClC,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE;GACC,cAAc;EACf;AACF","sourcesContent":[".Notice-section {\n  background-color: #E8F6FC;\n  color: #000;\n}\n.Notice-type-Section {\n  margin-top: 2rem;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(3, 1fr);\n  grid-column-gap: 0px;\n  grid-row-gap: 0px;\n}\n.BoxSection {\n  display: flex;\n  margin-bottom: 2rem;\n}\n.BoxSection svg{\n  font-size: 2rem;\n}\n.BoxSectionTitle {\n  margin-left: 1rem;\n  font-size: 1.3rem;\n}\n.BottomText{\n  font-size: 1.3rem;\n}\n@media only screen and (max-width: 768px) {\n  .Notice-type-Section{\n   display: block;\n  }  \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Notice-section": "style_Notice-section__Kv1EC",
	"Notice-type-Section": "style_Notice-type-Section__LS+fl",
	"BoxSection": "style_BoxSection__iuqyG",
	"BoxSectionTitle": "style_BoxSectionTitle__za9XG",
	"BottomText": "style_BottomText__KrI0-"
};
export default ___CSS_LOADER_EXPORT___;
