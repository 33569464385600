// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_SelectTitleBox__LeysN {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid #000;\n    padding: 6px;\n    cursor: pointer;\n}\n.style_selectTitle__lyJf- {\n    margin: 0px;\n}\n.style_selectBox__QTyWJ {\n    position: relative;\n}\n.style_optionsPanel__EeCgE {\n    position: absolute;\n    z-index: 99;\n    width: 100%;\n    background: #fff;\n    padding: 10px;\n    -webkit-user-select: none;\n            user-select: none;\n    height: 200px;\n    overflow: scroll;\n}\n.style_option__l4Edb {\n    cursor: pointer;\n    border-bottom: 1px solid #eee;\n    padding: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/ReactSimpleSelect/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,6BAA6B;IAC7B,YAAY;IACZ,eAAe;AACnB;AACA;IACI,WAAW;AACf;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,yBAAiB;YAAjB,iBAAiB;IACjB,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,6BAA6B;IAC7B,aAAa;AACjB","sourcesContent":[".SelectTitleBox {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid #000;\n    padding: 6px;\n    cursor: pointer;\n}\n.selectTitle {\n    margin: 0px;\n}\n.selectBox {\n    position: relative;\n}\n.optionsPanel {\n    position: absolute;\n    z-index: 99;\n    width: 100%;\n    background: #fff;\n    padding: 10px;\n    user-select: none;\n    height: 200px;\n    overflow: scroll;\n}\n.option {\n    cursor: pointer;\n    border-bottom: 1px solid #eee;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectTitleBox": "style_SelectTitleBox__LeysN",
	"selectTitle": "style_selectTitle__lyJf-",
	"selectBox": "style_selectBox__QTyWJ",
	"optionsPanel": "style_optionsPanel__EeCgE",
	"option": "style_option__l4Edb"
};
export default ___CSS_LOADER_EXPORT___;
