// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_investsection__GMjhU{\n    background-color: #E8F6FC;\n}\n.style_SectionSubtext__lZhau{\n    color: #86898b;\n    font-size: 1.2rem;\n    font-weight: 500;\n}\n@media (min-width: 1400px) {\n    .style_container__hjghV {\n        width: 1140px!important;\n    }\n}\n\n.style_card-item__U6u0Z img {\n    margin: auto;\n}\n@media (max-width: 767px) {\n    .style_featured-in-xu__\\+Ivb5 {\n        width: 80%;\n        margin: auto;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/VirtualItrHelpdesk/FeaturedIn/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI,YAAY;AAChB;AACA;IACI;QACI,UAAU;QACV,YAAY;IAChB;AACJ","sourcesContent":[".investsection{\n    background-color: #E8F6FC;\n}\n.SectionSubtext{\n    color: #86898b;\n    font-size: 1.2rem;\n    font-weight: 500;\n}\n@media (min-width: 1400px) {\n    .container {\n        width: 1140px!important;\n    }\n}\n\n.card-item img {\n    margin: auto;\n}\n@media (max-width: 767px) {\n    .featured-in-xu {\n        width: 80%;\n        margin: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"investsection": "style_investsection__GMjhU",
	"SectionSubtext": "style_SectionSubtext__lZhau",
	"container": "style_container__hjghV",
	"card-item": "style_card-item__U6u0Z",
	"featured-in-xu": "style_featured-in-xu__+Ivb5"
};
export default ___CSS_LOADER_EXPORT___;
